// // import 'moment';
// import moment from 'moment';
// import React from 'react';
// // import MomentUtils from '@date-io/moment';
// import DateFnsUtils from '@date-io/date-fns';

// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';

// export default function Datepicker(props) {
//   // The first commit of Material-UI
//   const [selectedDate, setSelectedDate] = React.useState(new Date());

//   React.useEffect(()=>{
//     handleDateChange(selectedDate)
//   },[])

//   React.useEffect(()=>{
//     setSelectedDate(props.value)
//   },[props])

//   const handleDateChange = (date) => {
//     // console.log('date', date)
//     setSelectedDate(date);
//     const day = moment(date).format('MM/DD/YYYY')
//     const data = {
//       startDate: moment(day).startOf('day').toDate().valueOf(),
//       endDate: moment(day).endOf('day').toDate().valueOf()
//     }
//     props.handleChange(data)
//   };




//   return (
//     <React.Fragment>
//     <LocalizationProvider AdapterDateFns={DateFnsUtils}>
//         <DatePicker
//             autoOk
//             disablePast={props.disablePast || false}
//             clearable={props.clearable || false}
//             inputVariant="outlined"
//             inputMode="none"
//             id="date-picker-dialog"
//             format="MM/dd/yyyy"
//             value={selectedDate}
//             onChange={handleDateChange}
//             placeholder="MM/DD/YYYY"
//             className="datepicker"
//             multiple={true}
//             minDate={new Date('04/11/2021')}
//             maxDate={new Date()}
//             // onClose={handleDateChange}
//         />
//     </LocalizationProvider>
//     <style jsx="true">{`
//       .datepicker > div{
//         height: 38px;
//         color: ${props?.color};
//         width: 195px;
//       }
//       .datepicker > div > div > button{
//         color: ${props?.color};
//       }
//     `}</style>
//     </React.Fragment>
//   );
// }

import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

export default function BasicDatePicker(props) {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={props.disabled || false }
        label={props.label || "Date"}
        renderInput={(params) => <TextField {...params} />}
        autoOk
        disablePast={props.disablePast || false}
        clearable={props.clearable || false}
        inputVariant="outlined"
        inputMode="none"
        id="date-picker-dialog"
        format={props.format || "dd/MM/yyyy"}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder || "MM/DD/YYYY"}
        className={`datepicker form-control ${props.className || ""}`}
        multiple={!!props.multiple}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </LocalizationProvider>
  );
}