import {
  DeleteWithToken,
  GetWithToken,
  PostWithToken,
  PutWithToken,
  DeleteById,
  PatchWithToken,
  Get,
  GetWithTokenParams,
  GetWithTokenFile,
} from "../../../lib/request";

export const getAllOrderListByMerchant = (query) => {
  return async function (dispatch) {
    // console.log("enter", query);
    const result = await GetWithToken(
      `/v2/merchant/order?item=${query?.item || ""}&order=${
        query?.order || ""
      }&orderId=${query?.orderId || ""}&buyerName=${
        query?.buyerName || ""
      }&merchantName=${query?.merchantName || ""}&productName=${
        query?.productName || ""
      }&status=${query?.status || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&offset=${query?.offset || 0}&limit=${
        10 || 0
      }`
    );
    // console.log(result, "result for all consumer ");
    return result;
  };
};

export const getOrderByIdByMerchant = (id, query) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/merchant/order/${id}?productId=${query.productId || ""}`
    );
    return result;
  };
};

export const deleteOrderByIdByMerchant = (id) => {
  return async function (dispatch) {
    console.log(id, "enter");
    const result = await DeleteById(`/v2/merchant/order/${id}`);
    console.log(result, "result for all consumer ");
    return result;
  };
};

export const getTeamPurcheaseByMerchantId = (query) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/merchant/order/team/purchase?item=${query.item || ""}&order=${
        query.order || ""
      }&orderId=${query.orderId || ""}&buyerName=${
        query.buyerName || ""
      }&merchantName=${query.merchantName || ""}&productName=${
        query.productName || ""
      }&teamStatus=${query.teamStatus || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&limit=${query.limit || 10}&offset=${
        query.offset || 0
      }`
    );

    return result;
  };
};

export const postRedemptionByMerchant = (orderId, redemptionCode) => {
  return async function (dispatch) {
    const result = await PostWithToken(
      `/v2/merchant/order/redemption?orderId=${orderId}&redemptionCode=${redemptionCode}`
    );
    return result;
  };
};

export const getOrderReturnByMerchant = (query) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/merchant/order/return/all?consumerName=${
        query.buyerName || ""
      }&merchantName=${query.merchantName || ""}&agentId=${
        query.agentId || ""
      }&refundMethod=${query.refundMethod || ""}&refundId=${
        query.refundId || ""
      }&item=${query.item || ""}&order=${query.order || ""}&offset=${
        query.offset || 0
      }&status=${query.status || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&limit=${query.limit || 10}`
    );
    console.log(result, "result for all reciept ");
    return result;
  };
};

export const getOrderReturnByIdByMerchant = (id, status) => {
  return async function (dispatch) {
    console.log("enter");
    const result = await GetWithToken(
      `/v2/merchant/order/return-by-id/${id || ""}`
    );
    console.log(result, "result for all reciept ");
    return result;
  };
};

export const patchOrderReturnByIdByMerchant = (id, status) => {
  return async function (dispatch) {
    return await PatchWithToken(`/v2/merchant/order/return/${id || ""}`, {
      status,
    });
  };
};

export const getAllSettlementData = (query) => {
  return async function (dispatch) {
    return await GetWithToken(
      `/v2/merchant/order/settle/all?item=${query.item || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&order=${query.order || ""}&status=${
        query.status || ""
      }&offset=${query.offset || 0}`
    );
  };
};
