import { DeleteWithToken, GetWithToken, PostWithToken, PutWithToken,DeleteById, Get, PatchWithToken } from "../../lib/request";

export const createNewBanner = (data) => {
    return async function (dispatch) {
        const result = await PostWithToken('/app-banners', data);
        return result;
    }
}

export const getAllBanners = (query) => {
    // console.log(query);
    return async function (dispatch) {
    const result = await GetWithToken(`/app-banners?&offset=${query?.offset|| 0}`)   
    return result;
    }
}

export const getBannerById = (id) => {
    // console.log(query);
    return async function (dispatch) {
    const result = await GetWithToken(`/app-banners/${id}`)   
    return result;
    }
}

export const updateBannerById = (data, id) => {
    console.log(data);
    return async function (dispatch) {
        const result = await PatchWithToken(`/app-banners/${id}`, data);
        return result;
    }
}

export const deleteBannerById = ( id) => {
    console.log(id,'id id id did idd ');
    return async function (dispatch) {
        const result = await DeleteWithToken(`/app-banners/${id}`);
        return result;
    }
}

