// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-box {
  display: block;
  min-height: 120px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px #0000001a;
  border-radius: 2px;
  margin-bottom: 15px; }

.analytics__body {
  min-height: 400px; }
`, "",{"version":3,"sources":["webpack://./src/static/css/analytics.css"],"names":[],"mappings":"AAAA;EACI,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;EACX,+BAA+B;EAC/B,kBAAkB;EAClB,mBAAmB,EAAA;;AAGvB;EACI,iBAAiB,EAAA","sourcesContent":[".info-box{\n    display: block;\n    min-height: 120px;\n    background: #fff;\n    width: 100%;\n    box-shadow: 0 1px 1px #0000001a;\n    border-radius: 2px;\n    margin-bottom: 15px;\n}\n\n.analytics__body{\n    min-height: 400px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
