import "./App.scss";
import { PublicRoutes, PrivateRoutes } from "./routes";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { getLocalStorage } from "./lib/session";
import { useDispatch, useSelector } from "react-redux";
import NetworkDetector from "./hoc/NetworkDetector";
import React, { useEffect, useState } from "react";
import ThemeContext from "./context/ThemeContext";
import { setMobileView } from "./redux/actions/auth.action";
import { detectDevice } from "./lib/global";
import DrawerHoc from "./hoc/drawerHoc";
import DialogHoc from "./hoc/dialogHoc";
import { customTheme } from "./lib/theme";
import { actionThemeChange } from "./redux/actions/home.action";
import LoaderHoc from "./hoc/loader";
import Index from "./pages";
import OneSignal from "react-onesignal";
import { ONESIGNAL_API_ID } from "./lib/config";
import { handleDocumentTitleLogin } from "./lib/useDocumentTitle";
// window.OneSignal = window.OneSignal || [];
// const OneSignal = window.OneSignal;

function App(props) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.store.lang);
  const theme = useSelector((state) => state.store.theme) || "light";
  const currentTheme = customTheme[theme];

  const PrivateRoute = (props) => {
    if (!getLocalStorage("token")) {
      return <Redirect to={{ pathname: "/auth" }} />;
    }
    return <Route exact {...props} />;
  };

  useEffect(() => {
    dispatch(setMobileView(detectDevice()));
  }, [dispatch]);

  useEffect(async () => {
    const defTheme = getLocalStorage("theme");
    if (defTheme) {
      dispatch(actionThemeChange(defTheme));
    }
    // OneSignal.push(async () => {
    //   OneSignal.init(
    //     {
    //       // appId: "a0b2eb25-c6a7-47f8-aa35-fa962242069e", //STEP 9
    //       appId: ONESIGNAL_API_ID, //STEP 9
    //       promptOptions: {
    //         slidedown: {
    //           enabled: true,
    //           actionMessage:
    //             "We'd like to show you notifications for the latest news and updates about the following categories.",
    //           acceptButtonText: "ok",
    //           cancelButtonText: "cancel",
    //           categories: {
    //             tags: [
    //               {
    //                 tag: "react",
    //                 label: "ReactJS",
    //               },
    //               {
    //                 tag: "angular",
    //                 label: "Angular",
    //               },
    //               {
    //                 tag: "vue",
    //                 label: "VueJS",
    //               },
    //               {
    //                 tag: "js",
    //                 label: "JavaScript",
    //               },
    //             ],
    //           },
    //         },
    //       },
    //       welcomeNotification: {
    //         title: "One Signal",
    //         message: "Thanks for subscribing!",
    //       },
    //     },
    //     //Automatically subscribe to the new_app_version tag
    //     OneSignal.sendTag("new_app_version", "new_app_version", (tagsSent) => {
    //       // Callback called when tag has finished sending
    //       console.log("new_app_version TAG SENT", tagsSent);
    //     }),
    //     OneSignal.getExternalUserId((ts) => {
    //       console.log('playerIdmebbbb',ts);
    //   })
    //
    //   );
    //
    //   let idme = await OneSignal.getExternalUserId();
    //   console.log('playerIdme',idme);
    //
    // });

    OneSignal.init({
      appId: ONESIGNAL_API_ID,
    }).then(async () => {
      let playerTokenId = await OneSignal.getUserId();
    });
    handleDocumentTitleLogin(localStorage.getItem("role"));
  }, []);

  return (
    <React.Fragment>
      <Router>
        <ThemeContext.Provider value={theme}>
          <div className="App App__theme">
            <Switch>
              {PublicRoutes.map(({ component: Cmp, ...route }, index) => (
                <Route
                  exact
                  key={"publicRoute" + index}
                  {...route}
                  render={(routeProps) => <Cmp {...props} {...routeProps} />}
                />
              ))}
              <Route exact component={Index} />
              {/* {PrivateRoutes.map(({ component: Cmp, ...route }, index) => 
                    <PrivateRoute 
                      exact 
                      key={'privateRoute' + index} 
                      {...route}
                      render={routeProps => <Cmp {...props} {...routeProps} />} 
                    />
                  )} */}
            </Switch>
          </div>
          <DrawerHoc></DrawerHoc>
          <DialogHoc></DialogHoc>
          <LoaderHoc></LoaderHoc>
        </ThemeContext.Provider>
      </Router>
      <style jsx="true">{`
        .App__theme {
          background: ${currentTheme.background};
          color: ${currentTheme.textColor};
        }
      `}</style>
    </React.Fragment>
  );
}

export default NetworkDetector(App);
