import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IconButton, TablePagination } from "@mui/material";
import Image from "../../../components/Images/Image";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import "../../../static/css/merchantCss/merchent_center.css";
import SvgIcon from "../../../components/Images/SvgIcon";
import { AllowAccess } from "../../../lib/global";

function MerchantReport() {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const breadcrumb = [{ label: "Merchant Report", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, []);
  const merchantAction = () => {
    history.push("/merchant-action");
  };
  return (
    <React.Fragment>
      <div className="  merchant_report_page_searchBox ">
        <div className="d-flex">
          <div className="col-xl-3 px-1">
            Merchant Id
            <input type="text" className="form-control " placeholder="Input" />
          </div>
          <div className="col-xl-3 px-1">
            Mercahnt Name
            <div className="input-group  p-0 form-control rounded  border-0 bg-white  w-100 ">
              <select className="w-100 border product-category-border bg-white ">
                <option selected>All</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div className="col-xl-3 px-1">
            Registered Date
            <input type="date" className="form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn input-inside-text mr-1 btn-white border px-4"
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-dark px-3"
          >
            Search
          </button>
        </div>
      </div>

      <div className="merchant_report_page_mainContainer">
        <div className="merchant_report_page_mainContainer_head">
          <p>REPORT LISTING</p>
        </div>
        <div className="merchant_report_page_mainContainer_body">
          <div className="merchant_report_page_mainContainer_body_allContent">
            <div className="merchant_report_page_allContent_top">
              <div className="merchant_report_page_allContent_top_totalReport">
                ALL REPORT
              </div>
              <button>Export</button>
            </div>
            <table className="merchant_report_page_mainContainer_body_allContent_table">
              <tr className="merchant_report_page_mainContainer_body_allContent_table_headrow">
                <th>N0.</th>
                <th>ID</th>
                <th>merchant</th>
                <th>Rating</th>
                <th>Earning</th>
                <th>Product</th>
                <th>Product Sold</th>
                <th className="merchant_report_page_mainContainer_body_allContent_table_action">
                  {" "}
                  Action
                </th>
              </tr>
              <tr>
                <td>1</td>
                <td>123466778</td>
                <td className="merchant_report_page_mainContainer_body_allContent_table_productName">
                  Nick eStore
                </td>
                <td>3/5</td>
                <td>RM3,100.00</td>
                <td>12</td>
                <td>1700</td>
                <td className="merchant_report_page_mainContainer_body_allContent_table_action">
                  <button onClick={() => merchantAction()}>
                    <Image
                      src="/view.png"
                      className="order_page_view_icon"
                      alt="Header logo for admin dashboard"
                    />
                  </button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>123466778</td>
                <td className="merchant_report_page_mainContainer_body_allContent_table_productName">
                  Nick eStore
                </td>
                <td>3/5</td>
                <td>RM3,100.00</td>
                <td>12</td>
                <td>1700</td>
                <td className="merchant_report_page_mainContainer_body_allContent_table_action">
                  <IconButton
                    className="pointer"
                    // onClick={() => handleDeleteProduct(row.productId)}
                    // onClick={() => handleDeleteProduct(row.productId, permissions?.product?.includes('delete'))}
                  >
                    <SvgIcon
                      src="/icons/delete_icon.svg" alt="delete icon" />
                  </IconButton>
                </td>
              </tr>
            </table>
          </div>
          <div className="merchant_report_page_mainContainer_body_bottom">
            <form className="merchant_report_page_mainContainer_body_bottom_selectQty">
              <select>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </form>
            <div className="merchant_report_page_mainContainer_body_bottom_pagenation">
              <a href="#">&lt;</a>
              <a href="#">1</a>
              <a href="#">&gt;</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MerchantReport;
