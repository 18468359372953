import React, { useState } from 'react';
import SendBird from 'sendbird';
import { SendBirdProvider as SBProvider } from "sendbird-uikit";
import { getLocalStorage } from '../../../lib/session';
import { SENDBIRD_ADMIN_ID, SENDBIRD_API_KEY } from '../../../lib/config';
import CustomizedApp from './CustomizedApp';
import { AllowAccess, showToast } from '../../../lib/global';
import { breadcrumbAction } from '../../../redux/actions/config.action';
import { useDispatch } from "react-redux";
const breadcrumb = [
  { label: "Dashboard", url: "/dashboard" },
  { label: "Chat To Admin", url: null }
];


const name     = getLocalStorage('userName')
const adminId  = SENDBIRD_ADMIN_ID;
const userId   = getLocalStorage('storeId')?getLocalStorage('storeId'):getLocalStorage('uid');
const appId    = SENDBIRD_API_KEY;
const customType = 'MERCHANT_SUPERADMIN';

const MerchantToAdmin = () => {
  AllowAccess(["MERCHANT"]);
  const dispatch = useDispatch();
  const [activeChannel, setActiveChannel] = useState(null);
  dispatch(breadcrumbAction(breadcrumb));
  
  if(adminId == null){
    showToast("Admin Sendbird ID Is Not Found")
  }

  const startChat = async () => {
    try {
      const sb = new SendBird({ appId: appId });
      await sb.connect(userId, (user, error) => {
        if (error) {
          console.error('Failed to connect with Sendbird:', error);
        } else {
          console.log('Success connect to sendbird Sendbird:', user)
        }
      });
      const channelParams = new sb.GroupChannelParams();
      channelParams.isDistinct = true;
      channelParams.customType = customType;
      channelParams.addUserIds([adminId, userId]);

      const channel = await sb.GroupChannel.createChannel(channelParams);
      setActiveChannel(channel)
      console.log('Success Create Channel:', channel);
    } catch (error) {
      console.error('Failed Create Channel:', error);
    }
  };

  return (
    <div className="app-wrapper">
      <button onClick={startChat} className='btn btn-dark btn-md text-nowrap mx-3 mb-3'>Start Chat With Admin</button>
      <SBProvider appId={appId} userId={userId} nickname={name}>
        <CustomizedApp customTypesFilter={[customType]} activeChannel={activeChannel}/>
      </SBProvider>
    </div>
  );
};

export default MerchantToAdmin;
