import {
  Icon,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState,useEffect } from "react";
import InputField from "../../../../components/formControl/input/InputField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SvgIcon from "../../../../components/Images/SvgIcon";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../../redux/actions/config.action";
import { getLiveStream } from "../../../../redux/actions/marketing.action";
import moment from "moment";
import { AllowAccess, open_dialog } from "../../../../lib/global";
import { DELETE_BANNER_MODEL, DELETE_LIVESTREAM } from "../../../../lib/constant";
import ablity from "../../../../helpers/abilityGuard";

const LiveStreamCenter = () => {
  AllowAccess(["SUPERADMIN","MERCHANT"]);
  const history = useHistory();
  const [allLivestreamListing, setAllLivestreamListing] = useState([]);

  const dispatch = useDispatch();

  const breadcrumb = [{ label: "Livestream", url: null }];
  dispatch(breadcrumbAction(breadcrumb));

  useEffect(() => {
    getLiveStreamList();
  },[])

  const getLiveStreamList = () => {
    dispatch(getLiveStream())
      .then((res) => {
        setAllLivestreamListing(res.data.data);
        console.log("res", res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
  }

  const getBannersAfterDelete = (data) => {
    setAllLivestreamListing(data);
  };

  const handleDelete = async (id) => {
    open_dialog(DELETE_LIVESTREAM, {
      onSubmit: getBannersAfterDelete,
      id,
    });
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="px-2 py-3 mb-3 card ">
          <div className="col-12 row flex-wrap">
            <div className="min-width-200 col-md-3">
              <label>Livestream Name</label>
              <InputField
                type="text"
                className="form-control"
                placeholder="Input"
                id="livestreamName"
                // value={serchData.storeId}
                // onChange={onChangeMerchantSearchDetail}
              />
            </div>
            <div className="min-width-200 col-md-3">
              <label>Consumer ID</label>
              <InputField
                type="text"
                className="form-control"
                placeholder="Input"
                id="livestreamId"
                // value={serchData.merchantName}
                // onChange={onChangeMerchantSearchDetail}
              />
            </div>

            <div className="min-width-200 col-md-3">
              <label>Merchant Name</label>
              <InputField
                type="text"
                className="form-control"
                id="merchantId"
                // value={serchData.merchantRegisteredDate}
                // onChange={onChangeMerchantSearchDetail}
                // disabled={true}
              />
            </div>
            <div className="min-width-200 col-md-3">
              <label>Livestream Date</label>
              <InputField
                type="text"
                className="form-control"
                id="consumerId"
                // value={serchData.merchantRegisteredDate}
                // onChange={onChangeMerchantSearchDetail}
                // disabled={true}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn input-inside-text mr-1 btn-white border px-4"
              // onClick={() => resetSearch()}
            >
              Reset
            </button>
            <button
              type="button"
              className="btn input-inside-text mx-1 btn-danger px-3"
              // onClick={() => searchMerchat()}
            >
              Search
            </button>
          </div>
        </div>
        <div className="card p-3">
          <h5>LIVESTREAM LISTING</h5>
          <div className="card p-3 m-1 border rounded">
            <div>
              <h6 className="float-left">ALL LIVESTREAM(10)</h6>
              <div className="float-right">
                <div>
                  {ablity({model:'Marketing', action:'create'}) == 1 && (
                    <button
                      onClick={() =>
                        history.push(
                          "/marketing/all-promo/livestream/create"
                        )
                      }
                      className="btn btn-danger btn-sm mb-2"
                    >
                      + Create Live
                    </button>
                  )}
                </div>
              </div>
            </div>

            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="th_cell "
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Livestream Name</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            // onClick={() =>
                            //   getApiData({ item: "Rating", order: "ASC" })
                            // }
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            // onClick={() =>
                            //   getApiData({ item: "Rating", order: "DESC" })
                            // }
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Date</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            // onClick={() =>
                            //   getApiData({ item: "title", order: "ASC" })
                            // }
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            // onClick={() =>
                            //   getApiData({ item: "title", order: "DESC" })
                            // }
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell "
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Livestream By</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            // onClick={() =>
                            //   getApiData({ item: "Rating", order: "ASC" })
                            // }
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            // onClick={() =>
                            //   getApiData({ item: "Rating", order: "DESC" })
                            // }
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="table_action_head text-secondary"
                      component="th"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allLivestreamListing &&
                    allLivestreamListing.length > 0 &&
                    allLivestreamListing.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className="td_cell">
                          {row?.name || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.createdAt?moment(row.createdAt).format("DD/MM/YY"):"-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.merchant.merchantName || "N/A"}
                        </TableCell>

                        <TableCell className="d-flex  table_action_item">
                          {ablity({model:'Marketing', action:'read'}) == 1 && (
                              <IconButton
                                onClick={() =>
                                  history.push(
                                    "/marketing/all-promo/livestream/go-live"
                                  )
                                }
                                className="pointer"
                                // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                              >
                                <SvgIcon
                                  src="/icons/view_icon.svg"
                                  alt="view icon"
                                />
                              </IconButton>
                            )}

                          {ablity({model:'Marketing', action:'update'}) == 1 && (
                              <IconButton
                                onClick={() =>
                                  history.push(
                                    "/marketing/all-promo/livestream/edit/"+row.id
                                  )
                                }
                                className="pointer"
                                // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                              >
                                <SvgIcon
                                  src="/icons/edit_icon.svg"
                                  alt="view icon"
                                />
                              </IconButton>
                          )}

                          {ablity({model:'Marketing', action:'delete'}) == 1 && (
                              <IconButton
                                onClick={() => handleDelete(row.id)}
                                className="pointer"
                              >
                                <SvgIcon
                                  src="/icons/delete_icon.svg"
                                  alt="view icon"
                                />
                              </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <div className="col-12">
              <div className="float-right mb-1">
                <Stack spacing={2}>
                  <Pagination
                    // count={Math.ceil(props.totalItem / 10)}
                    count={3}
                    shape="rounded"
                    size="small"
                    // onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LiveStreamCenter;
