import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import CustomizedTables from "../../components/tables";
import * as api from "../../services/language";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton } from "@mui/material";
import {
  AllowAccess,
  close_dialog,
  open_dialog,
  startLoader,
  stopLoader,
} from "../../lib/global";

const LanguagePage = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [columns, setColumns] = useState([
    { id: "id", label: "SL No.", type: "index" },
    { id: "language", label: "Language", align: "left" },
    { id: "code", label: "Language Code", align: "left" },
    {
      id: "status",
      label: "Status",
      align: "left",
      component: (status) => (status == 1 ? "Active" : "Inactive"),
    },
    {
      id: "code",
      label: "Action",
      component: (value, data) => {
        return (
          <div className="d-flex align-items-center">
            <button
              disabled={data.code == "en"}
              onClick={() => handleStatusChange(data)}
              className={`btn btn-${
                data.status == 1 ? "danger" : "success"
              } fit-content  btn-sm`}
            >
              {data?.status == 1 ? "disable" : "enable"}
            </button>
            <IconButton
              disabled={data.code == "en"}
              onClick={() => handleDelete(data)}
            >
              <DeleteForeverIcon color="secondary" />
            </IconButton>
          </div>
        );
      },
    },
  ]);

  useEffect(() => {
    startLoader();
    getTotallData();
  }, []);

  const getTotallData = () => {
    api
      .getLanguage()
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.data) {
          setData(res.data.data.data);
          setTotalCount(res.data.data.totalCount);
          stopLoader();
        }
      })
      .catch((error) => {
        stopLoader();
        console.error(error);
      });
  };

  const handleStatusChange = (data) => {
    startLoader();
    api
      .changeLanguageStatus({ _id: data._id, status: data.status == 1 ? 0 : 1 })
      .then(() => getTotallData())
      .catch((err) => console.error(err));
  };

  const handleDelete = (data) => {
    startLoader();
    api
      .deleteLanguage({ _id: data._id })
      .then(() => getTotallData())
      .catch((err) => console.error(err));
  };

  const handlePageChange = () => {
    console.log("page change");
  };

  const handleAddLanguage = () => {
    open_dialog("ADD_LANGUAGE", {
      addLanguage: api.addLanguage,
      handleClose: () => getTotallData(),
    });
    // close_dialog("ADD_LANGUAGE")
  };
  return (
    <React.Fragment>
      <div className="col-12 p-3">
        <div className="col-12 d-flex py-3 card_bg shadow">
          <h3 className="mr-auto page_title">
            Language
          </h3>
          <button
            onClick={handleAddLanguage}
            title="Add New Language"
            className="btn btn-success btn-sm ml-auto my-auto fit-content"
          >
            + Add
          </button>
        </div>
        <div className="card shadow">
          <CustomizedTables
            tableData={data}
            columns={columns}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
          ></CustomizedTables>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LanguagePage;
