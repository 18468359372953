import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/buttons";
import CustomCheckbox from "../../components/checkbox";
import Input from "../../components/formControl/input";
import {
  CATEGORY_SELECTION_DIALOG,
  PASSWORD_FORGET_INFO_MODAL,
} from "../../lib/constant";
import { open_dialog, setSessionData, showToast } from "../../lib/global";
import {
  getLocalStorage,
  removeLocalStorageKey,
  setLocalStorage,
} from "../../lib/session";
import {
  forgetPasswordSendLink,
  loginApi,
} from "../../redux/actions/auth.action";
import OneSignal from "react-onesignal";
import { ONESIGNAL_API_ID } from "../../lib/config";
import { handleDocumentTitleLogin } from "../../lib/useDocumentTitle";
// import { loginApi } from '../../services/login.service'

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(
    Boolean(getLocalStorage("rememberMe"))
  );
  const [loading, setLoading] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [playerToken, setPlayerToken] = useState("");

  useEffect(() => {
    if (rememberMe) {
      const i_email = getLocalStorage("email");
      const i_password = getLocalStorage("password");
      setEmail(i_email);
      setPassword(i_password);
    }

    OneSignal.init({
      appId: ONESIGNAL_API_ID,
    }).then(async () => {
      let playerTokenId = await OneSignal.getUserId();
      setPlayerToken(playerTokenId);
    });
  }, []);

  const handleFormInput = (event, key) => {
    const value = event.target.value;
    switch (key) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        return;
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    // setLoading(true)
    if (!email || !password) {
      showToast("Email and password fields are requrired!", "error");
    }

    if (rememberMe) {
      setLocalStorage("rememberMe", true);
      setLocalStorage("email", email);
      setLocalStorage("password", password);
    } else {
      removeLocalStorageKey("rememberMe");
      removeLocalStorageKey("email");
      removeLocalStorageKey("password");
    }
    const payload = { email, password, playerToken };

    dispatch(loginApi({ email, password, playerToken }))
      .then((response) => {
        showToast("You logged in successfully!", "success");
        setLocalStorage("token", response.data.data.token);
        setLocalStorage("storeId", response.data.data.storeId);
        setLocalStorage("role", response.data.data.role);
        setLocalStorage(
          "userName",
          response.data.data.username || response.data.data.name
        );
        setLocalStorage("uid", response.data.data.userId);
        setLocalStorage("email", payload.email);
        setLocalStorage("createdBy", response.data.data.createdBy);
        setLocalStorage(
          "assignMethod",
          JSON.stringify(response.data.data.assignMethod)
        );
        setLocalStorage("rules", JSON.stringify(response.data.data.rules));
        handleDocumentTitleLogin(localStorage.getItem("role"));
        history.push("/dashboard");
      })
      .catch((error) => {
        showToast(
          error?.response?.data?.message || "Failed to login!",
          "error"
        );
      });

    // call login api and redirect to homepage
    // loginApi(payload).then(res=>{
    //     setLoading(false)
    //     if(res && res.data && res.data.data){
    //         showToast(res.data.message, 'success')
    //         setLocalStorage('token', res.data.data.token)
    //         history.push('/');
    //         setSessionData(res.data.data)
    //     }
    // }).catch(error=>{
    //     console.error(error)
    //     setLoading(false)
    //     showToast(error?.response?.data?.message || "Falied to login!", 'error')
    // })
  };
  const handleForgetPassword = () => {
    setForgetPassword(false);
  };

  const handleRememberMe = (event) => {
    const value = event.target.checked || false;
    setRememberMe(value);
  };

  const handleSubmitPasswordForgetForm = (e) => {
    e.preventDefault();
    console.log("trigger");
    dispatch(forgetPasswordSendLink({ email }))
      .then((res) => {
        open_dialog(PASSWORD_FORGET_INFO_MODAL, {
          onSubmit: () => setForgetPassword(false),
          message: res?.data?.message || "OK !",
          isSuccess: true,
          email,
        });
      })
      .catch((error) => {
        open_dialog(PASSWORD_FORGET_INFO_MODAL, {
          onSubmit: () => setForgetPassword(false),
          message:
            error?.response?.data?.message || "Failed to send link!, Try again",
          isSuccess: false,
          email,
        });
      });
  };

  const handleTest = () => {
    open_dialog(CATEGORY_SELECTION_DIALOG, {
      minWidth: "80vw",
      maxWidth: "80vw",
    });
  };

  return (
    <React.Fragment>
      <div className="vh-100 landing_image position-relative">
        <div className="position-absolute w-100 h-100">
          <img
            className="landing_image_src"
            src="/images/loginPageBackgroundNew.png"
            // loginPageBackground.jpg
            alt="login page background image"
          />
        </div>
        <br />
        <br />
        <div className="m-auto px-5 h-100 d-flex flex-column justify-content-between">
          <div className="col d-flex flex-colummn">
            <div className="col-2">
              <h3 className="font-weight-bold mb-1">
                <a href="https://uu2.co/">HOME</a>
              </h3>
              <div>
                <a href="https://uu2.co/">ABOUT US</a>
              </div>
              <div>
                <a href="https://uu2.co/story/">OUR STORY</a>
              </div>
              <div>
                <a href="https://uu2.co/about-us/">CONTACT US</a>
              </div>
            </div>
            <div className="col-md-7 d-flex">
              <h1 className="display-1 font-weight-bolder pt-5 pl-4 d-flex justify-content-center">
                Welcome
              </h1>
            </div>
            {/* <div className="col-3 d-flex flex-row-reverse">
              <div>
                <img
                  src="UU2_Transparent.png"
                  width={"auto"}
                  height={"50px"}
                  alt="uu2 icon"
                />
              </div>
            </div> */}
          </div>
          <div className="row mb-5">
            <div className="col-12 mb-3 d-flex flex-row align-items-center justify-content-end">
              <div className="mx-2">FOLLOW US ON</div>
              <a href="https://www.instagram.com/uu2_official/" target="_blank">
                <div className="mx-2" style={{ width: "35px" }}>
                  <img className="w-100" src="/images/instagram-icon.png" />
                </div>
              </a>
              <a href="https://www.facebook.com/uu2official" target="_blank">
                <div className="mx-2" style={{ width: "35px" }}>
                  <img className="w-100" src="/images/facebook-icon.png" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="p-4 position-absolute text-white login_input_box_section">
          <div>
            {!forgetPassword ? (
              <form onSubmit={handleSubmitForm}>
                <br />
                <br />
                <br />
                <p className="text-nowrap small">
                  The best deals happen when you buy together
                </p>
                <div>
                  <Input
                    className="input__textField mt-2 w-100"
                    type="text"
                    name="email"
                    id="email"
                    onChange={(e) => handleFormInput(e, "email")}
                    value={email}
                    placeholder="Username / Email"
                  />
                </div>
                <div>
                  <Input
                    className="input__textField mt-2 w-100"
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    onChange={(e) => handleFormInput(e, "password")}
                    placeholder="Password"
                  />
                </div>
                <br />

                <div className="text-dark ">
                  <div
                    className="m-0 p-0 btn border-none underline font-weight-bold"
                    onClick={() => setForgetPassword(true)}
                  >
                    Forget Password
                  </div>
                </div>
                <br />

                <Button type="submit" role="button" className="login_btn">
                  {loading ? "loading..." : "Log In"}
                </Button>
              </form>
            ) : (
              <form onSubmit={handleSubmitPasswordForgetForm}>
                <br />
                <br />
                <br />
                <p className="text-nowrap small">
                  The best deals happen when you buy together
                </p>
                <h4 className="font-weight-bold text-dark text-center">
                  Forget Password
                </h4>
                <br />

                <div>
                  <label htmlFor="email">Enter Your Email</label>
                  <Input
                    className="input__textField w-100"
                    type="text"
                    name="email"
                    id="email"
                    onChange={(e) => handleFormInput(e, "email")}
                    value={email}
                    placeholder="Username / Email"
                  />
                </div>
                <br />
                <Button
                  type="submit"
                  role="button"
                  className="login_btn"
                  onClick={handleSubmitPasswordForgetForm}
                >
                  Change Password
                </Button>
              </form>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>

    // <React.Fragment>
    //     <div id="login" className="login_page">
    //         <div id="overlay"></div>
    //         <div className='login p-3 d-flex justify-content-center align-items-center'>
    //             <div className='login__container d-flex'>
    //                 <div className='form__section  border d-flex flex-column container'>
    //                     {/* <h2>Login </h2> */}
    //                     <div className="login_page_logo w-100 d-flex">
    //                         <img src="UU2_Transparent.png" alt="official logo" style={{ maxWidth: "131px" }} />
    //                     </div>
    //                     <form onSubmit={handleSubmitForm} className='form text-start'>
    //                         <p className='m-0'>Welcome to UU2 Admin Site</p>
    //                         <h2 className=''>Login to your account</h2>
    //                         <div className='input__box mb-3'>
    //                             {/* <p  className="mb-0 text-start">Email</p> */}
    //                             <Input
    //                                 className="input__textField mt-2 w-100"
    //                                 type='text'
    //                                 name="email"
    //                                 id='email'
    //                                 onChange={(e) => handleFormInput(e, 'email')}
    //                                 value={email}
    //                                 placeholder='Username / Email' />
    //                         </div>
    //                         <div className='input__box mb-3'>
    //                             {/* <p className="mb-0 text-start">Password</p> */}
    //                             <Input
    //                                 className="input__textField mt-2 w-100"
    //                                 type='password'
    //                                 name="password"
    //                                 id='password'
    //                                 value={password}
    //                                 onChange={(e) => handleFormInput(e, 'password')}
    //                                 placeholder='Password' />
    //                         </div>
    //                         {/* <div className='remember__forgetPass d-flex justify-content-between px-2'> */}
    //                         {/* <CustomCheckbox
    //                             label="Remember Me"
    //                             checked={rememberMe}
    //                             onChange={handleRememberMe}
    //                             className='m-0 p-0'
    //                         /> */}
    //                         {/* <a className='m-0 p-0'>Forget Password</a> */}
    //                         {/* </div> */}
    //                         <br />
    //                         <Button
    //                             type='submit'
    //                             role="button"
    //                             className='login_btn'>
    //                             {loading ? "loading..." : "Log In"}
    //                         </Button>
    //                     </form>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //     <style jsx="true">{`
    //     .login_page{
    //         background-image: url(/images/login_background.jpeg);
    //         background-repeat: no-repeat;
    //         background-size: cover;
    //     }
    // `}</style>
    // </React.Fragment>
  );
};

export default LoginPage;

// [{"email": "superadmin123@gmail.com", "phone": "78787878799", "userId": "8bc4aa32-277f-457c-977e-5868239daf955", "loginId": "superadmin1234@", "storeId": "UU2M0F0", "password": "superadmin@12345", "username": "SUPERADMIN", "passportNumber": "98989"}]
