import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Image from "../../../../../components/Images/Image";
import { breadcrumbAction } from "../../../../../redux/actions/config.action";
import { open_dialog } from "../../../../../lib/global";
import { GO_LIVESTREAM_SHOW_PRODUCT,GO_LIVESTREAM_SHOW_PROMO } from "../../../../../lib/constant";
const ContentGoLive = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const breadcrumb = [
    { label: "Livestream", url: "/marketing/all-promo/livestream" },
    {
      label: "Create Livestream",
      url: "/marketing/all-promo/livestream/create-livestream",
    },
    { label: "Go Live", url: null },
  ];

  const handelAddProduct = () => {
    open_dialog(GO_LIVESTREAM_SHOW_PRODUCT, {
      onSubmit: selectProductSuccess,
    });
  };
  const handelAddPromo = () => {
    open_dialog(GO_LIVESTREAM_SHOW_PROMO, {
      onSubmit: selectPromoSuccess,
    });
  };
  const selectProductSuccess = (data) => {
    console.log(data);
  };
  const selectPromoSuccess = (data) => {
    console.log(data);
  };
  dispatch(breadcrumbAction(breadcrumb));

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-6">
                <h4>Streatwear Sales</h4>
              </div>
              <div className="col-6">
                <div className="float-right">
                  <button className={"btn btn-outline-dark mr-1 border-0"}>
                    <Image
                      src="/icons/Share_live.png"
                      className="teamPurchase_chat_icon mx-1 mb-1"
                      alt="Header logo for admin dashboard"
                    />
                    Share Link</button>
                  <button className={"btn btn-outline-dark mr-1"}>Notify Follower</button>
                  <button className={"btn btn-outline-dark mr-1"} onClick={history.goBack}>Back</button>
                  <button className={"btn btn-dark"}>Go Live</button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-1">
                  <h6>Toolbox</h6>
                  <button
                    className={"btn btn-outline-dark btn-sm btn-block mb-1 mt-2"}
                    onClick={handelAddProduct}
                  >
                    <Image
                      src="/icons/best_product.png"
                      className="mx-1 mb-1"
                      style={{ width: "25px" }}
                    />
                    Product</button>
                  <button
                    className={"btn btn-outline-dark btn-sm btn-block mb-1"}
                    style={{fontSize:'13px'}}
                    onClick={() => handelAddPromo()}
                  >
                    <Image
                      src="/icons/promo.png"
                      className="mx-1 mb-1"
                      style={{ width: "25px" }}
                    />
                    Promotion</button>
              </div>
              <div className="col-8">
                <div className="card">
                  <div
                    className="bg-dark rounded-top"
                    style={{ minHeight: "60vh" }}
                  >
                    <div className="d-flex flex-column justify-content-center vh-50">
                      <h5 className="text-white text-center">
                        Live with streaming software
                      </h5>
                      <br />
                      <div className="row m-0 mb-2">
                        <div className="col-2 text-white text-right">Url</div>
                        <div className="col-8">
                          <input
                            type="text"
                            className="form-control w-100"
                            placeholder="http://testing.com"
                          />
                        </div>
                        <div className="col-2">
                          <button className="btn btn-warning text-dark">Copy</button>
                        </div>
                      </div>
                      <div className="row m-0">
                        <div className="col-2 text-white text-right">Key</div>
                        <div className="col-8">
                          <input
                            type="text"
                            className="form-control w-100"
                            placeholder="my.1234.live"
                          />
                        </div>
                        <div className="col-2">
                          <button className="btn btn-warning text-dark">Copy</button>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div className="row m-0 fw-lighter py-2">
                    <div className="col-6 row m-0 justify-content-end">
                    </div>
                  </div>
                </div>
                <br />
                <h6>Live Real-Time Data</h6>
                <div className="card p-2 pt-3">
                  <div className="row">
                    <div className="col-6 p-2" style={{backgroundColor:"#ecf0f1"}}>
                      <div className="text-secondary fntSz14 text-center">Viewers</div>
                      <div className="text-center">0</div>
                    </div>
                    <div className="col-6 p-2" style={{backgroundColor:"#ecf0f1"}}>
                      <h4 className="text-secondary fntSz14 text-center text">Likes</h4>
                      <div className="text-center">0</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="card">
                  <div className="card-header">
                    <h6>Comments</h6>
                  </div>
                  <div className="card-body" style={{border:'1px solid gray'}}>
                    <div
                      className="p-2 d-flex justify-content-center align-items-center text-secondary"
                      style={{ minHeight: "45vh" }}
                    >
                      No Comment
                    </div>
                  </div>
                  <div className="card-footer">
                    <textarea className="form-control" rows="3" placeholder="Comment"></textarea>
                    <div className="row">
                      <div className="col-6 pt-2">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                            Pin
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <button className=" btn btn-secondary mt-2 float-right">Send</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContentGoLive;
