import dayjs, { Dayjs } from 'dayjs';
import moment, { Moment } from 'moment';
import { DateTime } from 'luxon';

export function makeJSDateObject(date: Date | Moment | DateTime | Dayjs) {
  if (date instanceof dayjs) {
    return date.clone().toDate();
  }

  if (moment.isMoment(date)) {
    return date.clone().toDate();
  }

  if (date instanceof DateTime) {
    return date.toJSDate();
  }

  if (date instanceof Date) {
    return new Date(date.getTime());
  }

  return date; // handle case with invalid input
}

/**
 * Format Date
 * @param date
 * @param formatString
 * @returns {string}
 */
export function formatDate(date: Date | Moment | DateTime | Dayjs, formatString: string) {
  return moment(date).format(formatString);
}
