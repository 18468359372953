
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/**
 * @description Use this component for date range
 * @author jagannath
 * @date 23/04/2021
 * @param props:Object - {startDate, endDate}
 * @param handleDateChange: Function - callback ({startDate, endDate})
 * @return {*}: Callback Function - { startDate:Number, endDate:Number }
 */
const DateRangePicker = (props) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        props.handleDateChange?.({ start: new Date(startDate).valueOf(), end: new Date(endDate).valueOf() })
        // props.handleChange?.({startDate: new Date(startDate).valueOf(), endDate: new Date(endDate).valueOf()})
    }, [startDate, endDate])
    return (
        <React.Fragment>
            <div className="row px-2   ">
         
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    isClearable
                    placeholderText="start date..."
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date('04/11/2021')}
                    maxDate={new Date()}
                    className="daterange__picker "
                />
                <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    className="daterange__picker"
                    startDate={startDate}
                    isClearable
                    placeholderText="end date..."
                    endDate={endDate}
                    minDate={startDate ? startDate : new Date('04/11/2021')}
                    // minDate={new Date('04/11/2021')}
                    maxDate={moment().add(1, 'day').toDate()}
                />
            
            </div>
            <input type="text"  name="daterange" value="01/01/2018 - 01/15/2018" onClick={(e)=>console.log(e)}/>

           
            <style jsx="true">{`
            .daterange__picker{
                height: 38px;
                border-radius: 5px;
                border: 1px solid lightgrey;
                color: black;
                padding-left: 10px;
                width:100%;
            }
        `}</style>
        </React.Fragment>
    )
}

export default DateRangePicker

