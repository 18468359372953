import {
    IconButton,
    Table,
    Pagination,
    Stack,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../components/Images/SvgIcon";
import ablity from "../../../helpers/abilityGuard";

import {
    getAllProductList,
    getProductsBymerchantId,
} from "../../../redux/actions/product.action";
import moment from "moment";

import {
    startLoader,
    stopLoader,
    showToast,
    open_dialog,
} from "../../../lib/global";
import { ACCESS_DENIED, PRODUCT_DELETE_DIALOG } from "../../../lib/constant";
import { getLocalStorage } from "../../../lib/session";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";



function Allproduct(props) {
    const [permissions, setPermissions] = useState(getLocalStorage("assignMethod"));
    const history = useHistory();
    const dispatch = useDispatch();

    const [allProductData, setAllProductData] = useState([]);

    const [pageNo, setPageNo] = useState(1);
    const [pageLimit, setPageLimit] = useState(10)
    const limit = useRef(10)
    const [offset, setOffset] = useState(0)
    const refSearchData = useRef({})

    const { afterDelete } = props;



    const addProduct = (item) => {
        if (!item && item !== undefined) {
            open_dialog(ACCESS_DENIED, {});
        } else if (item == "undefined" || item == undefined) {
            history.push(`/products/add-product`);
        } else {
            history.push(`/products/add-product`);
        }
    };
    const editAction = (id, item) => {
        if (!item && item !== undefined) {
            open_dialog(ACCESS_DENIED, {});
        } else if (item == "undefined" || item == undefined) {
            history.push(`edit?id=${id}`);
        } else {
            history.push(`edit?id=${id}`);
        }
    };
    const viewAction = (id) => {
        history.push(`view?id=${id}`);
    };




    useEffect(() => {
        // if (permissions !== "undefined") {
        //     setPermissions(JSON.parse(getLocalStorage("assignMethod")));
        // } else {
        //     setPermissions({});
        // }
    }, []);



    useEffect(() => {
        let filteringdata
        if (pageLimit != limit.current) {
            limit.current = pageLimit
            props.reset()
            setPageNo(1)
            setOffset(0)
            filteringdata = {
                status: props.status,
                limit: pageLimit,
                offset: 0
            };
        }
        else if (refSearchData.current!== props.search) {
            setPageNo(1)
            setOffset(0)
            filteringdata = {
                ...props.search,
                status: props.status,
                limit: pageLimit,
                offset: 0
            };
            refSearchData.current=props.search
        }
        else {
            filteringdata = {
                ...props.search,
                status: props.status,
                offset,
                limit: pageLimit
            };
        }
        apiData(filteringdata)
    }, [offset, pageLimit, props.search]);


    const apiData = (filteringData) => {
        startLoader()
        if (!props.merchantId) {
            const response = dispatch(getAllProductList(filteringData));
            response
                .then((data) => {
                    // setPage(1);
                    props.setTotals(data.data.total || {})
                    // setAllProductData(data && data.data && data.data.data);
                    console.log(data.data.data);
                    setAllProductData(
                        data &&
                        data.data &&
                        data.data.data.map((row, index) => {
                            return {
                                productName: row?.title || "N/A",
                                productType: row?.productType || "N/A",
                                rating: row?.rating || 0,
                                categoryName: row?.categoryName || "N/A",
                                lowestPrice: Number(row?.lowestPrice?.price) || "N/A",
                                createdDate: row?.createdAt,
                                //   ? moment(row.createdAt).format("DD/MM/YY")
                                //   : "N/A",
                                // merchantName: row?.merchantName || "N/A",
                                merchantName: row?.merchant.preferredMerchantName || "N/A",
                                stock: row?.totalStock || 0,
                                status: row?.statusText || "N/A",
                                productId: row?.productId,
                            };
                        })
                    );

                    // setTotalCount(data.data.data.length);
                    // setPages(Math.ceil(data.data.data.length/10))
                    setTimeout(() => {
                        stopLoader();
                    }, 1000);
                })
                .catch((error) => {
                    setTimeout(() => {
                        stopLoader();
                    }, 1000);
                });
        } else {
            const response = dispatch(getProductsBymerchantId(props.merchantId));
            response
                .then((data) => {
                    // setPage(1);
                    // setAllProductData(data && data.data && data.data.data)
                    setAllProductData(
                        data &&
                        data.data &&
                        data.data.data.map((row, index) => {
                            return {
                                productName: row?.title || "N/A",
                                productType: row?.productType || "N/A",
                                rating: row?.rating || 0,
                                categoryName: row?.categoryName || "N/A",
                                lowestPrice: Number(row?.lowestPrice?.price) || "N/A",
                                createdDate: row?.createdAt,
                                //   ? moment(row.createdAt).format("DD/MM/YY")
                                //   : "N/A",
                                // merchantName: row?.merchantName || "N/A",
                                merchantName: row?.merchant.preferredMerchantName || "N/A",
                                stock: row?.totalStock || 0,
                                status: row?.statusText || "N/A",
                                productId: row?.productId,
                            };
                        })
                    );

                    // setTotalCount(data.data.data.length);
                    stopLoader();
                })
                .catch((error) => {
                    stopLoader();
                });
        }
    }





    const handlePageChange = (event, value) => {
        // refOffset(value - 1)
        setOffset(value - 1);
        setPageNo(value);
    };



    const getProductAfterDelete = (data) => {
        afterDelete(data);
        // setAllProductData(data.data);
        apiData({})
        // setTotalCount(data.data.length);
    };
    /**
     * @author prabhakar sarkar
     *@description delete modal
     */
    const handleDeleteProduct = (id, item) => {
        if (!item && item !== undefined) {
            open_dialog(ACCESS_DENIED, {});
        } else if (item == "undefined" || item == undefined) {
            open_dialog(PRODUCT_DELETE_DIALOG, {
                onSubmit: getProductAfterDelete,
                id,
            });
        } else {
            open_dialog(PRODUCT_DELETE_DIALOG, {
                onSubmit: getProductAfterDelete,
                id,
            });
        }
    };

    const handleSorting = (item, sortType, isDate = false) => {
        startLoader();
        try {
            if (typeof allProductData[0][item] === "number") {
                let sortedData = allProductData.sort((a, b) => {
                    if (sortType == "asc") {
                        return a[item] - b[item];
                    } else if (sortType == "desc") {
                        return b[item] - a[item];
                    }
                });
                setTimeout(() => {
                    setAllProductData([...sortedData]);
                    stopLoader();
                }, [1000]);
            } else if (typeof allProductData[0][item] === "string" && !isDate) {
                let sortedData = allProductData.sort((a, b) => {
                    let fa = a[item].toLowerCase(),
                        fb = b[item].toLowerCase();
                    if (sortType == "asc") {
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    } else if (sortType == "desc") {
                        if (fa < fb) {
                            return 1;
                        }
                        if (fa > fb) {
                            return -1;
                        }
                        return 0;
                    }
                });

                setTimeout(() => {
                    setAllProductData([...sortedData]);
                    stopLoader();
                }, [1000]);
            } else if (typeof allProductData[0][item] === "string" && isDate) {
                let sortedData = allProductData.sort((a, b) => {
                    let da = new Date(a[item]);
                    let db = new Date(b[item]);

                    if (sortType == "asc") {
                        return da - db;
                    } else if (sortType == "desc") {
                        return db - da;
                    }
                });
                setTimeout(() => {
                    setAllProductData([...sortedData]);
                    stopLoader();
                }, [1000]);
            }
        } catch (error) {
            stopLoader()
        }
    };


    return (
        <React.Fragment>
            <div className="d-flex justify-content-between">
                <div>
                    {/* <span>{totalCount || 0} Products</span>
           */}
                    <span>{props?.totalItem || 0} Products</span>
                </div>
                <div className="d-flex my-3">
                    {/* <input
                        type="search"
                        placeholder="search..."
                        className="form-control mx-3"
                        // onChange={(e) => setSearchData(e.target.value)}
                        // onChange={(e) => getApiData({status,search:e.target.value,...props.search})}
                        onChange={(e) => setSearchData({ ...searchData, search: e.target.value })}


                    /> */}
                    {ablity({model:'Product', action:'create'}) == 1 && (
                        <button
                            // onClick={() => addProduct()}
                            onClick={() => addProduct(permissions?.product?.includes("create"))}
                            type="button"
                            className="btn btn-dark btn-sm text-nowrap mx-3"
                        // disabled={permmisions.product.includes('add') ? "false" : "true"}
                        >
                            + Add product
                        </button>
                    )}
                </div>
            </div>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="th_cell" component="th">
                                No
                            </TableCell>
                            <TableCell className="th_cell" component="th" align="left">
                                <div className="d-flex  ">
                                    <div>Name</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "0px 3px",
                                            }}
                                            onClick={() => handleSorting("productName", "asc")}
                                        />
                                        <ExpandMoreIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "-5px 3px",
                                            }}
                                            onClick={() => handleSorting("productName", "desc")}
                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell className="th_cell" component="th" align="center">
                                <div className="d-flex  ">
                                    <div>Type</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "0px 3px",
                                            }}
                                            onClick={() => handleSorting("productType", "asc")}
                                        // onClick={() => getApiData({ item: "title", order: "ASC" })}
                                        />
                                        <ExpandMoreIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "-5px 3px",
                                            }}
                                            onClick={() => handleSorting("productType", "desc")}
                                        // onClick={() => getApiData({ item: "title", order: "DESC" })}
                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell className="th_cell " component="th" align="center">
                                <div className="d-flex  ">
                                    <div>Rating</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "0px 3px",
                                            }}
                                            onClick={() => handleSorting("rating", "asc")}
                                        />
                                        <ExpandMoreIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "-5px 3px",
                                            }}
                                            onClick={() => handleSorting("rating", "desc")}
                                        />
                                    </div>
                                </div>
                            </TableCell>

                            <TableCell className="th_cell " component="th" align="center">
                                <div className="d-flex  ">
                                    <div>Category</div>

                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "0px 3px",
                                            }}
                                            onClick={() => handleSorting("categoryName", "asc")}

                                        // onClick={() => getApiData({ item: "categoryName", order: "ASC" })}
                                        />
                                        <ExpandMoreIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "-5px 3px",
                                            }}
                                            onClick={() => handleSorting("categoryName", "desc")}

                                        // onClick={() => getApiData({ item: "categoryName", order: "DESC" })}
                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell className="th_cell" component="th" align="center">
                                <div className="d-flex  ">
                                    <div>Comm Rate</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "0px 3px",
                                            }}
                                            onClick={() => handleSorting("lowestPrice", "asc")}

                                        // onClick={() => getApiData({ item: "title", order: "ASC" })}
                                        />
                                        <ExpandMoreIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "-5px 3px",
                                            }}
                                            onClick={() => handleSorting("lowestPrice", "desc")}

                                        // onClick={() => getApiData({ item: "title", order: "DESC" })}
                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell className="th_cell" component="th" align="center">
                                <div className="d-flex  ">
                                    <div>Date Added</div>

                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "0px 3px",
                                            }}
                                            onClick={(isDate) =>
                                                handleSorting("createdDate", "asc", (isDate = true))
                                            }
                                        // onClick={() => apiData({ item: "createdAt", order: "ASC" })}
                                        />
                                        <ExpandMoreIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "-5px 3px",
                                            }}
                                            onClick={(isDate) =>
                                                handleSorting("createdDate", "desc", (isDate = true))
                                            }
                                        // onClick={() => apiData({ item: "createdAt", order: "DESC" })}
                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell className="th_cell" component="th" align="center">
                                <div className="d-flex  ">
                                    <div>Merchant</div>

                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "0px 3px",
                                            }}
                                            onClick={() => handleSorting("merchantName", "asc")}
                                        // onClick={() => getApiData({ item: "merchantName", order: "ASC" })}
                                        />
                                        <ExpandMoreIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "-5px 3px",
                                            }}
                                            onClick={() => handleSorting("merchantName", "desc")}

                                        // onClick={() => getApiData({ item: "merchantName", order: "DESC" })}
                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell className="th_cell" component="th" align="center">
                                <div className="d-flex  ">
                                    <div>Stock</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "0px 3px",
                                            }}
                                            onClick={() => handleSorting("stock", "asc")}

                                        // onClick={() => getApiData({ item: "title", order: "ASC" })}
                                        />
                                        <ExpandMoreIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "-5px 3px",
                                            }}
                                            onClick={() => handleSorting("stock", "desc")}

                                        // onClick={() => getApiData({ item: "title", order: "DESC" })}
                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell className="th_cell" component="th" align="center">
                                <div className="d-flex  ">
                                    <div>Status</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "0px 3px",
                                            }}
                                            onClick={() => handleSorting("status", "asc")}
                                        // onClick={() => getApiData({ item: "title", order: "ASC" })}
                                        />
                                        <ExpandMoreIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                margin: "-5px 3px",
                                            }}
                                            onClick={() => handleSorting("status", "desc")}

                                        // onClick={() => getApiData({ item: "title", order: "DESC" })}
                                        />
                                    </div>
                                </div>
                            </TableCell>

                            <TableCell className="table_action_head" component="th">
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!allProductData?.length && (
                            <div className="d-flex justify-content-center">
                                <h3 className="text-muted m-auto">No data found!</h3>
                            </div>
                        )}

                        {allProductData &&
                            allProductData.length > 0 &&
                            allProductData.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                    }}
                                >
                                    <TableCell align="left" className="td_cell">
                                        {offset * pageLimit + (index + 1)}
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        <div>{row?.productName || "N/A"}</div>
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        {row.productType || "N/A"}
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        {String(row.rating) || "N/A"}
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        {row.categoryName || "N/A"}
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        {row?.lowestPrice?.price || "N/A"}
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        {row.createdDate ? moment(row?.createdDate).format("DD/MM/YY") : "N/A"}
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        {row.merchantName || "N/A"}
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        {String(row.stock) || "N/A"}
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        {row.status || "N/A"}
                                    </TableCell>

                                    <TableCell className="d-flex justify-content-between table_action_item">
                                        {ablity({model:'Product', action:'read'}) == 1 && (
                                            <IconButton
                                                className="pointer"
                                                onClick={() => viewAction(row.productId)}
                                            >
                                                <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                                            </IconButton>
                                        )}

                                        {ablity({model:'Product', action:'update'}) == 1 && (
                                            <IconButton
                                                // onClick={() => history.push(`edit?id=${row.productId}`)}
                                                className="pointer"
                                                onClick={() =>
                                                    editAction(
                                                        row.productId,
                                                        permissions?.product?.includes("update")
                                                    )
                                                }
                                            >
                                                <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                                            </IconButton>
                                        )}


                                        {ablity({model:'Product', action:'delete'}) == 1 && (
                                          <IconButton
                                            className="pointer"
                                            // onClick={() => handleDeleteProduct(row.productId)}
                                            onClick={() =>
                                              handleDeleteProduct(
                                                row.productId,
                                                permissions?.product?.includes("delete")
                                              )
                                            }
                                          >
                                              <SvgIcon src="/icons/delete_icon.svg" alt="delete icon" />
                                          </IconButton>
                                        )}

                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <div className="d-flex justify-content-between align-items-center">
                <PagenationDropdown setPageQuant={setPageLimit} />

                <div className="float-right mb-1">
                    <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil((props?.totalItem || 0) / pageLimit)}
                            shape="rounded"
                            size="small"
                            page={pageNo}
                            onChange={handlePageChange}
                        />
                    </Stack>
                </div>
            </div>
            <br />
        </React.Fragment>
    );
}

export default Allproduct;
