import React, { useEffect, useState } from "react"
import { AllowAccess, startLoader, stopLoader } from "../../lib/global";
import { useDispatch } from "react-redux";
import { getAllConsumerList } from "../../redux/actions/consumer-center.action";
import InputField from "../../components/formControl/input/InputField";


const ListCustomer = (props) => {
    AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
    const [customerData, setCustomerData] = useState([]);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState('');
    const filterData = {
        limit: 9999,
        offset: 0,
    };

    const apiData = (filter) => {
        startLoader();
        const response = dispatch(getAllConsumerList(filter));
        response.then(async (data) => {
            if (data && data.data && data.data.status === 200) {
                let result = []
                if(data && data.data && data.data.data){
                    await data.data.data.map((item)=>{
                        result.push({
                            ...item,
                            name:item.consumerName || item.email
                        })
                    })
                }
                setCustomerData(result);
            }
        })
        .catch((error) => console.error(error));
        stopLoader();
    };
    useEffect(() => {
        apiData(filterData)
    }, []);

    const chat = (data) => {
        props.onSubmit(data)
        props.onClose()
    }

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = customerData.filter((item) =>
        item.name && item.name.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <React.Fragment>
            <div className="align-items-center justify-content-between px-3">
                <div className="py-2">
                    <InputField
                        className=""
                        formClass="col-12"
                        type="text"
                        id="search"
                        placeholder="Search"
                        name="search"
                        value={filter}
                        onChange={handleFilterChange}
                    />
                </div>
                <div className="border card rounded-lg position-relative" style={{ maxHeight: "60vh", overflow: "auto" }}>
                    <table className="table border-0">
                        <thead className="position-sticky">
                            <th>Name</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {filteredData && filteredData.length > 0 && filteredData.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>
                                            <button className="btn btn-sm btn-primary" onClick={(e)=>chat(item)}>Chat</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ListCustomer