// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./variables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./analytics.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover {
  object-fit: cover; }

.contain {
  object-fit: contain; }

.fill {
  object-fit: fill; }

.MuiListItemIcon-root {
  min-width: 30px !important;
  align-items: center !important; }

.w-49 {
  width: 49%; }

.fit-content {
  height: -moz-fit-content;
  height: fit-content; }

.sun-editor-editable {
  max-height: calc(100vh - 240px) !important;
  min-height: 50vh !important; }

.border-dashed {
  border: 1px dashed #dee2e6 !important; }

.fit-content {
  width: -moz-fit-content;
  width: fit-content; }

.error {
  /* position: absolute; */
  color: red;
  font-size: 0.6rem; }

/* label.error:before,
.gen-error:before {
    content: "\\e915";
    font-family: "icomoon" !important;
    position: absolute;
    left: 0;
    top: 0;
} */
.card_bg {
  background: white;
  margin-bottom: 20px;
  padding: 10px; }
`, "",{"version":3,"sources":["webpack://./src/static/css/home.css"],"names":[],"mappings":"AAGA;EACE,iBAAiB,EAAA;;AAEnB;EACE,mBAAmB,EAAA;;AAErB;EACE,gBAAgB,EAAA;;AAGlB;EACE,0BAA0B;EAC1B,8BAA8B,EAAA;;AAGhC;EACE,UAAU,EAAA;;AAEZ;EACE,wBAAmB;EAAnB,mBAAmB,EAAA;;AAErB;EACE,0CAA0C;EAC1C,2BAA2B,EAAA;;AAG7B;EACE,qCAAoC,EAAA;;AAEtC;EACE,uBAAkB;EAAlB,kBAAkB,EAAA;;AAEpB;EACE,wBAAA;EACA,UAAU;EACV,iBAAiB,EAAA;;AAEnB;;;;;;;GAGG;AAKH;EACE,iBAAiB;EACjB,mBAAmB;EACnB,aAAc,EAAA","sourcesContent":["@import \"./variables.css\";\n@import \"./analytics.css\";\n\n.cover {\n  object-fit: cover;\n}\n.contain {\n  object-fit: contain;\n}\n.fill {\n  object-fit: fill;\n}\n\n.MuiListItemIcon-root {\n  min-width: 30px !important;\n  align-items: center !important;\n}\n\n.w-49 {\n  width: 49%;\n}\n.fit-content{\n  height: fit-content;\n}\n.sun-editor-editable {\n  max-height: calc(100vh - 240px) !important;\n  min-height: 50vh !important;\n}\n\n.border-dashed{\n  border: 1px dashed #dee2e6!important;\n}\n.fit-content{\n  width: fit-content;\n}\n.error{\n  /* position: absolute; */\n  color: red;\n  font-size: 0.6rem;\n}\n/* label.error:before,\n.gen-error:before {\n    content: \"\\e915\";\n    font-family: \"icomoon\" !important;\n    position: absolute;\n    left: 0;\n    top: 0;\n} */\n.card_bg{\n  background: white;\n  margin-bottom: 20px;\n  padding: 10px ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
