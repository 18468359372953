import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../../components/Images/Image";
import { breadcrumbAction } from "../../../../redux/actions/config.action";
import { IconButton, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useState } from "react";
import { getRecieptOrderByTeamId } from "../../../../redux/actions/finance.action";

const initial = [
  {
    buyerName: "tangisa morina",
    orderId: "#01289013",
  },
  {
    buyerName: "tangisa morina",
    orderId: "#01289013",
  },
];

const RetailTpInfo = () => {
  const [allMemberListingData, setAllMemberListingData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams()
  console.log(id, "iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii");
  const viewAction = (id) => {
    history.push(`/finance/order-receipt/view-order/${id}`)
  }
  useEffect(() => {
    const breadcrumb = [
      { label: "Order & Receipt", url: "/finance/order-receipt" },
      { label: `Group ID  ${id || ""}`, url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));
    if (id) {
      dispatch(getRecieptOrderByTeamId(id))
        .then((data) => {
          console.log(data?.data, "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");
          if (data && data.data && data.data.data) {
            console.log(data?.data?.data, "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");
            setAllMemberListingData(data?.data?.data)
          }
          // setFullData()
          console.log(data?.data?.data, "lllllllllllllllllllllllllllllllllllllllll");
        }).catch((error) => {
          console.log(error);
        })
    }
  }, [id])
  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-content-between container_body vh-100">
        <div className="d-flex  position-relative  my-4">
          <div className="col-12">

            <div className="card my-3 p-3">
              <div className="mb-2">RETAIL TP INFORMATION</div>
              <div className="d-flex">
                <label htmlFor="" className="col-3 text-right">Merchant Name</label>
                <div>{allMemberListingData?.product?.merchant?.merchantName || "N/A"}</div>
              </div>
              <div className="d-flex">
                <label htmlFor="" className="col-3 text-right">Merchant ID</label>
                <div> {allMemberListingData?.product?.merchant?.storeId || "N/A"}</div>
              </div>
              <div className="d-flex">
                <label htmlFor="" className="col-3 text-right">TP Status</label>
                <div> {allMemberListingData?.requireStatus|| "N/A"}</div>
              </div>
              <div className="d-flex">
                <label htmlFor="" className="col-3 text-right">Member Fullfilled</label>
                <div>{`${allMemberListingData?.totalMembersRequired}/5` || "N/A"}</div>
              </div>
            </div>

            <div className="card my-3 p-3">
              <div className="mb-2">MEMBER LISTING</div>
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="th_cell border-0" component="th">
                        No
                      </TableCell>
                      <TableCell
                        className="th_cell border-0 "
                        component="th"
                        align="center"
                      >
                        {/* Merchant ID */}
                        <div className="d-flex  ">
                          <div>Buyer</div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell border-0"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex ">
                          <div>Order ID</div>
                        </div>
                      </TableCell>

                      <TableCell
                        className="th_cell border-0"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex ">
                          <div>QTY</div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell border-0"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex ">
                          <div>Unit Price</div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell border-0"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ml-2">
                          <div>Total Price</div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell border-0"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex ">
                          <div>Buy Now Price</div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell border-0"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex ">
                          <div>Unit Capped</div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allMemberListingData?.activeMembers == 0 && (
                      <h3 className="text-muted m-auto">No data found!</h3>
                    )}
                    {allMemberListingData?.activeMembers &&
                      allMemberListingData?.activeMembers.length > 0 &&
                      allMemberListingData?.activeMembers.map((row, index) => {
                        console.log(row);
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              align="center"
                              className="td_cell border-0"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td_cell border-0"
                            >
                              {row?.consumerName || "N/A"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td_cell border-0"
                            >
                              <u
                                onClick={() =>
                                  viewAction(row?.orderId)
                                }
                                role="button"
                              >
                                {'#01289013' || "N/A"}
                              </u>
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td_cell border-0"
                            >
                              {String(row?.quantity) || "N/A"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td_cell border-0"
                            >
                              {row?.price || "N/A"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td_cell border-0"
                            >
                              {row?.quantity * row?.price || "N/A"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td_cell border-0"
                            >
                              {row?.quantity * row?.price || "N/A"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="td_cell border-0"
                            >
                              {row?.quantity * row?.price || "N/A"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {/* {merchatData && merchatData.length > 0 && merchatData.map((row, index) => (
                  
                    ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

        </div>
        <div className="position-stick b-0 card">
          <footer className="bg-white p-3 card">
            <div className="d-flex justify-content-end ">
              <button
                onClick={history.goBack}
                type="button"
                className="btn btn-outline-danger mx-2"
              // onClick={back}
              >
                Back
              </button>
            </div>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RetailTpInfo;
