// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --txt-color: #000;
  --app-color: #fc024d;
  --app-color-shadow: #fc024d85;
  --dark: #1d2332f0;
  --light: #fff; }
`, "",{"version":3,"sources":["webpack://./src/static/css/variables.css"],"names":[],"mappings":"AAAA;EACI,iBAAY;EACZ,oBAAY;EACZ,6BAAmB;EAEnB,iBAAO;EACP,aAAQ,EAAA","sourcesContent":[":root {\n    --txt-color: #000;\n    --app-color: #fc024d;\n    --app-color-shadow: #fc024d85;\n\n    --dark: #1d2332f0;\n    --light: #fff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
