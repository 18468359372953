import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputField from "../../../components/formControl/input/InputField";
import SelectInput from "../../../components/select";
import { showToast, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {searchMerchantList} from "../../../redux/actions/merchant-center.action";
import {
  addOutletApi,
  getOutletById,
  updateOutletById,
} from "../../../services/outlet.service";

const AddOutlet = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [outletName, setOutletName] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [postalCode, setPostalCode] = useState();
  const [state, setState] = useState();
  const [merchantList, setMerchantList] = useState([]);
  const [basicInformation, setBasicInformation] = useState({merchant: null});
  const [storeId, setStoreId] = useState("");
  const [merchantData, setMerchantData] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      startLoader();
      dispatch(getOutletById(id))
        .then((data) => {
          stopLoader();
          const { outletName, addressLine1, addressLine2, postalCode, state, merchant } = data.data.data;
          setOutletName(outletName);
          setAddressLine1(addressLine1);
          setAddressLine2(addressLine2);
          setPostalCode(postalCode);
          setState(state);
          setStoreId(merchant.storeId);
          setMerchantData(merchant.preferredMerchantName + ' - '+ merchant.storeId);
          setIsEdit(true);
        })
        .catch((error) => {
          stopLoader;
          console.log({ error }, "error");
        });
    }

    
  }, [id]);

  useEffect(() => {
    const breadcrumb = [
      { label: "Merchant Outlet", url: "/merchant-center/outlet" },
      { label: id ? "Edit Outlet" : "Add Outlet", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    getMerchantList();
  }, []);

  const addOutletFun = () => {
    startLoader();
    const data = {
      storeId,
      outletName,
      addressLine1, 
      addressLine2,
      postalCode, 
      state
    };
    if (id) {
      dispatch(updateOutletById(id, data))
        .then((data) => {
          if (data && data.data && data.data.status === 201) {
            setTimeout(() => {
              showToast(data.data.message);
              stopLoader();
              history.push("/merchant-center/outlet");
            }, 2000);
          } else {
            showToast(data.data.message);
            stopLoader();
            history.push("/merchant-center/outlet");
          }
        })
        .catch((error) => {
          console.log(error);
          stopLoader();
        });
    } else {
      const response = dispatch(addOutletApi(data));
      response
        .then((data) => {
          if (data && data.data && data.data.status === 200) {
            setTimeout(() => {
              showToast(data.data.message);
              stopLoader();
              history.push("/merchant-center/outlet");
            }, 2000);
          } else {
            stopLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          stopLoader();
        });
    }
  };

  const getMerchantList = (search) => {
    dispatch(searchMerchantList(search))
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data.map((item) => ({
            title: item.merchantName,
            preferredName: item.preferredMerchantName,
            label: `${item.preferredMerchantName} - (${item.storeId})`,
            value: item.storeId,
          }));

          setMerchantList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
};

const onChangeBasicInfo = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setStoreId(val.value)
    setBasicInformation((pre) => ({ ...pre, [key]: value }));
};

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-between flex-column h-100">
        <div className="mr-3 ml-4 mt-3 rounded  px-3 pt-4 pb-2 bg-white">
          <small>BASIC INFORMATION</small>
          <form className="mt-3  p-0">
          <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Merchant Name
              </label>
              { isEdit == false && (
              <div className="form-group col-9">
                <SelectInput
                  options={merchantList}
                  name="merchant"
                  value={basicInformation.merchant}
                  onChange={(e) => onChangeBasicInfo(e, "merchant", e)}
                  required={true}
                  formSubmitted={false}
                  placeholder="Select Merchant"
                />
              </div>
              )}

            { isEdit == true && (
              <InputField
                className=""
                formClass="col-9"
                type="text"
                value={merchantData}
                disabled
              />
            )}
            </div>

            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Outlet Name
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                placeholder="Enter Outlet name"
                value={outletName}
                onChange={(e) => setOutletName(e.target.value)}
                required={true}
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Address Line 1
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                placeholder="Enter Address Line 1"
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small">
                Address Line 2
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
                placeholder="Enter Address Line 2"
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Postal Code
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder="Enter Postal Code"
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                State
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="Enter State"
              />
            </div>
          </form>
        </div>
        <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
          <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
            <button
              onClick={history.goBack}
              type="button"
              className="btn border input-inside-text mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-dark btn-sm mr-2"
              onClick={() => addOutletFun()}
              disabled={
                !outletName || 
                !addressLine1 ||
                !addressLine2 ||
                !postalCode ||
                !state
              }
            >
              {id ? "Save" : "Create"}
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default AddOutlet;
