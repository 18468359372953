
import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// import rating_stars from "../../../language"

import SelectInput from '../../components/select';
import { breadcrumbAction } from '../../redux/actions/config.action';
import { TabPanel } from "../../components/Tabs/TabPannel";
import ShopRatingDetail from './ShopRattingDetail';

import { getMerchantShopRating } from '../../redux/actions/merchant-center.action';
import ablity from "../../helpers/abilityGuard";
import { getLocalStorage } from '../../lib/session';
import { AllowAccess } from '../../lib/global';

const initialSearchData = {
    productName: "",
    variationName: "",
    userName: "",
    reviewName:"",
    ratingStars:""
}

const  rating_stars=[
   {label:"0",value:"0"},
   {label:"1",value:"1"},
   {label:"2",value:"2"},
   {label:"3",value:"3"},
   {label:"4",value:"4"},
   {label:"5",value:"5"},

]


const ShopRatting = (props) => {
    AllowAccess(["MERCHANT"]);
    const dispatch = useDispatch()
    const storeId = getLocalStorage("storeId")
    const { mid, merchantName } = useParams()
    const [searchData, setSearchData] = useState(initialSearchData)
    const [shopRating, setShopRating] = useState()

    const [activeTab, setActiveTab] = useState("basicSetting");

    const [search,setSearch]=useState('')

    useEffect(() => {
        const breadcrumb = [
            { label: "Merchant Profile", url: "/merchant-center/profiles" },
            { label: merchantName, url: null }
        ];
        dispatch(breadcrumbAction(breadcrumb));
    }, [])

    const onSetRatting = (ratting) =>{
        setShopRating(ratting)
    }

    return (
        <Fragment>
            <div>
                <hr />
                <div>
                    <div className="px-1">
                        <div className='float-right px-2'>
                            <div className="text-secondary text-nowrap"><span className="bold fntSz28 px-2 text-dark">{shopRating?.avg}</span>/5</div>
                        </div>
                        <h4 className="bold m-0">Shop Rating</h4>
                        <p className="text-muted small">View merchant&apos;s shop rating</p>

                    </div>
                    <TabPanel
                        style={{ display: activeTab == "basicSetting" ? "block" : "none" }}
                        value={0} index={0}
                        costumStyle={{ padding: "0px" }}
                    >
                        <ShopRatingDetail storeId={storeId} setRatting={onSetRatting} reply_status="ALL" />
                    </TabPanel>
                </div>
            </div>



            <style jsx="true" >{`
                .shopRating_inputDiv{
                    margin: -26px; 
                }
                .min-width-180{
                    min-width: 180px !important;
                }
            `}

            </style>
        </Fragment>
    )
}

export default ShopRatting;