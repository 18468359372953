import React, { useState } from "react";
import "../../../../../static/css/deleteModal.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteMerchant,
  getAllMerchantListAfterDelete,
} from "../../../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import { showToast, startLoader, stopLoader } from "../../../../../lib/global";
import Image from "../../../../../components/Images/Image";
import InputField from "../../../../../components/formControl/input/InputField";

const initialData = [
  {
    id:"498fa7cc-f00a-4449-91a0-87b27042c010",
    name:"Nike Shoe V11",
    price:"RM50.00-RM90.00",
    status:0,
  },
  {
    id:"498fa7cc-f00a-4449-91a0-87b27042c011",
    name:"Nike Shoe V12",
    price:"RM50.00-RM90.00",
    status:0,
  },
  {
    id:"498fa7cc-f00a-4449-91a0-87b27042c012",
    name:"Nike Shoe V13",
    price:"RM50.00-RM90.00",
    status:0,
  },
  {
    id:"498fa7cc-f00a-4449-91a0-87b27042c013",
    name:"Nike Shoe V14",
    price:"RM50.00-RM90.00",
    status:0,
  },
  {
    id:"498fa7cc-f00a-4449-91a0-87b27042c014",
    name:"Nike Shoe V15",
    price:"RM50.00-RM90.00",
    status:0,
  },
  {
    id:"498fa7cc-f00a-4449-91a0-87b27042c015",
    name:"Nike Shoe V12",
    price:"RM50.00-RM90.00",
    status:0,
  }
];

const GoLiveShowProductDialog = (props) => {
  const [allProductData, setallProductData] = useState(initialData);

  const dispatch = useDispatch();
  const STATUS_SHOW = 1;
  const STATUS_REMOVE = 0;
  const deleteFun = () => {
    const data = {
      status: "DELETED",
    };
    startLoader();
    const response = dispatch(deleteMerchant(data, props.id));
    response.then((data) => {
      if (data.data.status == 200) {
        const response = dispatch(getAllMerchantListAfterDelete());
        response
          .then((data) => {
            if (data && data.data && data.data.status === 200) {
              setTimeout(() => {
                stopLoader();
                showToast(data.data.message, "success");
                props.onSubmit([...data.data.data]);
                props.onClose();
              }, 1000);
            } else {
              stopLoader();
              showToast("Internal Server Error", "error");
              props.onClose();
            }
          })
          .catch((error) => {
            console.log({ error });
          });
      } else {
        stopLoader();
        showToast("Internal Server Error", "error");
      }
    });
  };
  function statusButton(status,id) {
    if (status == 1) {
      return <button className='btn btn-link' onClick={()=>changeStatus(STATUS_REMOVE,id)}>Show</button>;
    } else {
      return <button className='btn btn-link' onClick={()=>changeStatus(STATUS_SHOW,id)}>Remove</button>;
    }
  }
  function changeStatus(status,id){
    console.log(status)
    let data = allProductData;
    let index = data.findIndex((item)=>item.id == id);
    data[index].status = status;
    console.log(data[index])
    setallProductData(data);
  }

  return (
    <React.Fragment>
      <div>
        <div className="d-flex flex-row align-items-center justify-content-between px-3">
          <p className="mb-0">Show Product</p>
          <button
            onClick={props.onClose}
            className="btn fntSz24 text-secondary"
          >
            x
          </button>
        </div>
        <div className="border-top border-bottom py-1">
          <div className="d-flex flex-row justify-content-between m-2">
            <div className="row ml-2">
              <button className="btn btn-danger py-1 mx-1 btn-sm">
                Add Releated Product
              </button>
              <InputField
                className=""
                formClass="col-6"
                type="text"
                id="filter"
                placeholder="Filter"
                name="filter"
                // value={basicInformation.title}
                // onChange={onChangeBasicInfo}
              />
            </div>
          </div>
          <div
            style={{ maxHeight: "35vh", overflow: "auto" }}
            className="fntSz14 position-relative px-2"
          >
            <table className="table border-0">
              <thead className="position-sticky" style={{ top: "0" }}>
                <tr className="bg-light rounded-lg text-secondary">
                  <th className="border-0">
                    Product
                  </th>
                  <th className="border-0">
                    Price
                  </th>
                  <th className="border-0">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {allProductData &&
                  allProductData.length > 0 &&
                  allProductData.map((row, index) => (
                    <tr
                      key={row.id}
                    >
                      <td className=" align-middle border-0">
                        <div className="d-flex flex-row align-items-center">
                          <Image
                            src="/images/shoes.jpeg"
                            alt={"photo"}
                            className="flash_sale_product_image mr-1"
                          />
                          <div>{row.name}</div>
                        </div>
                      </td>
                      <td className="align-middle border-0 float-right">
                        {row.price}
                      </td>
                      <td>
                        {statusButton(row.status,row.id)}
                        <button className="btn btn-link py-1 mx-1 btn-sm">Delete</button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <style jsx="true">{`
          .card-body {
            min-height: 50vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
          .flash_sale_product_image {
            width: 45px;
            height: 40px;
            border-radius: 3px;
          }
          input {
            cursor: pointer;
          }
        `}</style>
      </div>
    </React.Fragment>
  );
};

export default GoLiveShowProductDialog;
