import { IconButton, MenuItem, Select } from "@mui/material";
import Divider from "@mui/material/Divider";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../components/Images/Image";
import InputField from "../../../components/formControl/input/InputField";
import RadioButtonsGroup from "../../../components/radio-button/RadioButtonsGroup";
import SelectInput from "../../../components/select";
import {
  brandList,
  searchBrandsList,
  searchCategoryList,
} from "../../../redux/actions/merchant-center.action";
import "../../../static/css/productCss/allProduct.css";
import { countriesList } from "../../../lib/countriesList";
import {
  AllowAccess,
  open_dialog,
  parseParam,
  parseQuery,
  showToast,
  startLoader,
  stopLoader,
  UploadImage,
  UploadVideo,
} from "../../../lib/global";
import {
  addNewProductByStore,
  createNewProduct,
  getProductById,
  updateProduct,
} from "../../../redux/actions/product.action";
import SvgIcon from "../../../components/Images/SvgIcon";
import {
  CANCEL_EDIT_MODAL,
  CATEGORY_SELECTION_DIALOG,
} from "../../../lib/constant";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { MAX_IMAGE_SIZE } from "../../../lib/config";
import {
  getAllCategoryList,
  getAllSubCategoriesList,
  getCategoryDetailsById,
} from "../../../redux/actions/category.action";
import Tree from "../../../components/categoriesTree/Tree";
import ImageModal from "../../../components/carousel/modal";
import { IOSSwitch } from "../../../components/switch/IOSSwitch";
import { getByStoreId } from "../../../services/outlet.service";

const productTypes = [
  { label: "Physical", value: "physical" },
  { label: "Non-Physical", value: "non-physical" },
];

const MerchantAddProduct = () => {
  AllowAccess(["MERCHANT"]);
  // console.log("marchan prndsjfqwafijbj");
  const param = useParams();
  const merchantId = param.merchantId
    ? param.merchantId
    : localStorage.getItem("storeId");
  const merchantName = param.merchantName
    ? param.merchantName
    : localStorage.getItem("userName");
  // console.log(merchantId, merchantName);
  const history = useHistory();
  const dispatch = useDispatch();
  const [editProduct, setEditProduct] = useState(false);
  const [productId, setProductId] = useState();
  const [errors, setErrors] = useState({});
  const [steps, setSteps] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [videoFile, setVideoFile] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [variationInfo, setVariationInfo] = useState({});
  const [editStatusText, setEditStatusText] = useState("NEW");

  const [showCategory, setShowCategory] = useState([]);
  const [idCategory, setIdCategory] = useState([]);
  const [openCategory, setOpenCategory] = useState(false);
  const [openSubCategory, setSubOpenCategory] = useState(false);
  const [oneCategory, setOneCategory] = useState({});

  const [dataCategory, setDataCategory] = useState([]);

  const [hide, setHide] = useState(true);
  const [hideVideo, setHideVideo] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [basicInformation, setBasicInformation] = useState({
    title: "",
    category: null,
    categoryLink: idCategory,
    images: [],
    video: "",
    description: "",
    type: "",
    preOrder: false,
    allowChangeRedemptionLocation: null
  });
  const [salesInformation, setSalesInformation] = useState({
    variation: false,
    price: null,
    stock: null,
  });
  const [variations, setVariations] = useState([]);
  const [teamPurchaseInfo, setTeamPurchaseInfo] = useState({
    price: null,
    member: 0,
    unitCapped: 0,
    stock: null,
  });
  const [specification, setSpecification] = useState({
    brand: "",
    shipFrom: "", // country
    warrantyPeriod: "", // in months
    expiryDate: "",
  });
  const [shippingInfo, setShippingInfo] = useState({
    weight: null,
    parcelWidth: null,
    parcelHeight: null,
    parcelLength: null,
    shippingFee: null,
    coverShippingFee: false,
  });
  // console.log(basicInformation, "this is basiccccccccccccccccccccccc");

  const [variationData, setVariationData] = useState([
    // {
    //   color: "Red",
    //   size: "S",
    //   price: null,
    //   stock: null,
    //   unitCapped: null,
    //   sku: ""
    // }
  ]);

  // console.log(parseParam(history.location.pathname), "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
  // console.log(
  //   param,
  //   "param   param  param  param kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk"
  // );

  useEffect(() => {
    const paramList = parseParam(history.location.pathname);
    onChangeSalesInfo(null, "variation", true);
    // console.log(paramList);
    var breadcrumb = [];
    if (param.merchantId) {
      breadcrumb = [
        { label: "Merchant Profile", url: "/merchant-center/profiles" },
        {
          label: param.merchantName,
          url: `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`,
        },
        { label: param.id ? "Edit Product" : "Add Product", url: null },
      ];
    } else {
      breadcrumb = [
        {
          label: "Products",
          url: param.mid
            ? `/merchant-center/profiles/${param.mid}/${param.merchantName}`
            : "/merchant/products",
        },
        {
          label:
            paramList && paramList[1] === "edit-product"
              ? "Edit Product"
              : "Add Product",
          url: null,
        },
      ];
    }

    dispatch(breadcrumbAction(breadcrumb));
    dispatch(searchCategoryList()).then(({ data }) => {
      setDataCategory(data.data);
    });

    if (paramList && paramList[1] === "edit-product") {
      setEditProduct(true);
      const { id } = { id: paramList[2] } || {};
      // console.log(id);
      setProductId(id);
      getProductDetail(id);
    }
    getCategiesList();
    getBrandsList();
  }, []);

  const getProductDetail = (productId) => {
    dispatch(getProductById(productId))
      .then((response) => {
        // console.log(response, "jjjjjjjjjjjjjjjjjjjj");
        if (response?.data?.data) {
          const result = response.data.data;
          // console.log(result);
          setBasicInformation({
            title: result.title,
            merchant: {
              label: result.merchantName,
              value: result.storeId,
            },
            category: {
              id: result.categoryId,
              title: result.categoryName,
            },
            categoryLink: [...idCategory],

            images: result.productImages || [],
            video: result.productVideo,
            description: result.description,
            type: result.productType,
            preOrder: !!result.preOrder,
          });
          setEditStatusText(result.statusText);
          setImageUrls(result.productImages);
          setImageFiles(result.productImages);
          setVideoUrl(result.productVideo);
          setVariations(result.variation);
          setVariationData(result.variationList);
          setSalesInformation({
            variation: result.variation?.length,
            price: result.price,
            stock: result.stock,
          });
          setSpecification({
            brand: {
              label: result.brand,
              value: result.brandId == "undefined" ? "" : result.brandId,
            },
            shipFrom: {
              label: result.shipFrom,
              value: result.shipFrom,
            }, // country
            warrantyPeriod: result.warranty,
            expiryDate: result.expiryDate,
          });
          setTeamPurchaseInfo({
            price: result.teamPurchasePrice,
            member: result.teamPurchaseMember,
            unitCapped: result.teamUnitCapped,
            stock: result.teamPurchaseStock,
          });
          setShippingInfo({
            weight: result.shippingInfo?.weight,
            parcelWidth: result.shippingInfo?.parcelSize?.width,
            parcelHeight: result.shippingInfo?.parcelSize?.height,
            parcelLength: result.shippingInfo?.parcelSize?.length,
            shippingFee: result.shippingInfo?.shippingFee,
            coverShippingFee: !!result.shippingInfo?.coverShippingFee,
          });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  const getCategiesList = (search) => {
    dispatch(searchCategoryList(search))
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data.map((item) => ({
            label: item.title,
            value: item.id,
          }));
          setCategoryList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  const getBrandsList = (search) => {
    dispatch(brandList(search))
      .then((response) => {
        // console.log(response, "tyuiophvgjh");
        if (response?.data?.data) {
          //  console.log();
          const data = response.data.data.map((item) => ({
            label: item.brandName,
            value: item.brandId,
          }));
          setBrandsList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const onChangeBasicInfo = (e, opt, val) => {
    // console.log("val", val);
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setBasicInformation((pre) => ({ ...pre, [key]: value }));
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  const onChangeSalesInfo = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setSalesInformation((pre) => ({ ...pre, [key]: value }));
  };
  const onChangeTeamPurchaseInfo = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setTeamPurchaseInfo((pre) => ({ ...pre, [key]: value }));
  };
  const onChangeSpecification = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setSpecification((pre) => ({ ...pre, [key]: value }));
  };
  const onChangeShippingInfo = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setShippingInfo((pre) => ({ ...pre, [key]: value }));
  };

  const handleUploadFile = async (e, index) => {
    const fileData = e.target.files[0];
    // console.log("fileData", fileData);
    if (fileData.size > MAX_IMAGE_SIZE) {
      showToast(
        `Image with size more than ${
          MAX_IMAGE_SIZE / 1000000
        }MB is not supported.\nPlease upload image of size smaller than ${
          MAX_IMAGE_SIZE / 1000000
        }MB.\nCurrent file size: ${(fileData.size / 1000000).toFixed(2)}MB`,
        "error",
        3000,
        "bottom",
        "center"
      );
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      const fileIns = [...imageUrls];
      const imageUrlIns = [...imageUrls];
      if (fileIns[index]) {
        fileIns[index] = fileData;
        imageUrlIns[index] = reader.result;
      } else {
        fileIns.push(fileData);
        imageUrlIns.push(reader.result);
      }
      setImageFiles(fileIns);
      setImageUrls(imageUrlIns);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleUploadVideoFile = async (e) => {
    const fileData = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      const fileIns = fileData;
      const videoUrlIns = reader.result;
      if (videoUrl) {
        setVideoFile();
        setVideoUrl();
      }
      setVideoFile(fileIns);
      setVideoUrl(videoUrlIns);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const validateDraft = () => {
    const errorsList = {
      title: "",
    };
    if (!basicInformation.title) {
      errorsList.title = "Product Name";
    }
    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };

  const validateBasicInfo = () => {
    const errorsList = {
      title: "",
      category: "",
      images: "",
      description: "",
      type: "",
    };
    if (!basicInformation.title) {
      errorsList.title = "Product Name";
    }
    // if (!basicInformation.category) {
    //   errorsList.category = "Category";
    // }
    if (idCategory.length == 0) {
      errorsList.category = "Category";
    }
    if (!imageFiles?.length) {
      errorsList.images = "Images";
    }
    if (!basicInformation.description) {
      errorsList.description = "Product Description";
    }
    if (!basicInformation.type) {
      errorsList.type = "Product Type";
    }
    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };
  const validateSalesInfo = () => {
    const errorsList = {
      price: "",
      stock: "",
      teamPurchasePrice: "",
      teamPurchaseStock: "",
      unitCapped: "",
      teamPurchaseMember: "",
    };
    if (!salesInformation.price) {
      errorsList.price = "Product Price";
    }
    if (!salesInformation.stock) {
      errorsList.stock = "Product Stock";
    }
    if (!teamPurchaseInfo.price) {
      errorsList.teamPurchasePrice = "Team Price";
    }
    if (!teamPurchaseInfo.member) {
      // console.log("teamPurchaseInfo.member", teamPurchaseInfo.member);
      errorsList.teamPurchaseMember = "Team Member";
    }
    if (!teamPurchaseInfo.stock) {
      errorsList.teamPurchaseStock = "Team Product Stock";
    }
    if (!teamPurchaseInfo.unitCapped) {
      errorsList.unitCapped = "Unit Capped";
    }
    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };
  const validateVariationInfo = () => {
    const errorsList = {
      price: "",
      teamPrice: "",
      teamMembers: "",
      variation: "",
      variationData: "",
      unitCapped: "",
      teamUnitCapped: "",
    };
    variationData.map((item) => {
      if (!item.price && item.price == 0) {
        errorsList.price = "Individual Price";
      }
      if (!item.teamPrice && item.teamPrice == 0) {
        errorsList.teamPrice = "Team Price";
      }

      if (!item.teamMembers || item.teamMembers < 2) {
        errorsList.teamMembers = "Team Members";
      }
      if (item.unitCapped === "") {
        errorsList.unitCapped = "Unit Capped";
      }
      if (item.teamUnitCapped === "") {
        errorsList.teamUnitCapped = "Team Unit Capped";
      }
    });
    if (!variations[0].name || !variations[0].options?.length) {
      errorsList.variation = "Variation";
    }
    if (!variationData.length) {
      errorsList.stock = "Variation List";
    }

    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };
  const validateShippingInfo = () => {
    // console.log(specification, "mmmmmmmmmmmmmm");
    const errorsList = {
      shipFrom: "",
      warrantyPeriod: "",
      weight: "",
      parcelSize: "",
      shippingFee: "",
      brand: "",
    };
    if (!specification.shipFrom) {
      errorsList.shipFrom = "Ship from";
    }
    // if (!specification.brand) {
    //   errorsList.brand = "Brand Name";
    // }
    if (basicInformation.type == "non-physical") {
      if (!specification.expiryDate) {
        errorsList.expiryDate = "Expiry Date";
      }
    }
    if (basicInformation.type == "physical") {
      if (!specification.warrantyPeriod) {
        errorsList.warrantyPeriod = "Warranty";
      }
      if (!shippingInfo.weight) {
        errorsList.weight = "Product Weight";
      }
      if (
        !shippingInfo.parcelHeight ||
        !shippingInfo.parcelWidth ||
        !shippingInfo.parcelLength
      ) {
        errorsList.parcelSize = "Parcel Size";
      }
    }

    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };
  const onSaveAndDeactivate = () => {
    handleClickNext("DEACTIVATED");
  };

  const handleAdditionalValidation = () => {
    let isValid = true;
    let message = "";

    variationData?.map(async (item) => {
      if (item.teamMembers < 2) {
        isValid = false;
        message = "Team Member must be more than 1";
        return;
      }

      if (item.teamPrice == 0) {
        isValid = false;
        message = "Team Price must be more than 0";
        return;
      }

      if (item.price == 0) {
        isValid = false;
        message = "Individual Price must be more than 0";
        return;
      }
    });

    variations?.map(async (item) => {
      if (item.options.length > 0) {
        item.options.map(async (option) => {
          if (option == "") {
            isValid = false;
            message = `options ${item.name} must be provided`;
            return;
          }
        });
      }
    });

    return { isValid, message };
  };

  const handleAdditionalShippingInfoValidation = () => {
    let isValid = true;
    let message = "";

    if (
      basicInformation.type == "physical" &&
      Number(shippingInfo.weight) <= 0
    ) {
      isValid = false;
      message = "Product weight must be filled in more than 0 kg";
    }

    return { isValid, message };
  };

  const handleClickNext = async (status) => {
    setErrors({});
    try {
      startLoader();
      if (status == "DRAFT") {
        let error = validateDraft();
        if (Object.values(error).filter((item) => item).length) {
          stopLoader();
          return;
        }
      } else {
        if (steps == 1) {
          let error = validateBasicInfo();
          // console.log("error", error);
          if (Object.values(error).filter((item) => item).length) {
            stopLoader();
            return;
          } else {
            stopLoader();
            return setSteps(2);
          }
        }
        if (steps == 2) {
          let { isValid, message } = handleAdditionalValidation();

          if (!isValid) {
            showToast(message, "error", 3000, "bottom", "center");
            stopLoader();
            return;
          }

          let error;
          if (salesInformation.variation) {
            error = validateVariationInfo();
          } else {
            if (
              Object.values(validateVariationInfo()).filter((item) => item)
                .length == 0
            ) {
              error = validateVariationInfo();
            } else {
              error = validateSalesInfo();
            }
          }

          if (Object.values(error).filter((item) => item).length == 0) {
            stopLoader();
            return setSteps(3);
          } else {
            if (
              Object.values(validateVariationInfo()).filter((item) => item)
                .length == 0
            ) {
              stopLoader();
              return setSteps(3);
            } else {
              stopLoader();
              return;
            }
          }

          // if (Object.values(error).filter((item) => item).length) {
          //   console.log( "RIPTO G");
          //   stopLoader();
          //   return;
          // } else {
          //   // console.log(basicInformation.type, "dfghjk");
          //   // if (basicInformation.type == "non-physical") {
          //   //   console.log('yes');
          //   // } else {
          //   stopLoader();
          //   return setSteps(3);
          //   // }
          // }
        }
        if (steps == 3) {
          // console.log("333333333333333");
          let error = validateShippingInfo();
          if (Object.values(error).filter((item) => item).length) {
            stopLoader();
            return;
          }
          let { isValid, message } = handleAdditionalShippingInfoValidation();
          if (!isValid) {
            showToast(message, "error", 3000, "bottom", "center");
            stopLoader();
            return;
          }
        }
      }
      // console.log(steps, "hhhhhhhhhhhhhhhhh");
      // console.log(
      //   { storeId: param.merchantId },
      //   { merchantName: param.merchantName },
      //   "lllllllllllllll"
      // );
      const payload = {
        title: basicInformation.title,
        storeId: param.merchantId ? param.merchantId : merchantId,
        merchantName: localStorage.getItem("userName"),
        allowChangeRedemptionLocation: basicInformation.allowChangeRedemptionLocation,
        // categoryId: String(basicInformation.category?.id),
        // categoryName: basicInformation.category?.title,
        categoryId: String(idCategory[0]),
        categoryName: String(showCategory[0]),
        categoryLink: idCategory,
        // productImages: basicInformation.images,
        productImages: [],
        productVideo: basicInformation.video,
        description: basicInformation.description,
        productType: basicInformation.type,
        variation: variations,
        variationList: variationData,
        // price: String(salesInformation.price),
        currencyName: "RM",
        statusText: status || "PUBLISHED",
        stock: Number(salesInformation.stock),
        teamPurchasePrice: Number(teamPurchaseInfo.price),
        teamPurchaseStock: Number(teamPurchaseInfo.stock),
        teamPurchaseMember: Number(teamPurchaseInfo.member),
        unitCapped: Number(teamPurchaseInfo.unitCapped),
        brand: specification.brand?.label || "",
        brandId: String(specification.brand?.value) || "",
        shipFrom: specification.shipFrom?.label || "",
        warranty: String(specification.warrantyPeriod) || "",
        expiryDate: String(specification.expiryDate) || "",

        shippingInfo: {
          weight:
            shippingInfo.weight == "0"
              ? "0"
              : Number(shippingInfo.weight) || "",
          parcelSize: {
            height: Number(shippingInfo.parcelHeight) || "",
            width: Number(shippingInfo.parcelWidth) || "",
            length: Number(shippingInfo.parcelLength) || "",
          },
          shippingFee: shippingInfo.shippingFee || "", // object
        },
        preOrder: basicInformation.preOrder,
      };

      // console.log(payload, "this payloaddddddddddddddddddd");
      // uploading product images and videos
      let images = [];
      for (let file of imageFiles) {
        if (!basicInformation.images.includes(file)) {
          // console.log(file);
          let url = await UploadImage(file);
          // console.log(url?.error?.message, 'jjjjjjjjjj');
          // if (url?.error) {
          //   showToast(url?.error?.message || "file size is too big", 'error')
          // }
          // else {
          images.push(url);
          // }
        } else {
          images.push(file);
        }
      }
      // console.log("ghjkl");
      payload.productImages = [...payload.productImages, ...images];

      if (videoFile) {
        let videoUrl = await UploadVideo(videoFile);
        payload.productVideo = videoUrl;
      } else {
        payload.productVideo = videoUrl;
      }

      if (editProduct) {
        dispatch(updateProduct(payload, productId))
          .then((response) => {
            stopLoader();
            // console.log("Product updated successfully");
            if (param.merchantId) {
              // console.log(param.merchantId, "lllllljjjjjjjjjjjllll");
              history.push(
                `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`
              );
            } else {
              history.push("/merchant/products");
            }
          })
          .catch((error) => {
            stopLoader();
            const message = JSON.parse(error.request.responseText).message;
            showToast("Fail Save Product [" + message + "]", "error");
            console.error("Failed to upload product!", error);
          });
      } else {
        // console.log(payload, "payloaddddddddddddddddddddddddddddd");
        dispatch(addNewProductByStore(payload))
          .then((response) => {
            stopLoader();
            // console.log(param.merchantId, "llllllllll");
            if (param.merchantId) {
              // console.log(param.merchantId, "lllllljjjjjjjjjjjllll");
              history.push(
                `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`
              );
            } else {
              history.push("/merchant/products");
            }
          })
          .catch((error) => {
            stopLoader();
            const message = JSON.parse(error.request.responseText).message;
            showToast("Fail Save Product [" + message + "]", "error");
            console.error("Failed to upload product!", error);
          });
      }

      // console.log(JSON.stringify(payload, null, "  "));
      // dispatch(addNewProductByStore(payload))
      //   .then((response) => {
      //     stopLoader();
      //     console.log("Product uploaded successfully");
      //     history.push("/merchant/products");
      //   })
      //   .catch((error) => {
      //     stopLoader();
      //     console.error("Failed to upload product!", error);
      //   });

      // console.log("now it's time to validate form....");
    } catch (error) {
      console.log(error);
      stopLoader();
    }
  };

  const toggleAllowChangeRedemptionLocation = () => {
    dispatch(getByStoreId(merchantId)).then((res) => {
      if(res.data.data.length > 0){
        setBasicInformation((pre) => ({
          ...pre,
          allowChangeRedemptionLocation: !pre.allowChangeRedemptionLocation,
        }))
      }else{
        showToast("Please add Merchant Outlet to allow this feature.", "error", 3000, "bottom", "center");
      }
    })
  }

  const handleAddVariationOption = (varitionIndex) => {
    const variationIns = [...variations];
    variationIns[varitionIndex].options.push("");
    setVariations(variationIns);
    handleGenerateVariationTable();
  };
  const handleDeleteVariationOption = (varitionIndex, optionIndex) => {
    const variationIns = [...variations];
    variationIns[varitionIndex].options.splice(optionIndex, 1);
    setVariations(variationIns);
    handleGenerateVariationTable();
  };

  const handleChangeVariationName = (e, variationNumber) => {
    const value = e.target.value;
    const variationIns = [...variations];
    variationIns[variationNumber].name = value;
    setVariations(variationIns);
    let validate = validateUniqueVariation();
    if (validate.length > 0) {
      showToast(validate[0], "error");
      return;
    }
    handleGenerateVariationTable();
  };
  const validateUniqueVariation = () => {
    let error = [];
    let variationName = [];
    variations.forEach((item) => {
      if (variationName.includes(item.name)) {
        error.push(
          "Variation " + item.name + " has exists,Variation name must be unique"
        );
      }
      variationName.push(item.name);
    });
    return error;
  };
  const handleChangeVariationOption = (e, variationIndex, optionIndex) => {
    const value = e.target.value;
    const variationIns = [...variations];
    variationIns[variationIndex].options[optionIndex] = value;
    handleSortVariation(variationIns);
    setVariations(variationIns);
    handleGenerateVariationTable();
  };

  const handleSortVariation = (item) => {
    return item.map((r, i) => {
      r.options.sort();
      return r;
    })
  }

  const handleAddMoreVariation = () => {
    setVariations((pre) => [...pre, { name: "", options: [""] }]);
    // onChangeSalesInfo(null, "variation", true);
  };

  const addVariation = () => {
    setVariations([{ name: "", options: [""] }]);
    onChangeSalesInfo(null, "variation", true);
    // handleGenerateVariationTable()
  };

  const handleDeleteVariation = (variationIndex) => {
    if (variations.length <= 1) {
      setVariations([]);
      setVariationData([]);
      setSalesInformation((pre) => ({ ...pre, variation: false }));
      handleGenerateVariationTable();
    } else {
      const variationIns = [...variations];
      variationIns.splice(variationIndex, 1);
      setVariations(variationIns);
      handleGenerateVariationTable();
    }
  };

  function generateVariation(
    result,
    variation,
    currentVariation = {},
    currentIndex = 0
  ) {
    if (currentIndex === variation.length) {
      result.push(currentVariation);
      return;
    }

    let currentOption = variation[currentIndex];
    let optionName = currentOption.name;

    for (let i = 0; i < currentOption.options.length; i++) {
      let optionValue = currentOption.options[i];
      let updatedVariation = { ...currentVariation, [optionName]: optionValue };
      generateVariation(result, variation, updatedVariation, currentIndex + 1);
    }
  }

  function mergeVariation(variationData, variationList) {
    // console.log(variationList, variationData, "data");
    let defaultValues = {
      price: 0,
      stock: 0,
      teamPrice: 0,
      teamStock: 0,
      unitCapped: 0,
      teamMembers: 0,
      teamUnitCapped: 0,
    };

    let mergedData = [];

    for (let i = 0; i < variationList.length; i++) {
      let found = false;

      for (let j = 0; j < variationData.length; j++) {
        let isMatch = true;

        for (let key in variationList[i]) {
          if (variationList[i][key] !== variationData[j][key]) {
            isMatch = false;
            break;
          }
        }

        if (isMatch) {
          let data = { ...variationList[i], ...variationData[j] };
          mergedData.push(data);
          found = true;
          break;
        }
      }

      if (!found) {
        let data = { ...variationList[i], ...defaultValues };
        mergedData.push(data);
      }
    }
    return mergedData;
  }

  const handleGenerateVariationTable = () => {
    let listVariationData = [];
    generateVariation(listVariationData, variations);
    let mergedData = mergeVariation(variationData, listVariationData);
    setVariationData(mergedData);
  };
  const handleVariationDataChange = (e, key, index) => {
    let value = e.target.value;
    const dataIns = [...variationData];
    if(inArray(key,['price','teamPrice'])){
      value = handleOnly2DecimalPlace(value);
    }
    if(inArray(key,['unitCapped','teamMembers','stock','teamUnitCapped','teamStock'])  && value != ''){
      value = handleNotAllowDecimalPlace(value);
    }

    if(value || value == ''){
      dataIns[index][key] = value;
      setVariationData(dataIns);
    }
  };

  const inArray = (value, array) => {
    return array.includes(value);
  }

  function isFloatNumber(stringNumber) {
    const parsedNumber = parseFloat(stringNumber);
    return !isNaN(parsedNumber)
  }

  function isIntegerNumber(stringNumber) {
    const parsedNumber = parseInt(stringNumber);
    return !isNaN(parsedNumber) && Number.isInteger(parsedNumber);
  }

  function getDecimalValue(number) {
    const parts = number.split('.');
    return parts[1] ? parts[1].length : 0;
  }

  const handleOnly2DecimalPlace = (value) => {
    if (isFloatNumber(value) && value != '') {
      if(getDecimalValue(value) <= 2){
        return value
      }
    }else{
      return value
    }
  }

  const handleNotAllowDecimalPlace = (value) => {
    if(isIntegerNumber(value)){
      return parseInt(value)
    }
  }

  const isValidInputNumber = (val) => {
    console.log("val", val);
    if (val != "" && val != null && val != undefined && val > 0) {
      return true;
    }
    return false;
  };

  const onChangeVariationInfo = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if(inArray(key,['unitCapped','stock'])  && value != ''){
      value = handleNotAllowDecimalPlace(value);
    }
    setVariationInfo((pre) => ({ ...pre, [key]: value }));
  };
  const handleApplyAll = () => {
    if (!variationInfo.unitCapped || !variationInfo.stock) {
      return;
    }
    const variationDataIns = [...variationData];
    const data = variationDataIns.map((item) => ({
      ...item,
      sku: variationInfo.sku,
      teamSku: variationInfo.sku,
      stock: variationInfo.stock,
      unitCapped: variationInfo.unitCapped,
      teamStock: variationInfo.stock,
      teamUnitCapped: variationInfo.unitCapped,
    }));
    setVariationData(data);
  };

  const handleSelectCategory = () => {
    if (openCategory) setOpenCategory(false);
    else setOpenCategory(true);
  };

  const CancelEditing = () => {
    open_dialog(CANCEL_EDIT_MODAL, {
      goBackUrl: param.merchantId
        ? `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`
        : "/merchant/products",
    });
  };

  const deleteImage = (item) => {
    $("#product-image-" + item).val("");
    setImageUrls(imageUrls.filter((i, index) => index !== item));
    setImageFiles(imageFiles.filter((i, index) => index !== item));
  };
  const deleteVideo = () => {
    $(`#product-video-${0}`).val("");
    setVideoFile("");
    setVideoUrl("");
  };
  const viewModalImage = (e, index) => setModalIsOpen(!modalIsOpen);

  return (
    <React.Fragment>
      <div>
        <div className="d-flex justify-content-center  p-3">
          <div className="d-flex flex-column align-items-center">
            <small className="m-0 p-0 h-0">Basic Information</small>
            {steps == 1 && <p className="process-indicate m-0"></p>}
          </div>
          <hr className="process-line mx-2" />
          <div className="d-flex flex-column align-items-center">
            <small className="m-0 p-0 h-0">Sales Information</small>
            {steps == 2 && <p className="process-indicate m-0"></p>}
          </div>
          <hr
            className="process-line mx-2"
            visible={String(basicInformation.type === "physical")}
          />
          <div
            // visible={String(basicInformation.type === "physical")}
            className="d-flex flex-column align-items-center"
          >
            <small className="m-0 p-0 h-0">More Information</small>
            {steps == 3 && <p className="process-indicate m-0"></p>}
          </div>
        </div>

        <div
          id="basic-info-container"
          visible={String(steps == 1)}
          className="mr-3 ml-4 rounded mb-2 px-3 pt-4 bg-white"
        >
          <h6>BASIC INFORMATION</h6>
          <form className="mt-3 mb-2 p-0">
            <div className="col-12 row my-3">
              <label
                htmlFor="product-name"
                className="col-3 text-right m-0 p-0 small imp"
              >
                Product Name
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                id="product-name"
                placeholder="Product Name"
                name="title"
                value={basicInformation.title}
                onChange={onChangeBasicInfo}
                required={true}
              />
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Category
              </label>
              <div className="form-group col-9">
                <div
                  onClick={handleSelectCategory}
                  className="col border form-control pointer bg-muted h-auto"
                >
                  <div className="d-flex alight-item-center flex-wrap">
                    {(showCategory.length > 0 &&
                      showCategory.map((a, i) => (
                        <span
                          className="d-flex mr-3 mb-3 pb-1 px-2 rounded bg-secondary text-white"
                          key={i}
                        >
                          {a}
                          {/* <span className="text-sm">{"    x"}</span> */}
                        </span>
                      ))) || <p className="text-muted">Select Category</p>}
                  </div>
                </div>

                {openCategory && (
                  <div
                    id="checkBoxes"
                    className="category-container col border form-control pointer bg-muted"
                  >
                    <Tree
                      data={dataCategory}
                      setShowCategory={setShowCategory}
                      setIdCategory={setIdCategory}
                      showCategory={showCategory}
                      idCategory={idCategory}
                    />
                  </div>
                )}
              </div>
            </div>
            <ImageModal
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
              imageUrls={imageUrls}
            />

            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Product Images
              </label>
              <div className="icon-button-file col">
                {imageUrls.length > 0 && (
                  <IconButton
                    className="m-0 mb-1 p-0 small pointer"
                    onClick={(e) => viewModalImage(e)}
                  >
                    <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                  </IconButton>
                )}

                <div className="icon-button-file col-12 d-flex flex-wrap">
                  {[0, 1, 2, 3, 4, 5, 6, 7].map((item) => (
                    <div
                      className="ml-n3 mx-5 mb-2"
                      key={"product-image-" + item}
                    >
                      <input
                        accept="image/*"
                        id={`product-image-${item}`}
                        className="d-none"
                        onChange={(e) => handleUploadFile(e, item)}
                        multiple={false}
                        type="file"
                      />
                      <div
                        className="position-relative"
                        onMouseEnter={() => setHide(false)}
                        onMouseLeave={() => setHide(true)}
                      >
                        <label
                          htmlFor={
                            imageUrls?.[item] ? "#" : `product-image-${item}`
                          }
                        >
                          <Image
                            src={imageUrls?.[item] || "/icons/upload_icon.svg"}
                            alt="upload"
                            className={`pointer upload_image_label`}
                          />
                        </label>

                        {!hide && imageUrls?.[item] && (
                          <div
                            className="b-0 bg-dark text-light opacity-25 position-absolute w-100 d-flex justify-content-between"
                            style={{ opacity: "0.7" }}
                          >
                            <IconButton className="pointer">
                              <label
                                htmlFor={`product-image-${item}`}
                                className="mb-0"
                              >
                                <SvgIcon
                                  src="/icons/edit_icon.svg"
                                  alt="view icon"
                                />
                              </label>
                            </IconButton>
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                              className="bg-light text-light"
                            />

                            <IconButton
                              className="pointer"
                              onClick={() => deleteImage(item)}
                            >
                              <SvgIcon
                                src="/icons/delete_icon.svg"
                                alt="delete icon"
                              />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small">
                Product Video
              </label>
              <div className="icon-button-file col-9">
                <div className="icon-button-file col-12 d-flex flex-wrap">
                  <div className="ml-n3 mx-5 mb-2">
                    <input
                      accept="video/*"
                      id={`product-video-${0}`}
                      className="d-none"
                      onChange={(e) => handleUploadVideoFile(e)}
                      multiple={false}
                      type="file"
                    />
                    <div
                      className="position-relative"
                      onMouseEnter={() => setHideVideo(false)}
                      onMouseLeave={() => setHideVideo(true)}
                    >
                      <label htmlFor={videoUrl ? "#" : `product-video-${0}`}>
                        {videoUrl ? (
                          <video width="80" height="80" controls>
                            <source src={videoUrl} type="video/mp4" />
                          </video>
                        ) : (
                          <Image
                            src={"/icons/upload_icon.svg"}
                            alt="upload"
                            className={`pointer upload_image_label`}
                          />
                        )}
                      </label>

                      {!hideVideo && videoUrl && (
                        <div
                          className="b-0 bg-dark text-light opacity-25 position-absolute w-100 d-flex justify-content-between"
                          style={{ opacity: "0.7" }}
                        >
                          <IconButton className="pointer">
                            <label
                              htmlFor={`product-video-${0}`}
                              className="mb-0"
                            >
                              <SvgIcon
                                className="pointer"
                                src="/icons/edit_icon.svg"
                                alt="view icon"
                                style={{ opacity: "0.9" }}
                              />
                            </label>
                          </IconButton>
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            className="bg-light text-light"
                          />

                          <IconButton
                            className="pointer"
                            onClick={() => deleteVideo()}
                          >
                            <SvgIcon
                              src="/icons/delete_icon.svg"
                              alt="delete icon"
                            />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 row">
              <label
                htmlFor="description"
                className="col-3 text-right small imp"
              >
                Description
              </label>
              <div className="col-9 d-flex">
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  value={basicInformation.description}
                  onChange={onChangeBasicInfo}
                  className={"form-control"}
                  placeholder="Enter product description"
                  rows={3}
                ></textarea>
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 my-auto text-right small imp">
                Product Type
              </label>
              <RadioButtonsGroup
                options={productTypes}
                name="product-type"
                className="col-9 px-3"
                value={basicInformation.type}
                onChange={(e) => onChangeBasicInfo(e, "type", e.target.value)}
              />
            </div>
            {basicInformation.type == "non-physical" && (
              <div className="col-12 row my-3">
                <label className="col-3 my-auto text-right small imp">
                  Allow Change Redemption Location
                </label>
                <IOSSwitch
                  name="allowChangeRedemptionLocation"
                  id="allowChangeRedemptionLocation"
                  onChange={toggleAllowChangeRedemptionLocation}
                  checked={basicInformation?.allowChangeRedemptionLocation}
                  value={basicInformation?.allowChangeRedemptionLocation}
                  className="mt-2"
                />
                <label className="col-2 my-auto text-left small">
                 {basicInformation.allowChangeRedemptionLocation?'Allow':'Not Allow'}
                </label>
              </div>
            )}
          </form>
        </div>
        <div
          id="sales-info-variation"
          visible={String(steps == 2)}
          className="mr-3 ml-4 rounded  px-3 pt-4 pb-2 bg-white"
        >
          <h6 className="my-0">SALES INFORMATION</h6>
          <form className="mt-3  p-0">
            <div className="col-12 row my-3">
              <label
                htmlFor="product-variation"
                className="col-2 text-right m-0 p-0 small imp"
              >
                Variation
              </label>
              <div className="col-10">
                {variations.map((item, variationIndex) => (
                  <form
                    className="local-bg p-3 w-100 my-3"
                    key={"variation-" + variationIndex}
                  >
                    <div className="col-12 row my-3">
                      <label
                        htmlFor="variation-name"
                        className="col-3 small imp text-nowrap"
                      >
                        Name
                      </label>
                      <InputField
                        className="pr-5"
                        formClass="col-9"
                        type="text"
                        id="variation-name"
                        placeholder="Enter variation name eg. colour, size, etc"
                        name="title"
                        value={item.name}
                        onChange={(e) =>
                          handleChangeVariationName(e, variationIndex)
                        }
                        required={true}
                        iconType="text"
                        iconPosition="right"
                        iconLabel={`${item.name?.length || 0}/20`}
                        maxLength={20}
                      />
                    </div>
                    <div className="col-12 row my-3">
                      <label
                        htmlFor="variation-name"
                        className="col-3 small imp text-nowrap"
                      >
                        Options
                      </label>
                      <div className="col-9 p-0">
                        {item.options.map((opt, optIndex) => (
                          <div className="col-12 p-0 my-2 d-flex">
                            <InputField
                              className="pr-5"
                              formClass="col-11"
                              type="text"
                              id="variation-options"
                              placeholder="Enter variation option eg. red, etc"
                              name="variation-options"
                              value={opt}
                              onChange={(e) =>
                                handleChangeVariationOption(
                                  e,
                                  variationIndex,
                                  optIndex
                                )
                              }
                              required={true}
                              iconType="text"
                              iconPosition="right"
                              iconLabel={`${opt?.length || 0}/20`}
                              maxLength={20}
                            />
                            <IconButton
                              onClick={() =>
                                handleDeleteVariationOption(
                                  variationIndex,
                                  optIndex
                                )
                              }
                              className="pointer"
                            >
                              <SvgIcon
                                src="/icons/delete_icon.svg"
                                alt="delete icon"
                              />
                            </IconButton>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-12 row justify-content-end">
                      <button
                        type="button"
                        className="btn btn-outline-dark mx-2"
                        onClick={() => handleDeleteVariation(variationIndex)}
                      >
                        delete
                      </button>
                      <button
                        type="button"
                        onClick={() => handleAddVariationOption(variationIndex)}
                        className="btn btn-dark mx-2"
                      >
                        Add Option
                      </button>
                    </div>
                  </form>
                ))}
                <div className="col-12 mt-3">
                  <button
                    type="button"
                    onClick={handleAddMoreVariation}
                    visible={String(variations.length < 2)}
                    className="btn btn-outline-dark w-100"
                  >
                    + Add variation
                  </button>
                </div>
                {/* <div className="col-12 mt-2 mb-3">
                  <button
                    type="button"
                    onClick={handleGenerateVariationTable}
                    // visible={String(variations.length < 2)}
                    className="btn btn-dark w-100"
                  >
                    Regenerate Variation Form
                  </button>
                </div> */}
              </div>
            </div>
            <br />
            <br />
            <div className="col-12 row mt-3 mb-5">
              <label className="col-2 text-right m-0 p-0 small">
                Variation Information
              </label>
              <div className="col-10 pl-5 row">
                <div className="col-3 px-2">
                  <InputField
                    formClass="col-12 p-0"
                    type="number"
                    onKeyPress={(e) => handleKeyPress(e)}
                    min="0"
                    id="unitCapped"
                    placeholder="Unit Capped"
                    name="unitCapped"
                    value={variationInfo.unitCapped}
                    onChange={onChangeVariationInfo}
                    required={true}
                  />
                </div>
                <div className="col-3 px-2">
                  <InputField
                    formClass="col-12 p-0"
                    type="number"
                    onKeyPress={(e) => handleKeyPress(e)}
                    min="0"
                    id="stock"
                    placeholder="Stock"
                    name="stock"
                    value={variationInfo.stock}
                    onChange={onChangeVariationInfo}
                    required={true}
                  />
                </div>
                <div className="col-3 px-2">
                  <InputField
                    formClass="col-12 p-0"
                    type="text"
                    // onKeyPress={(e) => handleKeyPress(e)}
                    // min="0"
                    id="sku"
                    placeholder="SKU(Optional)"
                    name="sku"
                    value={variationInfo.sku}
                    onChange={onChangeVariationInfo}
                    required={false}
                  />
                </div>
                <div className="col-3 px-2">
                  <button
                    onClick={handleApplyAll}
                    type="button"
                    className="btn btn-dark btn-sm text-nowrap"
                  >
                    Apply To All
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 my-3 d-flex">
              <label htmlFor="" className="col-2">
                Individual Variation List
              </label>
              <table className="col-10 border p-3 mx-3">
                <tr>
                  {variations &&
                    variations.length > 0 &&
                    variations.map((item, index) => (
                      <th
                        key={index}
                        className="small border py-1 px-2 local-bg"
                      >
                        {item?.name || "-"}
                      </th>
                    ))}
                  <th className="small border py-1 px-2 local-bg">Price</th>
                  <th className="small border py-1 px-2 local-bg">
                    Unit Capped
                  </th>
                  <th className="small border py-1 px-2 local-bg">Stock</th>
                  <th className="small border py-1 px-2 local-bg">SKU</th>
                </tr>
                {variationData &&
                  variationData.length > 0 &&
                  variationData
                    ?.filter((item) => {
                      return (
                        item[variations.map((item) => item.name)[0]]?.trim()
                          .length !== 0
                      );
                    })
                    .map((row, rowIndex) => (
                      <tr className="" key={rowIndex}>
                        {variations.map((item, index) => (
                          <th
                            key={index}
                            className="border px-2 small text-muted"
                          >
                            {row[item.name]}
                          </th>
                        ))}
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text  border-0 input_icon_left"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="price"
                            placeholder="Input"
                            name="price"
                            value={row.price}
                            onChange={(e) =>
                              handleVariationDataChange(e, "price", rowIndex)
                            }
                            required={false}
                            icon="currency"
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="unitCapped"
                            placeholder="Input"
                            name="unitCapped"
                            value={row.unitCapped}
                            onChange={(e) =>
                              handleVariationDataChange(
                                e,
                                "unitCapped",
                                rowIndex
                              )
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="stock"
                            placeholder="Input"
                            name="stock"
                            value={row.stock}
                            onChange={(e) =>
                              handleVariationDataChange(e, "stock", rowIndex)
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="text"
                            // onKeyPress={(e) => handleKeyPress(e)}
                            // min="0"
                            id="sku"
                            placeholder="Input"
                            name="sku"
                            value={row.sku}
                            onChange={(e) =>
                              handleVariationDataChange(e, "sku", rowIndex)
                            }
                            required={false}
                          />
                        </td>
                      </tr>
                    ))}
              </table>
            </div>
            <div className="col-12 my-3 d-flex">
              <label htmlFor="" className="col-2">
                Team Variation List
              </label>
              <table className="col-10 border p-3 mx-3">
                <tr>
                  {variations &&
                    variations.length > 0 &&
                    variations.map((item, index) => (
                      <th
                        key={index}
                        className="small border py-1 px-2 local-bg"
                      >
                        {item?.name || "-"}
                      </th>
                    ))}
                  <th className="small border py-1 px-2 local-bg">TP Price</th>
                  <th className="small border py-1 px-2 local-bg">TP Member</th>
                  <th className="small border py-1 px-2 local-bg">
                    Unit Capped
                  </th>
                  <th className="small border py-1 px-2 local-bg">Stock</th>
                  <th className="small border py-1 px-2 local-bg">SKU</th>
                </tr>
                {variationData &&
                  variationData.length > 0 &&
                  variationData
                    ?.filter((item) => {
                      return (
                        item[variations.map((item) => item.name)[0]]?.trim()
                          .length !== 0
                      );
                    })
                    .map((row, rowIndex) => (
                      <tr className="" key={rowIndex}>
                        {variations &&
                          variations.length > 0 &&
                          variations.map((item, index) => (
                            <th
                              key={index}
                              className="border px-2 small text-muted"
                            >
                              {row[item.name]}
                            </th>
                          ))}
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text input_icon_left border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="teamPrice"
                            placeholder="Input"
                            name="teamPrice"
                            value={row.teamPrice}
                            icon="currency"
                            onChange={(e) =>
                              handleVariationDataChange(
                                e,
                                "teamPrice",
                                rowIndex
                              )
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="teamMembers"
                            placeholder="Input"
                            name="teamMembers"
                            value={row.teamMembers}
                            onChange={(e) =>
                              handleVariationDataChange(
                                e,
                                "teamMembers",
                                rowIndex
                              )
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="teamUnitCapped"
                            placeholder="Input"
                            name="teamUnitCapped"
                            value={row.teamUnitCapped}
                            onChange={(e) =>
                              handleVariationDataChange(
                                e,
                                "teamUnitCapped",
                                rowIndex
                              )
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="teamStock"
                            placeholder="Input"
                            name="teamStock"
                            value={row.teamStock}
                            onChange={(e) =>
                              handleVariationDataChange(
                                e,
                                "teamStock",
                                rowIndex
                              )
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="text"
                            // onKeyPress={(e) => handleKeyPress(e)}
                            // min="0"
                            id="teamSku"
                            placeholder="Input"
                            name="teamSku"
                            value={row.teamSku}
                            onChange={(e) =>
                              handleVariationDataChange(e, "teamSku", rowIndex)
                            }
                            required={false}
                          />
                        </td>
                      </tr>
                    ))}
              </table>
            </div>
          </form>
        </div>

        <div
          id="specification"
          visible={String(steps == 3)}
          className="mr-3 ml-4 rounded  px-3 pt-4 pb-2 bg-white"
        >
          <h6 className="my-0">SPECIFICATION</h6>
          <form className="mt-3 p-0">
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small ">Brand</label>
              <div className="form-group col-9">
                <SelectInput
                  options={brandsList}
                  name="brand"
                  value={specification.brand}
                  onChange={(e) => onChangeSpecification(e, "brand", e)}
                  required={true}
                  formSubmitted={false}
                  placeholder="Select brand"
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Ship From
              </label>
              <div className="form-group col-9">
                <SelectInput
                  options={countriesList}
                  name="shipFrom"
                  value={specification.shipFrom}
                  onChange={(e) => onChangeSpecification(e, "shipFrom", e)}
                  required={true}
                  formSubmitted={false}
                  placeholder="Select country"
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                {basicInformation.type == "non-physical"
                  ? "Expiry Date"
                  : "Warranty Period"}
              </label>
              <div className="form-group col-9">
                {basicInformation.type == "non-physical" ? (
                  <InputField
                    className="pr-5"
                    formClass="p-0"
                    type="date"
                    min="0"
                    id="expiry-date"
                    placeholder="Input"
                    name="expiryDate"
                    value={specification?.expiryDate || ""}
                    onChange={onChangeSpecification}
                    required={true}
                    iconType="text"
                    iconLabel="Months"
                    iconPosition="right"
                  />
                ) : (
                  <InputField
                    className="pr-5"
                    formClass="p-0"
                    type="number"
                    onKeyPress={(e) => handleKeyPress(e)}
                    min="0"
                    id="warranty-period"
                    placeholder="Input"
                    name="warrantyPeriod"
                    value={specification.warrantyPeriod}
                    onChange={onChangeSpecification}
                    required={true}
                    iconType="text"
                    iconLabel="Months"
                    iconPosition="right"
                  />
                )}
              </div>
            </div>
          </form>
        </div>
        <br />
        <div
          id="shipping-address"
          visible={String(
            basicInformation.type == "non-physical"
              ? false
              : steps == 3
              ? true
              : false
          )}
          className="mr-3 ml-4 rounded mb-2 px-3 pt-4 bg-white"
        >
          <h6 className="my-0">SHIPPING INFORMATION</h6>
          <form className="mt-3 p-0">
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Weight
              </label>
              <div className="form-group col-9">
                <InputField
                  className="input_icon_right"
                  formClass="p-0"
                  type="number"
                  onKeyPress={(e) => handleKeyPress(e)}
                  min="0"
                  id="weight"
                  placeholder="Input"
                  name="weight"
                  icon="kg"
                  value={shippingInfo.weight}
                  onChange={onChangeShippingInfo}
                  required={true}
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Parcel Size
              </label>
              <div className="form-group col-9 d-flex flex-wrap">
                <InputField
                  className="input_icon_left input_icon_right"
                  icon="width-cm"
                  formClass="col-4 p-0"
                  type="number"
                  onKeyPress={(e) => handleKeyPress(e)}
                  min="0"
                  id="parcel-width"
                  placeholder="Input"
                  name="parcelWidth"
                  value={shippingInfo.parcelWidth}
                  onChange={onChangeShippingInfo}
                  required={true}
                />
                <InputField
                  className="input_icon_left input_icon_right"
                  icon="length-cm"
                  formClass="col-4 p-0"
                  type="number"
                  onKeyPress={(e) => handleKeyPress(e)}
                  min="0"
                  id="parcel-length"
                  placeholder="Input"
                  name="parcelLength"
                  value={shippingInfo.parcelLength}
                  onChange={onChangeShippingInfo}
                  required={true}
                />
                <InputField
                  className="input_icon_left input_icon_right"
                  icon="height-cm"
                  formClass="col-4 p-0"
                  type="number"
                  onKeyPress={(e) => handleKeyPress(e)}
                  min="0"
                  id="parcel-height"
                  placeholder="Input"
                  name="parcelHeight"
                  value={shippingInfo.parcelHeight}
                  onChange={onChangeShippingInfo}
                  required={true}
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Shipping Fee
              </label>
              <div className="form-group col-9">
                <p>
                  You can also{" "}
                  <button href="#" className="btn bold" disabled>
                    Click Here
                  </button>{" "}
                  to enable more shipping channel.
                </p>
                <div className="card border">
                  <div className="card-header d-flex">
                    Delivery Fee: <div className="bold">RM0:0</div>
                  </div>
                  <div className="card-body">
                    {/* <p>DHL Express (max 30kg)</p> */}
                    <p>JNT Express (max 30kg)</p>
                  </div>
                </div>
                <p className="small text-muted">
                  Shipping settings will be applicable for this product only.
                  Shipping fees displayed are base rates and will be subject to
                  change depending on buyer and seller location.
                </p>
              </div>
            </div>
          </form>
        </div>
        <br />
        <div
          id="other-information"
          visible={String(steps == 3)}
          className="mr-3 ml-4 rounded mb-2 px-3 pt-4 bg-white"
        >
          <h6 className="my-0">OTHERS</h6>
          <form className="mt-3 p-0">
            <div className="col-12 row my-3">
              <label className="col-3 text-right mx-0 mt-2 p-0 small imp">
                Pre-Order
              </label>
              <div className="form-group col-9">
                <RadioButtonsGroup
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  name="preOrder"
                  className="col-9 px-3"
                  value={basicInformation.preOrder}
                  onChange={(e) =>
                    onChangeBasicInfo(e, "preOrder", e.target.value)
                  }
                />
              </div>
            </div>
          </form>
        </div>
        <div className="error_container mr-3 ml-4 rounded px-3 d-flex">
          {Object.values(errors).filter((item) => item).length ? (
            <label className="error fntSz14">
              {Object.values(errors)
                .filter((item) => item)
                .join(", ")}{" "}
              fields are missing or invalid!
            </label>
          ) : (
            <></>
          )}
        </div>
        <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 my-3 bg-white border-top">
          <div className="col-md-4 d-flex  align-items-center mr-2 justify-content-end">
            <button
              type="button"
              className="btn border input-inside-text mr-2"
              onClick={() => CancelEditing()}
              // onClick={() => history.push(param.merchantId ? `/merchant-center/profiles/${param.merchantId}/${param.merchantName}` : "/merchant/products")}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn border input-inside-text mr-2"
              onClick={() => setSteps(steps - 1)}
              disabled={steps <= 1}
            >
              Prev
            </button>

            <button
              type="button"
              className="btn text-nowrap border input-inside-text mr-2"
              onClick={() => handleClickNext("DRAFT")}
              // disabled={steps !== 3}
            >
              Save to Draft
            </button>

            <button
              type="button"
              className="btn input-inside-text btn-dark text-nowrap"
              onClick={() =>
                handleClickNext(
                  !param.merchantId
                    ? !editProduct
                      ? "NEW"
                      : editStatusText
                    : "PUBLISHED"
                )
              }
            >
              {steps == 3
                ? !param.merchantId
                  ? "Submit Approval"
                  : "Save & Publish"
                : "Next"}
              {/* {steps == 3 ? (editProduct ? !param.merchantId ? "Save" : "Save & Publish" : !param.merchantId ? "Create" : "Create & Publish") : "Next"} */}
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default MerchantAddProduct;
