import {
    IconButton,
    TablePagination,
} from "@mui/material";
import Image from "../../../../components/Images/Image";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../../components/Images/SvgIcon";
import moment from "moment";
import {
    startLoader,
    stopLoader,
    showToast,
    open_dialog
} from "../../../../lib/global";
import "../../../../static/css/orderCss/allOrder.css"
import { getAllOrderList, getOrderByStoreId } from "../../../../redux/actions/order.action";
import { getLocalStorage } from "../../../../lib/session";
import { ACCESS_DENIED } from "../../../../lib/constant";
// import { LOGIN_POSTER } from "../../../../lib/config";
import InputField from   "../../../../components/formControl/input/InputField";


const initialOrderSearchDetail={
    
}

function MerchantOrderSetting(props) {
    const [permissions, setPermissions] = useState(getLocalStorage("assignMethod"))
    const [allOrderData, setAllOrderData] = useState([])
    // const []
    const [searchData, setSearchData] = useState("");
    const history = useHistory()
    useEffect(() => {
        if (permissions !== 'undefined') {
            setPermissions(JSON.parse(getLocalStorage("assignMethod")))
            console.log(JSON.parse(getLocalStorage("assignMethod")), "hhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
        } else {
            setPermissions({})
        }
    }, [])
    const {mid,merchantName}=useParams()

    const viewAction = (id, item) => {
        if (!item && item !== undefined) {
            open_dialog(ACCESS_DENIED, {
            });
        } else if (item == 'undefined' || item == undefined) {
            history.push(`/merchant-center/profiles/order/${mid}/${merchantName}/${id}`)
        }
        else {
            console.log(id, 'fghj');
            history.push(`/merchant-center/profiles/order/${mid}/${merchantName}/${id}`)
        }
    } 
    console.log(mid);
    const { status } = props
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getOrderByStoreId(mid))
            .then((data) => {
                console.log(data?.data?.data);
                setAllOrderData(data?.data?.data)
            }).catch((error) => {
                console.log(error);
            })
    }, [])
    const getApiData = (searchingData) => {
        console.log(searchingData);
        startLoader()
        dispatch(getOrderByStoreId(searchingData))
            .then((data) => {
                console.log(data);
                setAllOrderData(data && data.data && data.data.data);
                setTimeout(() => {
                    stopLoader()
                }, 1000)
            }).catch(error => {
                stopLoader()
                console.log({ error }, 'errorr');
            })
    };

    const onChangeConsumerSearchDetail = (e, title, val) => {
        const key = title || e.target.id;
        const value = val || e.target.value;
        console.log(key, value);
        setSearchData((pre) => ({ ...pre, [key]: value }));
    };

    const searchOrders = () => {
        startLoader()
        const response = dispatch(getAllConsumerList(searchData));
        response
            .then((data) => {
                if (data && data.data && data.data.status === 200) {
                    setConsumerData([...data.data.data]);
                    setTimeout(() => {
                        stopLoader()
                        // showToast(@)
                    }, 1000)
                } else {
                    stopLoader()
                }
            })
            .catch(error => {
                console.log({ error })
            })

    }
    const resetSearch = () => {
        // --this is pending for api call---
        startLoader()
        const response = dispatch(getAllConsumerList({}));
        response
            .then((data) => {
                // console.log(data.data);
                if (data && data.data && data.data.status === 200) {
                    setTimeout(() => {
                        stopLoader()
                        setSearchData(initialConsumerSearchDetail)
                        setConsumerData(data && data.data && data.data.data);
                    }, 1000)
                } else {
                    stopLoader()
                }
            })

            .catch((error) => console.log("----------error----", error));

    }

    return (
        <React.Fragment>
            <div className="mt-1  py-3 rounded bg-white">
                <div className="col-12  p-0 row flex-wrap">
                    <div className="min-width-200 col-md-3">
                        <label>Order Status</label>
                        <InputField type="text"
                            className="form-control"
                            placeholder="Input"
                            id="OrderStatus"
                        //   value={serchData.storeId}
                        //   onChange={onChangeMerchantSearchDetail}
                        />
                    </div>
                    <div className="min-width-200 col-md-3">
                        <label>Courier</label>
                        <InputField type="text"
                            className="form-control"
                            placeholder="Input"
                            id="Courier"
                        //   value={serchData.merchantName}
                        //   onChange={onChangeMerchantSearchDetail}
                        />
                    </div>
                    <div className="min-width-200 col-md-3">
                        <label>Order ID</label>
                        <InputField type="text"
                            className="form-control"
                            placeholder="Input"
                            id="OrderId"
                        //   value={serchData.storeId}
                        //   onChange={onChangeMerchantSearchDetail}
                        />
                    </div>
                    <div className="min-width-200 col-md-3">
                        <label>Order Date</label>
                        <InputField type="date"
                            className="form-control"
                            placeholder="Input"
                            id="OrderDate"
                        //   value={serchData.merchantName}
                        //   onChange={onChangeMerchantSearchDetail}
                        />
                    </div>



                </div>
                <div className="d-flex justify-content-end mt-3">
                    <button
                        type="button"
                        className="btn input-inside-text mr-1 btn-white border px-4"
                        onClick={() => resetSearch()}
                    >
                        Reset
                    </button>
                    <button
                        type="button"
                        className="btn input-inside-text mx-1 btn-secondary px-3"
                        onClick={() => searchOrders()}
                    >
                        Search
                    </button>
                </div>
            </div>


            <div>
                <div className="allTeamPurchaseMain">
                    <div className="tableheadTeamPurchaseProduct teamPurchase_borderLine col-5">

                        <div className="d-flex  ">
                            <div>Product</div>
                            <div className="d-flex flex-column ">
                                <ExpandLessIcon
                                    style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                    onClick={() => getApiData({ item: "title", order: "ASC" })}

                                />
                                <ExpandMoreIcon
                                    style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                    onClick={() => getApiData({ item: "title", order: "DESC" })}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="teamPurchase_tablehead teamPurchase_borderLine">

                        <div className="d-flex  ">
                            <div> Total</div>
                            <div className="d-flex flex-column ">
                                <ExpandLessIcon
                                    style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}

                                />
                                <ExpandMoreIcon
                                    style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="teamPurchase_tablehead teamPurchase_borderLine">


                        <div className="d-flex  ">
                            <div> Order Status</div>
                            <div className="d-flex flex-column ">
                                <ExpandLessIcon
                                    style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}

                                />
                                <ExpandMoreIcon
                                    style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="teamPurchase_tablehead teamPurchase_borderLine">


                        <div className="d-flex  ">
                            <div> Courier</div>
                            <div className="d-flex flex-column ">
                                <ExpandLessIcon
                                    style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}

                                />
                                <ExpandMoreIcon
                                    style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="tableheadfix tableheadfix_allorder teamPurchase_borderLine">Action</div>
                    {!allOrderData.length ? <div>No data found</div> :
                        <>
                            {allOrderData.map((item, index) => (

                                <div className="info">
                                    <div className="teamPurchase-orderId-detail-maindiv">
                                        <div className="justify-content-between d-flex teamPurchase-orderId-detail-section"
                                        >
                                            <div className="teamPurchase_orderId_Detail_Name mr-5">
                                                <p>Buyer: {item.consumerName || "N/A"}</p>
                                                <Image
                                                    src="/icons/chat1.png"
                                                    className="teamPurchase_chat_icon mb-1"
                                                    alt="Header logo for admin dashboard"
                                                />
                                            </div>
                                        </div>
                                        <div className="teamPurchase-orderId-detail-indexId">

                                        Order ID: {item?.orderId}                                         </div>

                                    </div>

                                    <div className="teamPurchase_tablehead_details_head">
                                        <div className="tableheadTeamPurchaseProduct"
                                            onClick={() => viewAction(item.orderId)}
                                        >
                                            <div className="d-flex ">
                                                <Image
                                                    src="/images/shoes.jpeg"
                                                    alt={'photo'}
                                                    className="teamPurchase_page_product_image"
                                                />
                                                <div className=" mx-2">
                                                    <div className="teamPurchase_Page_ProductBox_ProductName">{item?.productName || "N/A"} </div>
                                                    <div className="teamPurchase_Page_ProductBox_Product_varient">Variation: {`${item?.variation && item?.variation?.length>0 &&  item?.variation?.map((Item,index)=>Item?.value).toString() || " N/A"}` }</div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="teamPurchase_tablehead">
                                            <div >

                                                <div className="teamPurchase_Page_ProductBox_ProductName">{`${item.currencyName || "RM"}${item.price || "00"}.00`}</div>
                                                <div className="teamPurchase_Page_ProductBox_Product_varient">Online Banking</div>
                                            </div>
                                        </div>
                                        <div className="teamPurchase_tablehead">
                                            <div>
                                                <div className="teamPurchase_Page_ProductBox_ProductName">{item?.shippingDetails?.orderStatus}</div>
                                                <div className="teamPurchase_Page_ProductBox_Product_varient ">To avoid late shisss</div>
                                            </div>
                                        </div>
                                        <div className="teamPurchase_tablehead">
                                            <div>
                                                <div className="teamPurchase_Page_ProductBox_ProductName">{item?.shippingDetails?.courierName || "N/A"}</div>
                                                <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                                            </div>
                                        </div>
                                        <div className="tableheadfix tableheadfix_allorder">
                                            <div className="d-flex justify-content-center ">
                                                <div>
                                                    <IconButton
                                                        className="pointer"
                                                        onClick={() => viewAction(item.orderId, permissions?.order?.includes('update'))}

                                                    >
                                                        <SvgIcon
                                                            src="/icons/view_icon.svg"
                                                            alt="view icon"
                                                        />
                                                    </IconButton>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </>
                    }

                    {/* <div className="d-flex align-items-center " >
                        <div className="mr-3 text-muted">Expand ( {allProductData.length-2} Products) </div>

                        <button className="btn bg-white" onClick={() => setQuatity(allProductData.length)}
                        >
                            <Image  
                                src='/images/arrow_down.png'
                                alt='photo'
                                width={16}
                                height={10}
                            />
                        </button>
                    </div> */}
                </div>

            </div>

            <TablePagination
                rowsPerPageOptions={10}
                component="div"
                // count={allProductData.length}
                count={2}
                rowsPerPage={10}
                page={0}
                onPageChange={(e) => {
                    console.log("page change", e);
                }}
            // onRowsPerPageChange={(e)=>{console.log('page change', e)}}
            />

        </React.Fragment >
    );
}

export default MerchantOrderSetting










