// modal names

export const ADD_MERCHANT = "ADD_MERCHANT"
export const MERCHANT_ADD_BANK_ACCOUNT = "MERCHANT_ADD_BANK_ACCOUNT"
export const DELETE_HELP_CENTER_TICKET = "DELETE_HELP_CENTER_TICKET"
export const ADD_AUTHORIED = "ADD_AUTHORIED"
export const ADD_SUB_ACCOUNT = "ADD_SUB_ACCOUNT"
export const ADD_SHIPPING_ADDRESS = "ADD_SHIPPING_ADDRESS"
export const ADD_BUSINESS_PARTNER = "ADD_BUSINESS_PARTNER"
export const CATEGORY_SELECTION_DIALOG = "CATEGORY_SELECTION_DIALOG";

export const DELETE_DIALOG = "DELETE_DIALOG";
export const EDIT_BANK_ACCOUNT_DIALOG = "EDIT_BANK_ACCOUNT_DIALOG";
export const EDIT_MERCHANT_ACCOUNT_DIALOG = "EDIT_MERCHANT_ACCOUNT_DIALOG";
export const EDIT_MERCHANT_SHIPPING_ADDRESS_DIALOG = "EDIT_MERCHANT_SHIPPING_ADDRESS_DIALOG"
export const EDIT_SUB_ACCOUNT_DIALOG = "EDIT_SUB_ACCOUNT_DIALOG";
export const EDIT_PARTNERS_ACCOUNT_DIALOG = "EDIT_PARTNERS_ACCOUNT_DIALOG";
export const VERIFY_MERCHANT_DIALOG = "VERIFY_MERCHANT_DIALOG"
export const PRODUCT_DELETE_DIALOG = "PRODUCT_DELETE_DIALOG"
export const MERCHANT_PRODUCT_DELETE_DIALOG = "MERCHANT_PRODUCT_DELETE_DIALOG"


export const ADD_CONSUMER = "ADD_CONSUMER"
export const ADD_CONSUMER_BANK_ACCOUNT = "ADD_CONSUMER_BANK_ACCOUNT"
export const EDIT_CONSUMER_BANK_ACCOUNT = "EDIT_CONSUMER_BANK_ACCOUNT"
export const ADD_CONSUMER_SHIPPING_ADDRESS = "ADD_CONSUMER_SHIPPING_ADDRESS"
export const EDIT_CONSUMER_SHIPPING_ADDRESS = "EDIT_CONSUMER_SHIPPING_ADDRESS"
export const DELETE_CONSUMER = "DELETE_CONSUMER"
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_BRAND = "DELETE_BRAND"
export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_USER_ROLE = "DELETE_USER_ROLE"
export const DELETE_OUTLET = "DELETE_OUTLET"
export const DELETE_OUTLET_MERCHANT = "DELETE_OUTLET_MERCHANT"



export const SHOW_TRANSACTION_HISTORY="SHOW_TRANSACTION_HISTORY"
export const ACCESS_DENIED="ACCESS_DENIED"
export const CREATE_LIVESTREAM_ADD_PRODUCT="CREATE_LIVESTREAM_ADD_PRODUCT"
export const CREATE_LIVESTREAM_ADD_PROMO="CREATE_LIVESTREAM_ADD_PROMO"
export const CREATE_VOUCHER_ADD_PRODUCT="CREATE_VOUCHER_ADD_PRODUCT"
export const MERCHANT_CREATE_VOUCHER_ADD_PRODUCT="MERCHANT_CREATE_VOUCHER_ADD_PRODUCT"
export const DELETE_LIVESTREAM="DELETE_LIVESTREAM"
export const GO_LIVESTREAM_SHOW_PRODUCT="GO_LIVESTREAM_SHOW_PRODUCT"
export const GO_LIVESTREAM_SHOW_PROMO="GO_LIVESTREAM_SHOW_PROMO"
export const CANCEL_EDIT_MODAL="CANCEL_EDIT_MODAL"
export const FINANCE_REPORT_MODAL="FINANCE_REPORT_MODAL"
export const DELETE_BANNER_MODEL="DELETE_BANNER_MODEL"

export const DELETE_PRODUCT_BY_MERCHANT_DIALOG="DELETE_PRODUCT_BY_MERCHANT_DIALOG"
export const FLASH_SALE_ADD_PRODUCT_LIST="FLASH_SALE_ADD_PRODUCT_LIST"
export const VIEW_ORDER_TRANSACTION_HISTORY="VIEW_ORDER_TRANSACTION_HISTORY"
export const VIEW_GENERATE_REPORT_MODAL="VIEW_GENERATE_REPORT_MODAL"
export const PASSWORD_FORGET_INFO_MODAL="PASSWORD_FORGET_INFO_MODAL"

export const MERCHANT_FLASH_SALE_ADD_PRODUCT="MERCHANT_FLASH_SALE_ADD_PRODUCT"
export const MERCHANT_DELETE_TIMESLOT_DIALOG="MERCHANT_DELETE_TIMESLOT_DIALOG"
export const FLASH_SALE_ADD_PRODUCT="FLASH_SALE_ADD_PRODUCT"

export const DELETE_VOUCHER = "DELETE_VOUCHER"
export const DELETE_GROUP_DEAL = "DELETE_GROUP_DEAL"
export const DELETE_GROUP_DEAL_PRODUCT = "DELETE_GROUP_DEAL_PRODUCT"
export const CREATE_GROUP_DEAL_PRODUCT="CREATE_GROUP_DEAL_PRODUCT"

export const MERCHANT_FLASH_SALE_DUPLICATE_ADD_PRODUCT="MERCHANT_FLASH_SALE_DUPLICATE_ADD_PRODUCT"

export const CHAT_LIST_MERCHANT="CHAT_LIST_MERCHANT"
export const CHAT_LIST_CUSTOMER="CHAT_LIST_CUSTOMER"

export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;




