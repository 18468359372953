import React, { useEffect, useRef, useState } from "react";
import {
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../components/Images/SvgIcon";
import { getAllNotification } from "../../redux/actions/notification.action";
import { AllowAccess, startLoader, stopLoader } from "../../lib/global";
import moment from "moment/moment";
import PagenationDropdown from "../../components/DropDown/pagenationDropdown";

function AllNotifications(props) {
  AllowAccess(["MERCHANT"]);
  const history  = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [allNotification, setAllNotification] = useState([]);

  useEffect(() => {
    let filteringdata = {
      type:props.type
    }
    startLoader();

    const response = dispatch(getAllNotification({ ...filteringdata }));
    response.then((data) => {
      setAllNotification(
        data &&
        data.data &&
        data.data.data.map((row, index) => {
          return {
            title: row?.title || "N/A",
            message: row?.message || "N/A",
            notificationType: row?.notificationType || "N/A",
          };
        })
      );

      stopLoader();
    })
    .catch((error) => {
      stopLoader();
      console.log({ error }, "error");
    });
  }, []);

  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className="th_cell" component="th">
                No
              </TableCell>
              {/*<TableCell className="th_cell" component="th" align="left">*/}
              {/*  <div className="d-flex  ">*/}
              {/*    <div>Title</div>*/}
              {/*    <div className="d-flex flex-column ">*/}
              {/*      <ExpandLessIcon*/}
              {/*        style={{*/}
              {/*          width: "15px",*/}
              {/*          height: "15px",*/}
              {/*          margin: "0px 3px",*/}
              {/*        }}*/}
              {/*        // onClick={() => handleSorting("productName", "asc")}*/}
              {/*      />*/}
              {/*      <ExpandMoreIcon*/}
              {/*        style={{*/}
              {/*          width: "15px",*/}
              {/*          height: "15px",*/}
              {/*          margin: "-5px 3px",*/}
              {/*        }}*/}
              {/*        // onClick={() => handleSorting("productName", "desc")}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</TableCell>*/}
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Message</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      // onClick={() => handleSorting("productType", "asc")}
                      // onClick={() => getApiData({ item: "title", order: "ASC" })}
                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      // onClick={() => handleSorting("productType", "desc")}
                      // onClick={() => getApiData({ item: "title", order: "DESC" })}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell " component="th" align="center">
                <div className="d-flex  ">
                  <div>Type</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      // onClick={() => handleSorting("rating", "asc")}
                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      // onClick={() => handleSorting("rating", "desc")}
                    />
                  </div>
                </div>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {!allNotification?.length && (
              <div className="d-flex justify-content-center">
                <h3 className="text-muted m-auto">No data found!</h3>
              </div>
            )}

            {allNotification &&
              allNotification.length > 0 &&
              allNotification.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left" className="td_cell">
                    {index+1}
                  </TableCell>
                  {/*<TableCell align="left" className="td_cell">*/}
                  {/*  {row?.title|| "N/A"}*/}
                  {/*</TableCell>*/}
                  <TableCell align="left" className="td_cell">
                    {row.message || "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.notificationType || "N/A"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {/*<div className="d-flex justify-content-between align-items-center">*/}
      {/*  <PagenationDropdown setPageQuant={setPageLimit} />*/}

      {/*  <div className="float-right mb-1">*/}
      {/*    <Stack spacing={2}>*/}
      {/*      <Pagination*/}
      {/*        count={Math.ceil((props?.totalItem || 0) / pageLimit)}*/}
      {/*        shape="rounded"*/}
      {/*        size="small"*/}
      {/*        page={pageNo}*/}
      {/*        onChange={handlePageChange}*/}
      {/*      />*/}
      {/*    </Stack>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <br />
    </React.Fragment>
  )
}

export default AllNotifications