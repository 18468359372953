import React, { useEffect, useState } from "react";
// import { InputType } from "zlib";
// import { EMAIL_REGEX } from "../../lib/constant";
import { EMAIL_REGEX } from "../../../lib/constant";
// import { handleValidate } from "../../utils/functions";

/**
 * @description use this component for input field
 * @author jagannath
 * @date 27/12/2021
 * @param type: "text" | "number",
 * @param id: string
 * @param value: string | number,
 * @param onChange: any,
 * @param className: string,
 * @param required: boolean,
 * @param formSubmitted: boolean,
 * @param name: string,
 * @param label: string,
 * @param lang: string,
 * @param formClass?: string
 * @param min?: number
 * @param max?: number
 * @param minLength?: number
 * @param maxLength?: number
 * @param icon?: string - "currency"
 */
const InputField = (props) => {
  const [error, setError] = useState(props.error);
  const optionalProps = {};
  if (props.min) optionalProps["min"] = props.min;
  if (props.max) optionalProps["max"] = props.max;
  if (props.maxLength) optionalProps["maxLength"] = props.maxLength;
  if (props.minLength) optionalProps["minLength"] = props.minLength;
  if (props.onKeyPress) optionalProps["onKeyPress"] = props.onKeyPress;

  useEffect(() => {
    setError(props.error);
  }, [props.error]);
  const handleValidate = (e) => {
    if ((!e.target.value || e.target.value == 0) && e.target.required) {
      e.target.classList.add("field-invalid");
      setError("fieldRequired");
    } else if (e.target.type === "email") {
      if (!e.target.value.match(EMAIL_REGEX)) {
        e.target.classList.add("field-invalid");
        setError("invalidEmail");
      } else {
        e.target.classList.remove("field-invalid");
        setError("");
      }
    } else {
      e.target.classList.remove("field-invalid");
      e.target.classList.add("field-validated");
      setError("");
    }
  };

  return (
    <div className={`form-group m-0 ${props.formClass || "my-2 w-100"}`}>
      {props.type === "textarea" ? (
        <textarea
          id={props.id || props.name || props.label}
          name={props.name || props.label}
          className={`form-control ${props.className}`}
          type={"text"}
          value={props.value}
          field-invalid={String(
            props.formSubmitted && !props.value && props.required
          )}
          field-not-empty={String(!!props.value)}
          onBlur={handleValidate}
          onChange={props.onChange}
          required={!!props.required}
          placeholder={props.placeholder || "input..."}
          disabled={!!props.disabled}
          rows={props.rows || 3}
          {...optionalProps}
        ></textarea>
      ) : (
        <input
          id={props.id || props.name || props.label}
          name={props.name || props.label}
          className={`form-control ${props.className}`}
          type={props.type}
          value={props.value}
          field-invalid={String(
            props.formSubmitted && !props.value && props.required
          )}
          field-not-empty={String(!!props.value)}
          onBlur={handleValidate}
          onChange={props.onChange}
          onClick={props.onClick}
          required={!!props.required}
          placeholder={props.placeholder || "input"}
          disabled={!!props.disabled}
          {...optionalProps}
        />
      )}
      {/* <p className="form-label">{props.label}</p> */}
      {(error || (props.required && props.formSubmitted && !props.value)) && (
        <label
          htmlFor={props.id || props.name || props.label}
          className="error"
        >
          Mandatory Field
        </label>
      )}
      {props.icon == "currency" ? (
        <span className={"input-icon icon-left text-dark fntSz12"}>RM </span>
      ) : (
        <></>
      )}
      {props.icon == "kg" ? (<span className={"input-icon icon-right text-dark fntSz12"}>| KG</span>):(<></>)}
      {props.icon == "length-cm" ? (<span className={"input-icon icon-left text-dark fntSz12"}>L</span>):(<></>)}
      {props.icon == "width-cm" ? (<span className={"input-icon icon-left text-dark fntSz12"}>W</span>):(<></>)}
      {props.icon == "height-cm" ? (<span className={"input-icon icon-left text-dark fntSz12"}>H</span>):(<></>)}
      {props.iconType == "text" ? (<span className={`input-icon icon-${props.iconPosition} text-dark fntSz12`}>{props.iconLabel}</span>):(<></>)}
      {props.icon == "length-cm" || props.icon == "width-cm" || props.icon == "height-cm" ? (<span className={"input-icon icon-right text-dark fntSz12"}>| CM</span>):(<></>)}
    </div>
  );
};

export default InputField;
