import {
  Get,
  GetWithToken,
  PatchWithToken,
  PostWithToken,
  PutWithToken,
} from "../../lib/request";

export const getAllMerchantList = (query) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/merchants?merchantName=${
        query.merchantName || ""
      }&preferredMerchantName=${query.preferredMerchantName || ""}&merchantID=${
        query.storeId || ""
      }&authorizedPersonName=${
        query.authorizedPersonName || ""
      }&merchantRegisteredDate=${query.merchantRegisteredDate || ""}&item=${
        query.item || ""
      }&order=${query.order || ""}&offset=${query.offset || 0}&limit=${
        query.limit || 10
      }`
    );
    return result;
  };
};

export const getAllMerchantListAfterDelete = (query) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/merchants`);
    return result;
  };
};

export const generateMerchantIdAction = () => {
  return async function (dispatch) {
    const result = await PostWithToken("/merchants/generate-merchantid");
    return result;
  };
};

export const addNewMerchant = (payload, saveStatus) => {
  return async function (dispatch) {
    let URL;
    if (saveStatus) {
      URL = `/merchants/registerToDraft`;
    } else {
      URL = `/merchants/register`;
    }
    const result = await PostWithToken(URL, payload);
    return result;
  };
};

export const deleteMerchant = (payload, id) => {
  return async function (dispatch) {
    const result = await PatchWithToken(`/merchants/${id}`, payload);
    return result;
  };
};

export const updateMerchantByMercnahtId = (id, payload) => {
  return async function (dispatch) {
    const result = await PutWithToken(`/merchants/${id}`, payload);
    return result;
  };
};

export const sendEmailToMerchantToLogin = (payload) => {
  return async function (dispatch) {
    const result = await PostWithToken("/merchants/seller-onboarding", payload);
    return result;
  };
};

export const checkExistUser = (payload) => {
  return async function (dispatch) {
    const result = await PostWithToken("/merchants/validate-email", payload);
    return result;
  };
};

export const addNewBusinessMerchant = (payload) => {
  // const payload =
  return async function (dispatch) {
    const result = await PostWithToken(
      "/seller-auth/register-seller-business",
      payload
    );
    return result;
  };
};

export const getShopSettingMerchant = (id) => {
  // const payload =
  return async function (dispatch) {
    const result = await GetWithToken(`/shopsetting/${id}`);
    return result;
  };
};

export const searchCategoryList = (search) => {
  return async function (dispatch) {
    let url = "/categories/search";
    if (search) {
      url = `${url}?search=${encodeURIComponent(search) || ""}`;
    }
    const result = await GetWithToken(url);
    return result;
  };
};
export const searchMerchantList = (search,limit=200) => {
  return async function (dispatch) {
    let url = "/merchants/search?limit="+limit;
    if (search) {
      url = `${url}?search=${search}&limit=${limit}`;
    }
    const result = await GetWithToken(url);
    return result;
  };
};
export const searchBrandsList = (search) => {
  return async function (dispatch) {
    let url = "/brand";
    if (search) {
      url = `${url}?search=${search}`;
    }
    const result = await GetWithToken(url);
    return result;
  };
};
export const brandList = (search) => {
  return async function (dispatch) {
    const result = await Get(`/website/brand`);
    return result;
  };
};

export const createShopSettingMerchant = (id, data) => {
  // const payload =
  return async function (dispatch) {
    const result = await PatchWithToken(`/shopsetting/${id}`, data);
    return result;
  };
};

export const getMerchnatById = (id) => {
  // const payload =
  return async function (dispatch) {
    const result = await GetWithToken(`/merchants/${id}`);
    return result;
  };
};

export const getMerchantShopRating = (id) => {

  return async function (dispatch) {
    const result = await GetWithToken(`/merchants/shop-rating/${id}`);
    return result;
  };
};
