// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marketing_Promo_Official_Card{
    background-color:#F5F8FA !important;
    cursor: pointer;
    height: 100%;
    min-height: 22vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.marketing_Promo_Official_Card p {

    font-size: 15px;
    margin-bottom: 2px !important;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
    border-width: 1px;
    border-radius: 2px;
    padding: 2px;
}

.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) {
    border-width: 1px;
}`, "",{"version":3,"sources":["webpack://./src/static/css/marketingCss/marketing.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;AACA;;IAEI,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".marketing_Promo_Official_Card{\n    background-color:#F5F8FA !important;\n    cursor: pointer;\n    height: 100%;\n    min-height: 22vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n.marketing_Promo_Official_Card p {\n\n    font-size: 15px;\n    margin-bottom: 2px !important;\n}\n\n.e-input-group:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left) {\n    border-width: 1px;\n    border-radius: 2px;\n    padding: 2px;\n}\n\n.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) {\n    border-width: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
