// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-border {
  border: 2px solid gray;
  border-radius: 6px;
}

.add_brand_btn {
  background-color: black;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  border: none;
  height: 28px;
}
.Edit_Action_btn {
  border: none;
  border-radius: 3px;
}
.Delete_Action_btn {
  border: none;
  border-radius: 3px;
}

.Main_content_box_brand_SearchBtn {
  background-color: rgb(255, 255, 255);
}

#Brand_searchBtn {
  display: flex;
  width: 175px;
  justify-content: space-between;
}

#Brand_searchBtn button {
  border: none;
  outline: 0;
  padding-top: 4px;
  border-radius: 6px 0 0 6px;
  background: #f5f8fa;
  width: 35px;
}
#Brand_searchBtn input {
  border: none;
  outline: 0;
  width: 180px;

  border-radius: 0 6px 6px 0;
  background-color: rgb(185, 176, 176);
  background: #f5f8fa;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
}
.img_brand_SearchBtn {
  margin-bottom: 3px;
}
/* .btns_Search_Addbrand{
  
} */
`, "",{"version":3,"sources":["webpack://./src/static/css/productCss/ProductBrand.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,YAAY;EACZ,YAAY;AACd;AACA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,gBAAgB;EAChB,0BAA0B;EAC1B,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,YAAY;EACZ,UAAU;EACV,YAAY;;EAEZ,0BAA0B;EAC1B,oCAAoC;EACpC,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,qBAAqB;AACvB;AACA;EACE,kBAAkB;AACpB;AACA;;GAEG","sourcesContent":[".slider-border {\n  border: 2px solid gray;\n  border-radius: 6px;\n}\n\n.add_brand_btn {\n  background-color: black;\n  color: white;\n  border-radius: 4px;\n  font-size: 14px;\n  outline: none;\n  border: none;\n  height: 28px;\n}\n.Edit_Action_btn {\n  border: none;\n  border-radius: 3px;\n}\n.Delete_Action_btn {\n  border: none;\n  border-radius: 3px;\n}\n\n.Main_content_box_brand_SearchBtn {\n  background-color: rgb(255, 255, 255);\n}\n\n#Brand_searchBtn {\n  display: flex;\n  width: 175px;\n  justify-content: space-between;\n}\n\n#Brand_searchBtn button {\n  border: none;\n  outline: 0;\n  padding-top: 4px;\n  border-radius: 6px 0 0 6px;\n  background: #f5f8fa;\n  width: 35px;\n}\n#Brand_searchBtn input {\n  border: none;\n  outline: 0;\n  width: 180px;\n\n  border-radius: 0 6px 6px 0;\n  background-color: rgb(185, 176, 176);\n  background: #f5f8fa;\n  font-family: Helvetica Neue;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 17px;\n  letter-spacing: 0.5px;\n}\n.img_brand_SearchBtn {\n  margin-bottom: 3px;\n}\n/* .btns_Search_Addbrand{\n  \n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
