
import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// import rating_stars from "../../../language"

import SelectInput from '../../../../components/select';
import { startLoader, stopLoader } from '../../../../lib/global';
import { breadcrumbAction } from '../../../../redux/actions/config.action';
import { getProductsBymerchantId } from '../../../../redux/actions/product.action';
import { getAllCategoryForSearch } from '../../../../redux/actions/category.action';
import { TabPanel } from "../../../../components/Tabs/TabPannel";
import ShopRatingAll from './ShopRatingAll';

import { getMerchantShopRating } from '../../../../redux/actions/merchant-center.action';
import ablity from "../../../../helpers/abilityGuard";




const initialSearchData = {
    productName: "",
    variationName: "",
    userName: "",
    reviewName:"",
    ratingStars:""
}

const  rating_stars=[
   {label:"0",value:"0"},
   {label:"1",value:"1"},
   {label:"2",value:"2"},
   {label:"3",value:"3"},
   {label:"4",value:"4"},
   {label:"5",value:"5"},

]


const RatingIndex = (props) => {
    const dispatch = useDispatch()
    const { mid, merchantName } = useParams()
    const [searchData, setSearchData] = useState(initialSearchData)
    const [shopRating, setShopRating] = useState()

    const [activeTab, setActiveTab] = useState("basicSetting");

    const [search,setSearch]=useState('')

    useEffect(() => {
        const breadcrumb = [
            { label: "Merchant Profile", url: "/merchant-center/profiles" },
            { label: merchantName, url: null }
        ];
        dispatch(breadcrumbAction(breadcrumb));
    }, [])

    const onChangeSearchDetail = (e, title, val) => {
        const key = title || e.target.name;
        const value = val || e.target.value;
        setSearchData((pre) => ({ ...pre, [key]: value }));
    };


    const searchDataFun = (data) => {
             setSearch(searchData)
    }

 
    const ResetSearchDataFun = () => {
       
    }

    const onSetRatting = (ratting) =>{
        setShopRating(ratting)
    }

    return (
        <Fragment>
            <div>
                <div className="mt-1 rounded bg-white">
                    <div className="col-12 p-0 row flex-wrap">
                        <div className="min-width-180 col-md-2">
                            <label>Product Name</label>
                            <input type="text"
                                className="form-control"
                                placeholder="Input"
                                name="productName"
                                // value={searchData.productName}
                                onChange={onChangeSearchDetail}
                            />
                        </div>
                        <div className="min-width-180 col-md-2">
                            <label>Variation Name</label>
                            <input type="text"
                                className="form-control"
                                placeholder="Input"
                                name="productName"
                                // value={searchData.productName}
                                onChange={onChangeSearchDetail}
                            />
                        </div>
                        <div className="min-width-180 col-md-2">
                            <label>Username</label>
                            <input type="text"
                                className="form-control"
                                placeholder="Input"
                                name="productName"
                                // value={searchData.productName}
                                onChange={onChangeSearchDetail}
                            />
                        </div>
                        <div className="min-width-180 col-md-2">
                            <label>Review Name</label>
                            <input type='date'
                                className="form-control"
                                placeholder="Date"
                                name="productName"
                                // value={searchData.productName}
                                onChange={onChangeSearchDetail}
                            />
                        </div>
                        <div className="col-md-2">
                            <label>Stars</label>
                            <SelectInput
                                options={rating_stars}
                                name="ratingStars"
                                value={searchData.ratingStars}
                                onChange={(e) => onChangeSearchDetail(e, "categoryId", e)}
                                formSubmitted={false}
                                placeholder="All"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <button onClick={ResetSearchDataFun}
                            type="button"
                            className="btn input-inside-text mr-1 btn-white border px-4">
                            Reset
                        </button>
                        <button
                            type="button"
                            className="btn input-inside-text mx-1 btn-secondary px-3"
                            onClick={() => searchDataFun()}
                        >
                            Search
                        </button>
                    </div>
                </div>
                <br />
                <hr />
                <br />
                <div>
                    <div className="px-1">
                        <div className='float-right px-2'>
                            <div className="text-secondary text-nowrap"><span className="bold fntSz28 px-2 text-dark">{shopRating?.avg}</span>/5</div>
                        </div>
                        <h4 className="bold m-0">Shop Rating</h4>
                        <p className="text-muted small">View merchant&apos;s shop rating</p>

                    </div>
                    <div className="d-flex px-1">
                        {ablity({model:'Merchant', action:'create'}) == 1 && (
                            <button
                                onClick={() => setActiveTab("basicSetting")}
                                className={`btn btn-${activeTab == "basicSetting" ? "dark" : "outline-dark"
                                    } btn-sm mr-3 px-4`}
                            >
                                All
                            </button>
                        )}
                        {ablity({model:'Merchant', action:'create'}) == 1 && (
                            <button
                                onClick={() => setActiveTab("chatSetting")}
                                className={`btn btn-${activeTab == "chatSetting" ? "dark" : "outline-dark"
                                    } btn-sm mr-3 px-4`}
                            >
                                To Reply
                            </button>
                        )}
                        {ablity({model:'Merchant', action:'create'}) == 1 && (
                            <button
                                onClick={() => setActiveTab("notificationSetting")}
                                className={`btn btn-${activeTab == "notificationSetting" ? "dark" : "outline-dark"
                                    } btn-sm mr-3 px-4`}
                            >
                                Replied
                            </button>
                        )}
                    </div>
                    <TabPanel
                        style={{ display: activeTab == "basicSetting" ? "block" : "none" }}
                        value={0} index={0}
                        costumStyle={{ padding: "0px" }}
                    >
                        <ShopRatingAll reply_status="ALL" storeId={mid} setRatting={onSetRatting} />
                    </TabPanel>
                    <TabPanel
                        style={{ display: activeTab == "chatSetting" ? "block" : "none" }}
                        value={1} index={1}
                        costumStyle={{ padding: "0px" }}
                    >
                        <ShopRatingAll reply_status="TOREPLY" storeId={mid} setRatting={onSetRatting} />
                    </TabPanel>
                    <TabPanel
                        style={{ display: activeTab == "notificationSetting" ? "block" : "none" }}
                        value={1} index={1}
                        costumStyle={{ padding: "0px" }}
                    >
                        <ShopRatingAll  reply_status="REPLY" storeId={mid} setRatting={onSetRatting} />
                    </TabPanel>
                </div>
            </div>



            <style jsx="true" >{`
                .shopRating_inputDiv{
                    margin: -26px; 
                }
                .min-width-180{
                    min-width: 180px !important;
                }
            `}

            </style>
        </Fragment>
    )
}

export default RatingIndex;