import {
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../static/css/orderCss/allOrder.css"
import { TabPanel } from "../../../components/Tabs/TabPannel";
import TeamPurchaseRetail from  "../../../pages/order/TeamPurchase/TeamPurchaseRetail"
import { useDispatch } from "react-redux";
import { getAllTeamPurchease, getTeamPurcheaseByStoreId } from "../../../redux/actions/order.action";
import { startLoader, stopLoader } from "../../../lib/global";
import InputField from "../../../components/formControl/input/InputField";
import { useParams } from "react-router-dom";
import { getLocalStorage } from "../../../lib/session";

const initialSearhData = {
  buyerName: "",
  merchantName: "",
  orderId: "",
  productName: ""
}

function MerchantTeamPurchase(props) {
  const [orderId, setOrderId] = useState('');
  const [buyerName, setBuyerName] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [productName, setProductName] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [total, setTotal] = useState([])
  const classes = "btn border mx-1"
  const dispatch = useDispatch()
  const [search,setSearch]= useState({})
  const param = useParams()
    const storeId = param.mid ? param.mid : getLocalStorage("storeId")
  const searchData = () => {
    setSearch({ buyerName, merchantName, productName, orderId });
};
const resetSearch = () => {
    setBuyerName('')
    setMerchantName('')
    setOrderId('')
    setProductName('')
    setSearch({ buyerName: "", merchantName: "", productName: "", orderId: "" });

};

  useEffect(() => {
      startLoader()
      if (!props.storeId){
      dispatch(getTeamPurcheaseByStoreId(storeId,{}))
          .then((data) => {
              console.log(data, "kkkkkkkkkkkk");
              setTotal(data?.data?.total || [])
              stopLoader()
          }).catch((error) => {
              console.log(error);
              stopLoader()
          })
      }else{
          dispatch(getTeamPurcheaseByStoreId(props.storeId,{teamStatus:props.teamStatus,...props.search}))
          .then((data) => {
              console.log(data, "kkkkkwwwwwwwwwwwwkkkkkkk");
              setTotal(data?.data?.total || [])
              stopLoader()
          }).catch((error) => {
              console.log(error);
              stopLoader()

          })
      }
  },[props.search])
  return (
      <React.Fragment>
 <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
                <div className="col-11 row flex-wrap">
                    <div className="min-width-200 col-md-3">
                        <label>Order ID</label>
                        <InputField type="text"
                            className="form-control"
                            placeholder="Input"
                            id="storeId"
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                        />
                    </div>
                    <div className="min-width-200 col-md-3">
                        <label>Buyer Name</label>
                        <InputField type="text"
                            className="form-control"
                            placeholder="Input"
                            id="merchantName"
                            value={buyerName}
                            onChange={(e) => setBuyerName(e.target.value)}
                        />
                    </div>
                    <div className="min-width-200 col-md-3">
                        <label>Merchant Name</label>
                        <InputField type="text"
                            className="form-control"
                            placeholder="Input"
                            id="storeId"
                            value={merchantName}
                            onChange={(e) => setMerchantName(e.target.value)}
                        />
                    </div>
                    <div className="min-width-200 col-md-3">
                        <label>Product Name</label>
                        <InputField type="text"
                            className="form-control"
                            placeholder="Input"
                            id="merchantName"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                        />
                    </div>
                
                    <div className="min-width-200 mt-3 col-md-3">
                        <label>Order Date</label>
                        <InputField type="date"
                            className="form-control"
                            placeholder="Input"
                            id="orderDate"
                            // value={productName}
                            disabled
                            // onChange={(e) => setProductName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <button
                        type="button"
                        className="btn input-inside-text mr-1 btn-white border px-4"
                        onClick={() => resetSearch()}
                    >
                        Reset
                    </button>
                    <button
                        type="button"
                        className="btn input-inside-text mx-1 btn-secondary px-3"
                        onClick={() => searchData()}
                    >
                        Search
                    </button>
                </div>
            </div>
            <br />
            <br />


          <div className="m-4 card p-4">
              <div >
                  Team Purchase
              </div>
              <br/>
              <div >
                  <button
                      onClick={() => { setTabIndex(0) }}

                      autocomplete="off"
                      className={`mr-1 fntSz14 ${tabIndex == 0 ? "btn btn-danger" : "btn btn-outline-danger"}`}
                      data-bs-toggle="button"
                  >
                      {`TP Retail(${total?.tp_retails || 0})`}
                  </button>
                  <button
                      onClick={() => { setTabIndex(1) }}

                      autocomplete="off"
                      className={`mr-1 fntSz14 ${tabIndex == 1 ? "btn btn-danger" : "btn btn-outline-danger"}`}
                      data-bs-toggle="button"
                  >
                      {`TP Flash Sale(${total?.tp_flash_sale || 0})`}
                  </button>
                  <button
                      onClick={() => { setTabIndex(2) }}

                      autocomplete="off"
                      className={`mr-1 fntSz14 ${tabIndex == 2 ? "btn btn-danger" : "btn btn-outline-danger"}`}
                      data-bs-toggle="button"
                  >
                      {`TP Roadshow(${total?.tp_road_show || 0})`}
                  </button>
                  <button
                      onClick={() => { setTabIndex(3) }}

                      autocomplete="off"
                      className={`mr-1 fntSz14 ${tabIndex == 3 ? "btn btn-danger" : "btn btn-outline-danger"}`}
                      data-bs-toggle="button"
                  >

                      {`Completion(${total?.completion || 0})`}
                  </button>
                  <button
                      onClick={() => { setTabIndex(4) }}

                      autocomplete="off"
                      className={`mr-1 fntSz14 ${tabIndex == 4 ? "btn btn-danger" : "btn btn-outline-danger"}`}
                      data-bs-toggle="button"
                  >
                      {`Void(${total?.void || 0})`}
                  </button>
              </div>
              <TabPanel value={tabIndex} index={0} costumStyle={{ padding: "0px" }} >
                  <TeamPurchaseRetail
                      totalItem={total.tp_retails}
                      storeId={props.storeId}
                      search={props.search}
                      teamStatus="TPRETAILS"

                  />
              </TabPanel>
              <TabPanel value={tabIndex} index={1} costumStyle={{ padding: "0px" }}>
                  <TeamPurchaseRetail
                      totalItem={total.tp_flash_sale}
                      storeId={storeId}
                      // search={props.search}
                      teamStatus="TPFLASHSALE"

                  />
              </TabPanel>
              <TabPanel value={tabIndex} index={2} costumStyle={{ padding: "0px" }}>
                  <TeamPurchaseRetail
                      totalItem={total.tp_road_show}
                      storeId={storeId}
                      // search={props.search}
                      teamStatus="TPROADSHOW"

                  />
              </TabPanel>
              <TabPanel value={tabIndex} index={3} costumStyle={{ padding: "0px" }}>
                  <TeamPurchaseRetail
                      totalItem={total.completion}
                      storeId={storeId}
                      // search={props.search}
                      teamStatus="COMPLETION"

                  />
              </TabPanel>
              <TabPanel value={tabIndex} index={4} costumStyle={{ padding: "0px" }}>
                  <TeamPurchaseRetail
                      totalItem={total.void}
                      storeId={storeId}
                      // search={props.search}
                      teamStatus="VOID"
                  />
              </TabPanel>
             

          </div>

      </React.Fragment>

  );
}

export default MerchantTeamPurchase
