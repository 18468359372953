import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Image from "../../../components/Images/Image";
import SvgIcon from "../../../components/Images/SvgIcon";
import { TabPanel } from "../../../components/Tabs/TabPannel";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import "../../../static/css/merchantCss/merchent_center.css";
import BroadcastManagementCreate from "./BroadcastManagementCreate";
import BroadcastManagementDraft from "./BroadcastManagementDraft";
import ablity from "../../../helpers/abilityGuard";
import { AllowAccess } from "../../../lib/global";

// import "../../../static"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MerchantBroadcastManagement() {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const [Index, setIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();


  useEffect(() => {
    const breadcrumb = [{ label: "Merchant Broadcast", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, []);
  const sendbroadcastMsg = (status) => {
    history.push("/merchant-send-broadcast-massage",status);
  };
  return (
    <React.Fragment>
      <div className="broadcast_management_main_container">
        <div className="py-3 px-3 rounded">
          <div className="row ">
            <div className="col-xl-4 p-1 ">
              <div className=" card p-3 mr-1">
                <p className="broadcast_management_blog_para1">All Merchant</p>
                <p className="broadcast_management_blog_para2">
                  To engage all the merchant
                </p>
                <br />
                {ablity({model:'Merchant', action:'create'}) == 1 && (
                    <button
                      className="btn btn-danger w-30 text-nowrap "
                      onClick={() => sendbroadcastMsg("ALL")}
                    >
                      Create
                    </button>
                )}
              </div>
            </div>

            <div className="col-xl-4 p-1 ">
              <div className=" card p-3 mr-1">
                <p className="broadcast_management_blog_para1">
                  Active Merchant
                </p>
                <p className="broadcast_management_blog_para2">
                  To engage only active merchant
                </p>
                <br />
                {ablity({model:'Merchant', action:'create'}) == 1 && (
                    <button
                      className="btn btn-danger  w-30 text-nowrap "
                      onClick={() => sendbroadcastMsg("ACTIVE")}
                    >
                      Create
                    </button>
                )}
              </div>
            </div>
            <div className="col-xl-4 p-1 ">
              <div className=" card p-3 mr-1">
                <p className="broadcast_management_blog_para1">Blocked</p>
                <p className="broadcast_management_blog_para2">
                  To engage with blocked merchant
                </p>
                <br />
                {ablity({model:'Merchant', action:'create'}) == 1 && (
                    <button
                      className="btn btn-danger  w-30 text-nowrap "
                      onClick={() => sendbroadcastMsg("BLOCKED")}
                    >
                      Create
                    </button>
                )}
              </div>
            </div>
            <div className="col-xl-4 p-1 ">
              <div className=" card p-3 mr-1">
                <p className="broadcast_management_blog_para1">Closed</p>
                <p className="broadcast_management_blog_para2">
                  To engage with closed merchant
                </p>
                <br />
                {ablity({model:'Merchant', action:'create'}) == 1 && (
                    <button
                      className="btn btn-danger  w-30 text-nowrap "
                      onClick={() => sendbroadcastMsg("CLOSED")}
                    >
                      Create
                    </button>
                )}
              </div>
            </div>
            <div className="col-xl-4 p-1 ">
              <div className=" card p-3 mr-1">
                <p className="broadcast_management_blog_para1">Specified MID</p>
                <p className="broadcast_management_blog_para2">
                  To engage with specified MID
                </p>
                <br />
                {ablity({model:'Merchant', action:'create'}) == 1 && (
                    <button
                      className="btn btn-danger  w-30 text-nowrap "
                      onClick={() => sendbroadcastMsg('MERCHANT')}
                    >
                      Create
                    </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container_body container-fluid">
          <div className="card p-3">
            <span>BROADCAST HISTORY</span>
            <div className=" border card  my-3">
              <Tabs
                value={tabIndex}
                onChange={(e, value) => setTabIndex(value)}
                variant="scrollable"
                orientation="horizontal"
                scrollButtons={false}
                visibleScrollbar={false}
                className="px-3"
                aria-label="basic tabs example"
              >
                <Tab label="Created" {...a11yProps(0)} />
                <Tab label="Draft" {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={tabIndex} index={0}>
                <BroadcastManagementCreate />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <BroadcastManagementDraft />
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MerchantBroadcastManagement;
