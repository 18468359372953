import {
    IconButton,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
  } from "@mui/material";
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import ExpandLessIcon from "@mui/icons-material/ExpandLess";
  import React, { useEffect, useRef, useState } from "react";
  import { useDispatch } from "react-redux";
  import { useHistory } from "react-router-dom";
  import SvgIcon from "../../components/Images/SvgIcon";
  import { breadcrumbAction } from "../../redux/actions/config.action";
  import {
    getAllOutletApi
  } from "../../services/outlet.service";
  import "../../static/css/productCss/ProductBrand.css";
  import {
    AllowAccess,
    open_dialog,
    showToast,
    startLoader,
    stopLoader,
  } from "../../lib/global";
  import { DELETE_OUTLET_MERCHANT } from "../../lib/constant";
  import PagenationDropdown from "../../components/DropDown/pagenationDropdown";
  import ablity from "../../helpers/abilityGuard";
  
  const initialSearchData = {
    search: "",
  };
  
  const MerchantOutletListing = () => {
    AllowAccess(["MERCHANT"]);
    const history = useHistory();
    const dispatch = useDispatch();
    const [outletData, setOutletData] = useState([]);
    const [searchData, setSearchData] = useState(initialSearchData);
    const [totalOutlets, setTotalOutlets] = useState(0);
  
    const [pageNo, setPageNo] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const limit = useRef(10);
    const [offset, setOffset] = useState(0);
  
    useEffect(() => {
      const breadcrumb = [{ label: "Merchant Outlet", url: null }];
      dispatch(breadcrumbAction(breadcrumb));
      startLoader();
      // const data= {...searchData }
  
      let filteringdata;
      if (pageLimit != limit.current) {
        limit.current = pageLimit;
        setSearchData(initialSearchData);
        setPageNo(1);
        setOffset(0);
        filteringdata = {
          limit: pageLimit,
        };
      } else {
        filteringdata = {
          ...searchData,
          offset,
          limit: pageLimit,
        };
      }
      apiData(filteringdata);
    }, [offset, pageLimit, searchData]);
  
    const apiData = (filteringdata) => {
      dispatch(getAllOutletApi(filteringdata))
        .then((data) => {
          setTimeout(() => {
            stopLoader();
          }, 1000);
          if (data && data.data && data.data.status === 200) {
            setOutletData(
              data &&
                data.data &&
                data.data.data.map((row, index) => {
                  return {
                    outletName: row?.outletName || "N/A",
                    addressLine1: row?.addressLine1 || 0,
                    addressLine2: row?.addressLine2 || 0,
                    postalCode: row?.postalCode || 0,
                    state: row?.state || "N/A",
                    id: row?.id || "",
                  };
                })
            );
            setTotalOutlets(
              data && data.data && data.data.data && data.data.totalOutlet
            );
          }
        })
        .catch((error) => {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        });
    };
  
    const handlePageChange = (event, value) => {
      setOffset(value - 1);
      setPageNo(value);
    };
  
    const getOutletDataAfterDelete = (data, totalOutlet) => {
      setTotalOutlets(totalOutlet);
      setOutletData(data);
    };
    const DeleteOutletById = (id) => {
      open_dialog(DELETE_OUTLET_MERCHANT, {
        onSubmit: getOutletDataAfterDelete,
        id,
      });
    };
  
    const addOutletFun = () => {
      history.push("/merchant/outlet/add");
    };
  
    const EditOutletfun = (id) => {
      history.push(`outlet/add/${id}`);
    };
  
    const onChangeSeachData = (data) => {
      setSearchData(data);
    };
  
    const handleSorting = (item, sortType, isDate = false) => {
      startLoader();
      try {
        if (typeof outletData[0][item] === "number") {
          let sortedData = outletData.sort((a, b) => {
            if (sortType == "asc") {
              return a[item] - b[item];
            } else if (sortType == "desc") {
              return b[item] - a[item];
            }
          });
          setTimeout(() => {
            setOutletData([...sortedData]);
            stopLoader();
          }, [1000]);
        } else if (typeof outletData[0][item] === "string" && !isDate) {
          let sortedData = outletData.sort((a, b) => {
            let fa = a[item].toLowerCase(),
              fb = b[item].toLowerCase();
            if (sortType == "asc") {
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            } else if (sortType == "desc") {
              if (fa < fb) {
                return 1;
              }
              if (fa > fb) {
                return -1;
              }
              return 0;
            }
          });
         
          setTimeout(() => {
            setOutletData([...sortedData]);
            stopLoader();
          }, [1000]);
        } else if (typeof outletData[0][item] === "string" && isDate) {
          let sortedData = outletData.sort((a, b) => {
            let da = new Date(a[item]);
            let db = new Date(b[item]);
  
            if (sortType == "asc") {
              return da - db;
            } else if (sortType == "desc") {
              return db - da;
            }
          });
          setTimeout(() => {
            setOutletData([...sortedData]);
            stopLoader();
          }, [1000]);
        }
      } catch (error) {
        console.log({ error });
        stopLoader();
      }
    };
  
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="card px-4 py-3 bg-body bg-white rounded shadow-sm">
            <span>OUTLET LISTING</span>
            <div className="border card p-3 my-3">
              <div className="d-flex justify-content-between">
                <span>{`All Outlets (${totalOutlets})`}</span>
                <div className="d-flex my-3">
                  <input
                    type="search"
                    placeholder="search"
                    className="form-control mx-3"
                    value={searchData?.search || ""}
                    onChange={(e) =>
                      onChangeSeachData({
                        ...searchData,
                        search: e.target.value,
                      })
                    }
                  />
  
                  {ablity({model:'Product', action:'create'}) == 1 && (
                      <button
                        onClick={() => addOutletFun()}
                        type="button"
                        className="btn btn-dark btn-sm text-nowrap mx-3"
                      >
                        {" "}
                        + Add Outlet
                      </button>
                  )}
                </div>
              </div>
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="th_cell" component="th">
                        No
                      </TableCell>

                      <TableCell className="th_cell" component="th" align="left">
                        <div className="d-flex  ">
                          <div>Outlet Name</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "ASC" })}
                              onClick={() => handleSorting("outletName", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "DESC" })}
                              onClick={() => handleSorting("outletName", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Address 1</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("addressLine1", "asc")}
  
                              // onClick={() => getApiData({ item: "category", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("addressLine1", "desc")}
  
                              // onClick={() => getApiData({ item: "category", order: "DESC" })}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Address 2</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("addressLine2", "asc")}
  
                              // onClick={() => getApiData({ item: "product", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("addressLine2", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div> Postal Code</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("postalCode", "asc")
                              }
  
                              // onClick={() => getApiData({ item: " releventMerchant", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("postalCode", "desc")
                              }
  
                              // onClick={() => getApiData({ item: " releventMerchant", order: "DESC" })}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="th_cell" component="th" align="left">
                        <div className="d-flex  ">
                          <div> State</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("state", "asc")}
  
                              // onClick={() => getApiData({ item: "  status", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("state", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
  
                      <TableCell className="table_action_head" component="th">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!outletData?.length && (
                       <TableRow>
                        <TableCell align="center" className="td_cell">
                          <div className="d-flex justify-content-center">
                            <h3 className="text-muted m-auto">No data found!</h3>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}

                    {outletData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className="td_cell">
                          {offset * pageLimit + (index + 1)}
                        </TableCell>
                        <TableCell align="left" className="td_cell capitalize">
                          {row.outletName || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.addressLine1 || "0"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.addressLine2 || "0"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.postalCode || "0"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.state || "N/A"}
                        </TableCell>
  
                        <TableCell className="d-flex justify-content-between table_action_item">
                          {ablity({model:'Product', action:'update'}) == 1 && (
                              <IconButton
                                className="pointer"
                                onClick={() => EditOutletfun(row.id)}
                              >
                                <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                              </IconButton>
                          )}
  
                          {ablity({model:'Product', action:'delete'}) == 1 && (
                            <IconButton
                              className="pointer"
                              onClick={() => DeleteOutletById(row.id)}
                            >
                              <SvgIcon
                                src="/icons/delete_icon.svg"
                                alt="delete icon"
                              />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    
                  </TableBody>
                </Table>
              </TableContainer>
  
              <br />
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <PagenationDropdown setPageQuant={setPageLimit} />
  
                <div className="float-right mb-1">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil((totalOutlets || 0) / pageLimit)}
                      shape="rounded"
                      size="small"
                      page={pageNo}
                      onChange={handlePageChange}
                    />
                  </Stack>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  
  export default MerchantOutletListing;
  