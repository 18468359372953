import React, { useState,useEffect } from "react";
import "../../../../../static/css/deleteModal.css";
import reactTable from "../../../../../components/reactTable/reactTable";
import CloseIcon from "@mui/icons-material/Close";
import ReactTable from 'react-table';
import {
  deleteMerchant,
  getAllMerchantListAfterDelete,
} from "../../../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import { showToast, startLoader, stopLoader } from "../../../../../lib/global";
import Image from "../../../../../components/Images/Image";

const initialData = [
  {
    id:'7f48bcb4-bd83-4bbd-8c02-f0429dabd350',
    voucherName: "RM 5.0 OFF with min. spend of RM 50.0",
    voucherCode: "ADKLXNCMZ",
    voucherType: "Streaming Exclusive",
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    remain:30,
    status: 0
  },
  {
    id:'f984ecba-e8a6-41d5-8056-8e62cbd32985',
    voucherName: "RM 3.0 OFF with min. spend of RM 50.0",
    voucherCode: "SLKXNCNZ",
    voucherType: "Streaming Exclusive",
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    status: 1
  }
];
const initialData2 = [
  {
    id:'7f48bcb4-bd83-4bbd-8c02-f0429dabd350',
    voucherName: "RM 5.0 OFF with min. spend of RM 50.0",
    voucherCode: "ADKLXNCMZ",
    voucherType: "Streaming Exclusive",
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    remain:30,
    status: 0
  },
  {
    id:'f984ecba-e8a6-41d5-8056-8e62cbd32985',
    voucherName: "RM 3.0 OFF with min. spend of RM 50.0",
    voucherCode: "SLKXNCNZ",
    voucherType: "Streaming Exclusive",
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    status: 1
  }
];

const GoLiveShowPromoDialog = (props) => {
  const [uu2VoucherData, setuu2VoucherData] = useState(initialData);
  const [shopVoucherData, setshopVoucherData] = useState(initialData2);
  const STATUS_SHOW = 1;
  const STATUS_REMOVE = 0;
  const dispatch = useDispatch();
  const deleteFun = () => {
    const data = {
      status: "DELETED",
    };
    startLoader();
    const response = dispatch(deleteMerchant(data, props.id));
    response.then((data) => {
      if (data.data.status == 200) {
        const response = dispatch(getAllMerchantListAfterDelete());
        response
          .then((data) => {
            if (data && data.data && data.data.status === 200) {
              setTimeout(() => {
                stopLoader();
                showToast(data.data.message, "success");
                props.onSubmit([...data.data.data]);
                props.onClose();
              }, 1000);
            } else {
              stopLoader();
              showToast("Internal Server Error", "error");
              props.onClose();
            }
          })
          .catch((error) => {
            console.log({ error });
          });
      } else {
        stopLoader();
        showToast("Internal Server Error", "error");
      }
    });
  };
  function statusButton(status,id,type) {
    if (status == 1) {
      return <button className='btn btn-outline-dark' onClick={()=>changeStatus(STATUS_REMOVE,id,type)}>Show</button>;
    } else {
      return <button className='btn btn-outline-dark' onClick={()=>changeStatus(STATUS_SHOW,id,type)}>Remove</button>;
    }
  }

  function changeStatus(status,id,type){
    console.log('change status')
    let temp = []
    if(type == 'uu2'){
      temp = [...uu2VoucherData];
      // temp = uu2VoucherData;
    }else{
      temp = [...shopVoucherData];

    }
    temp.forEach((item)=>{
      if(item.id == id){
        item.status = status;
      }
    })
    if(type == 'uu2'){
      setuu2VoucherData(temp);
    }else{
      setshopVoucherData(temp);
    }
  }

  function showRemainStatus(remain,status) {
    if (status == 0) {
      let counter = 30
      // useEffect(() => {
      //   counter > 0 && setInterval(() => counter-1, 1000);
      // }, []);
      console.log(counter)
      return <span className='badge bg-danger text-white'>Showing {counter}s</span>;
    }
  }
  return (
    <React.Fragment>
      <div>
        <div className="d-flex flex-row align-items-center justify-content-between px-3">
          <p className="mb-0 h4">Show Voucher</p>
          <button
            onClick={props.onClose}
            className="btn fntSz24 text-secondary"
          >
            x
          </button>
        </div>
        <div className="card m-2">
          <div className="h6 ml-2">UU2 Voucher</div>
          <div
            style={{ maxHeight: "30vh", overflow: "auto" }}
            className="fntSz14 position-relative px-2"
          >
            <table className="table border-0">
              <thead className="position-sticky" style={{ top: "0" }}>
              <tr className="bg-light rounded-lg text-secondary">
                <th className="border-0">
                  voucher(s)
                </th>
                <th className="border-0">
                  voucher code
                </th>
                <th className="border-0">
                  valid until
                </th>
                <th className="border-0">
                  Status
                </th>
              </tr>
              </thead>
              <tbody>
              {uu2VoucherData &&
                uu2VoucherData.length > 0 &&
                uu2VoucherData.map((row, index) => (
                  <tr key={row.id}>
                    <td className="border-0">
                      <span>{row.voucherName}</span>
                      <span className="badge badge-danger text-left">{row.voucherType}</span>
                    </td>
                    <td className=" align-middle border-0">
                      {row.voucherCode}
                    </td>
                    <td className="align-middle border-0 float-right">
                      {row.endDate}
                    </td>
                    <td>
                      {statusButton(row.status,row.id,'uu2')}
                      {showRemainStatus(row.remain,row.status)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card m-2">
          <div className="h6 ml-2">Shop Voucher</div>
          <div
            style={{ maxHeight: "30vh", overflow: "auto" }}
            className="fntSz14 position-relative px-2"
          >
            <table className="table border-0">
              <thead className="position-sticky" style={{ top: "0" }}>
              <tr className="bg-light rounded-lg text-secondary">
                <th className="border-0">
                  voucher(s)
                </th>
                <th className="border-0">
                  voucher code
                </th>
                <th className="border-0 float-right">
                  valid until
                </th>
                <th className="border-0">
                  Status
                </th>
              </tr>
              </thead>
              <tbody>
              {shopVoucherData &&
                shopVoucherData.length > 0 &&
                shopVoucherData.map((row, index) => (
                  <tr key={row.id}>
                    <td
                      className="border-0"
                    >
                      <span>{row.voucherName}</span>
                      <span className="badge badge-danger text-left">{row.voucherType}</span>
                    </td>
                    <td className=" align-middle border-0">
                      {row.voucherCode}
                    </td>
                    <td className="align-middle border-0 float-right">
                      {row.endDate}
                    </td>
                    <td>
                      {statusButton(row.status,row.id,'shop')}
                      {showRemainStatus(row.remain,row.status)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <style jsx="true">{`
          .card-body {
            min-height: 50vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
          .flash_sale_product_image {
            width: 45px;
            height: 40px;
            border-radius: 3px;
          }
          input {
            cursor: pointer;
          }
        `}</style>
      </div>
    </React.Fragment>
  );
};

export default GoLiveShowPromoDialog;
