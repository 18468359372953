import { IconButton, Pagination, Stack, TablePagination } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Image from "../../../../components/Images/Image";
import SvgIcon from "../../../../components/Images/SvgIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useHistory } from "react-router-dom";
import { getRecieptOrderWithhTeam } from "../../../../redux/actions/finance.action";
import { useDispatch } from "react-redux";
import { AllowAccess, startLoader, stopLoader } from "../../../../lib/global";
import PagenationDropdown from "../../../../components/DropDown/pagenationDropdown";
import ablity from "../../../../helpers/abilityGuard";

const initial = [
  {
    buyerName: "smeteria mangoosa",
    merchantName: "charli serama",
    memberFulfilled: "2/2",
    settlementStatus: "settled",
  },
  {
    buyerName: "smeteria mangoosa",
    merchantName: "charli serama",
    memberFulfilled: "2/2",
    settlementStatus: "settled",
  },
  {
    buyerName: "smeteria mangoosa",
    merchantName: "charli serama",
    memberFulfilled: "2/2",
    settlementStatus: "settled",
  },
  {
    buyerName: "smeteria mangoosa",
    merchantName: "charli serama",
    memberFulfilled: "2/2",
    settlementStatus: "settled",
  },
];

const RetailTP = (props) => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState({});

  const [allOrderReceiptData, setAllOrderReceiptData] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});

  const viewAction = (id) => {
    history.push(`/finance/order-receipt/group-id/${id}`);
  };

  useEffect(() => {
    let filteringData;
    if (pageLimit != limit.current) {
      console.log("limit");
      limit.current = pageLimit;
      props.reset();
      setPageNo(1);
      setOffset(0);
      filteringData = {
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== props.search) {
      console.log("opopopopoppop");
      setPageNo(1);
      setOffset(0);
      filteringData = {
        ...props.search,
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = props.search;
    } else {
      console.log("limit23232q32323");

      filteringData = {
        ...props.search,
        status: props.status,
        offset,
        limit: pageLimit,
      };
    }
    console.log(filteringData, "pkjj........ooo");
    apiData(filteringData);
  }, [offset, pageLimit, props.search]);

  const apiData = (filteringData) => {
    startLoader();
    dispatch(getRecieptOrderWithhTeam(filteringData))
      .then((data) => {
        console.log(data?.data?.data);
        // props.setTotals(data.data.total || {})

        // setAllOrderReceiptData(data?.data?.data);

        setAllOrderReceiptData(
          data &&
            data.data &&
            data.data.data.map((row, index) => {
              return {
                productName: row?.productName || "N/A",
                teamLeader: row?.teamLeader || "N/A",
                merchantName: row?.merchantName || "N/A",
                productName: row?.productName || "N/A",
                refId: row?.payment?.RefNo || "N/A",
                orderId: row?.orderId || "N/A",

                productImages:
                  row?.order?.productImages || "/images/noImage.png",
                productVariation:
                  (row?.order?.variation &&
                    row?.order?.variation?.length > 0 &&
                    row?.order?.variation
                      ?.map((Item, index) => Item?.value)
                      .toString()) ||
                  " N/A",

                activeMembers: row?.activeMembers?.length || 0,
                totalMembersRequired: row?.totalMembersRequired || 0,
                price: row?.price || 0,
                createdAt: row?.createdAt,
                updatedAt: row?.updatedAt,
                teamId: row?.teamId || "N/A",
                currencyName: row?.currencyName || "RM",
                price: row?.price || 0,
                rating: row?.rating || 0,
                status: row?.status || "N/A",
                settlementStatus: row?.settlementStatus || "N/A",

                // settlementStatus: row?.requireStatus == "PENDING"
                //   ? "To Settle"
                //   : "Settled" || "N/A"
              };
            })
        );
        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log(error);
      });
  };
  const handlePageChange = (event, value) => {
    console.log(value, "gggggggggggggggggggggggggggggggg");
    // setSearchData({ ...searchData, offset: value - 1 });
    // refOffset(value - 1)
    setOffset(value - 1);
    setPageNo(value);
  };

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof allOrderReceiptData[0][item] === "number") {
        let sortedData = allOrderReceiptData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setAllOrderReceiptData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof allOrderReceiptData[0][item] === "string" && !isDate) {
        let sortedData = allOrderReceiptData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        console.log(sortedData, "llllllllll");
        setTimeout(() => {
          setAllOrderReceiptData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof allOrderReceiptData[0][item] === "string" && isDate) {
        let sortedData = allOrderReceiptData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          console.log(da, db);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setAllOrderReceiptData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader();
    }
  };

  return (
    <React.Fragment>
      <div>
        <div className="allTeamPurchaseMain">
          <div
            style={{ width: "22rem" }}
            className="tableheadTeamPurchaseProduct teamPurchase_borderLine col-5"
          >
            <div className="d-flex  ">
              <div>Product</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("productName", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("productName", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Member Fulfilled</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("activeMembers", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("activeMembers", "desc")}
                />
              </div>
            </div>
          </div>

          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Settlement Status </div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("settlementStatus", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("settlementStatus", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="tableheadfix tableheadfix_allorder teamPurchase_borderLine">
            Action
          </div>
          {!allOrderReceiptData.length ? (
            <div>No data found</div>
          ) : (
            <>
              {allOrderReceiptData.map((item, index) => (
                <div className="info">
                  <div className="teamPurchase-orderId-detail-maindiv fntSz15">
                    <div className="justify-content-between d-flex teamPurchase-orderId-detail-section">
                      <div className="teamPurchase_orderId_Detail_Name w-100 m-0 pl-1 text-capitalize">
                        <p>Buyer: {item?.teamLeader || "N/A"}</p>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                      <div className="teamPurchase_orderId_Detail_brand mx-5 text-capitalize">
                        <p>Merchant: {item?.merchantName || "N/A"}</p>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                    </div>
                    <div className="teamPurchase-orderId-detail-indexId pr-2">
                      <div className="d-flex flex-row flex-nowrap">
                        <div className="px-5">
                          Ref ID: {item?.refId || "N/A"}
                        </div>
                        <div> Order ID: {item?.orderId || "N/A"} </div>
                      </div>
                    </div>
                  </div>

                  <div className="teamPurchase_tablehead_details_head">
                    <div
                      style={{ width: "22rem" }}
                      className="tableheadTeamPurchaseProduct"
                      // onClick={() => viewAction(item.orderId)}
                    >
                      <div className="d-flex text-capitalize">
                        <Image
                          src={item?.productImages || "/images/noImage.png"}
                          alt={"photo"}
                          className="teamPurchase_page_product_image"
                        />
                        <div className=" mx-2 ">
                          <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                            {item?.productName || "N/A"}{" "}
                          </div>
                          <div className="teamPurchase_Page_ProductBox_Product_varient text-capitalize">
                            Variation: {item?.productVariation || " N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-center">{`${
                          item?.activeMembers || 0
                        }/${item?.totalMembersRequired || 0}`}</div>
                        {/* <div className="teamPurchase_Page_ProductBox_Product_varient">Online Banking</div> */}
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-center">
                          {item?.settlementStatus || "N/A"}
                        </div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                      </div>
                    </div>
                    <div className="tableheadfix tableheadfix_allorder">
                      <div className="d-flex justify-content-center ">
                        <div>
                          {ablity({model:'Finance', action:'read'}) == 1 && (
                              <IconButton
                                className="pointer"
                                onClick={() => viewAction(item?.teamId)}
                              >
                                <SvgIcon
                                  src="/icons/view_icon.svg"
                                  alt="view icon"
                                />
                              </IconButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-between align-items-center">
        <PagenationDropdown setPageQuant={setPageLimit} />

        <div className="float-right mb-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil((props?.totalItem || 0) / pageLimit)}
              shape="rounded"
              size="small"
              page={pageNo}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
};

export default RetailTP;
