import { MoodBad } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../components/Images/Image";
import { MAX_IMAGE_SIZE } from "../../lib/config";
import {
  AllowAccess,
  showToast,
  startLoader,
  stopLoader,
  UploadImage,
} from "../../lib/global";
import {
  createNewBanner,
  getBannerById,
  updateBannerById,
} from "../../redux/actions/banner.action";
import { breadcrumbAction } from "../../redux/actions/config.action";

function AddBanner() {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const { bannerId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [webImageFile, setWebImageFile] = useState();
  const [webImageUrl, setWebImageUrl] = useState();
  const [mobileImageFile, setMobileImageFile] = useState();
  const [mobileImageUrl, setMobileImageUrl] = useState();
  const [title, setTitle] = useState();
  const [linkedWith, setLinkedWith] = useState();

  

  useEffect(() => {
    const breadcrumb = [
      { label: "Banners", url: "/Banner" },
      { label: bannerId ? "Edit Banner" : "Add Banner", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    if (bannerId) {
      dispatch(getBannerById(bannerId))
        .then((data) => {
          console.log(data, "banner data by id");
          setWebImageUrl(data?.data?.data?.webImage);
          setMobileImageUrl(data?.data?.data?.mobileImage);
          setLinkedWith(data?.data?.data?.linkedWith);
          setTitle(data?.data?.data?.title);
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }, []);

  const handleUploadFile = async (e, type) => {
    const fileData = e.target.files[0];
    if (fileData.size > MAX_IMAGE_SIZE) {
      showToast(
        `Image with size more than ${
          MAX_IMAGE_SIZE / 1000000
        }MB is not supported.\nPlease upload image of size smaller than ${
          MAX_IMAGE_SIZE / 1000000
        }MB.\nCurrent file size: ${(fileData.size / 1000000).toFixed(2)}MB`,
        "error",
        3000,
        "bottom",
        "center"
      );
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      const imageUrlIns = reader.result;
      type == "web"
        ? setWebImageFile(imageUrlIns)
        : setMobileImageFile(imageUrlIns);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onSubmit = async () => {
    console.log("ghjklkjh");
    startLoader();

    let webUrl = webImageFile? await UploadImage(webImageFile):webImageUrl;
    let mobileUrl =mobileImageFile? await UploadImage(mobileImageFile):mobileImageUrl;
  
   let payload = {
      title,
      linkedWith,
      webImage: webUrl,
      mobileImage: mobileUrl,
    };
    
    if (!bannerId) {
      dispatch(createNewBanner(payload))
        .then((res) => {
          console.log(res, "res res");
          setTimeout(() => {
            stopLoader();
            showToast("Banner added successfully", "success");
            history.push("/banner");
          }, 1000);
        })
        .catch((err) => {
          console.log({ err });
          history.push("/banner");
        });
    } else {
        dispatch(updateBannerById(payload,bannerId))
        .then((res) => {
          console.log(res, "res res");
          setTimeout(() => {
            stopLoader();
            showToast("Banner updated successfully", "success");
            history.push("/banner");
          }, 1000);
        })
        .catch((err) => {
          console.log({ err });
          history.push("/banner");
        });
    }
  };
  return (
    <>
      {/* <div className="position-relative" style={{height:""}}> */}
      <div className="card m-4 p-3">
        <div>
          <span>{bannerId ? "Edit Banner" : "Add Banner"}</span>
        </div>
        <br />
        <div>
          <div className="d-flex">
            <label htmlFor="#" className="col-2 imp">
              Title
            </label>
            <input
              type="text"
              placeholder="banner title"
              value={title}
              className="px-3 col-6  form-control"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <br />
          <div className="d-flex align-items-center">
            <label htmlFor="#" className="col-2 imp">
              Web Image
            </label>
            <div className="mx-2 mb-1">
              <input
                accept="image/*"
                id={`product-image-${"web"}`}
                className="d-none"
                onChange={(e) => handleUploadFile(e, "web")}
                multiple={false}
                type="file"
              />
              <div className="position-relative">
                <label htmlFor={`product-image-${"web"}`}>
                  <Image
                    src={webImageFile|| webImageUrl || "/icons/upload_icon.svg"}
                    alt="upload"
                    className={`pointer upload_image_label`}
                  />
                </label>
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex align-items-center">
            <label htmlFor="#" className="col-2 imp">
              Mobile Image
            </label>
            <div className="mx-2 mb-1">
              <input
                accept="image/*"
                id={`product-image-${"mobile"}`}
                className="d-none"
                onChange={(e) => handleUploadFile(e, "mobile")}
                multiple={false}
                type="file"
              />
              <div className="position-relative">
                <label htmlFor={`product-image-${"mobile"}`}>
                  <Image
                    src={mobileImageFile || mobileImageUrl || "/icons/upload_icon.svg"}
                    alt="upload"
                    className={`pointer upload_image_label`}
                  />
                </label>
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex">
            <label htmlFor="#" className="col-2">
              Linked With
            </label>
            <input
              type="text"
              placeholder="Link with"
              value={linkedWith}
              className="px-3 col-6  form-control"
              onChange={(e) => setLinkedWith(e.target.value)}
            />
          </div>
        </div>
        <br />
      </div>
      <footer className="d-flex position-sticky b-0 flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
        <div className="col-md-12  ">
          <div className="d-flex justify-content-end ">
            <button
              type="button"
              className="btn  btn-outline-danger input-inside-text mr-2"
              onClick={history.goBack}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn  btn-outline-danger  input-inside-text mr-2"
              onClick={() => onSubmit()}
              
            >
              {bannerId ? "Save" : "Create"}
            </button>
          </div>
        </div>
      </footer>
      {/* </div> */}
    </>
  );
}

export default AddBanner;
