import { DeleteWithToken, PutWithToken, PostWithToken,GetWithToken } from "../lib/request"
import { getLocalStorage } from "../lib/session";

/**
 * @description method to post add merchant outlet
 * @author ripto
 * @date 10/11/2023
 */

export const getAllOutletApi = (query) => {
    let role = getLocalStorage("role");
    
    if(role == "SUPERADMIN"){
        return async function (dispatch) {
            const result = await GetWithToken(`/v2/admin/outlet?search=${query.search || ""}&storeId=${query.storeId || ""}&item=${query.item || ""}&order=${query.order || ""}&offset=${query.offset || 0}&limit=${query.limit || 0}`);
            return result
        }
    }else{
        return async function (dispatch) {
            const result = await GetWithToken(`/v2/merchant/outlet?search=${query.search || ""}&item=${query.item || ""}&order=${query.order || ""}&offset=${query.offset || 0}&limit=${query.limit || 0}`);
            return result
        }
    }
}

export const getByStoreId = (id) => {
    let role = getLocalStorage("role");
    
    if(role == "SUPERADMIN"){
        return async function (dispatch) {
            const result = await GetWithToken(`/v2/admin/outlet/store/${id}`);
            return result;
        }
    }else {
        return async function (dispatch) {
            const result = await GetWithToken(`/v2/merchant/outlet/store/${id}`);
            return result;
        }
    }
}

export const addOutletApi = (data) => {
    let role = getLocalStorage("role");
    
    if(role == "SUPERADMIN"){
        return async function (dispatch) {
            const result = await PostWithToken('/v2/admin/outlet', data)
            return result
        }
    }else{
        return async function (dispatch) {
            const result = await PostWithToken('/v2/merchant/outlet', data)
            return result
        }
    }
}

export const updateOutletById = (id, data) => {
    let role = getLocalStorage("role");
    
    if(role == "SUPERADMIN"){
        return async function (dispatch) {
            const result = await PutWithToken(`/v2/admin/outlet/${id}`, data)
            return result
        }
    }else{
        return async function (dispatch) {
            const result = await PutWithToken(`/v2/merchant/outlet/${id}`, data)
            return result
        }
    }
}

export const getOutletById = (id) => {
    let role = getLocalStorage("role");
    
    if(role == "SUPERADMIN"){
        return async function (dispatch) {
            const result = await GetWithToken(`/v2/admin/outlet/${id}`);
            return result
        }
    }else{
        return async function (dispatch) {
            const result = await GetWithToken(`/v2/merchant/outlet/${id}`);
            return result
        }
    }
}

export const deleteOutletById = (id) => {
    let role = getLocalStorage("role");

    if(role == "SUPERADMIN"){
        return async function (dispatch) {
            const result = await DeleteWithToken(`/v2/admin/outlet/${id}`);
            return result
        }
    }else{
        return async function (dispatch) {
            const result = await DeleteWithToken(`/v2/merchant/outlet/${id}`);
            return result
        }
    }
}

