import React, { useEffect, useRef, useState } from "react";
import {
  withSendBird,
  Channel as SBConversation,
  ChannelList as SBChannelList,
  ChannelSettings as SBChannelSettings
} from "sendbird-uikit";

function CustomizedApp(props) {
  // props
  const {
    customTypesFilter,
    activeChannel
  } = props;

  // useState
  const [currentChannelUrl, setCurrentChannelUrl] = useState("");
  const [showSettings, setShowSettings] = useState(false);
  const channelListRef = useRef(null);

  useEffect(() => {
    console.log('Props change',activeChannel);
    if(activeChannel && activeChannel?.url){
      setCurrentChannelUrl(activeChannel.url)
    }
  }, [activeChannel]);
  
  const [query, setQuery] = useState({
    channelListQuery: {
      includeEmpty: true,
      // limit: 10,
      channelNameContainsFilter: "", // need to implement searching message ?
      customTypesFilter: customTypesFilter,
    }
  });

  return (
    <div className="customized-app">
      <div className="sendbird-app__wrap">
        <div className="sendbird-app__channellist-wrap">
          <SBChannelList
            ref={channelListRef}
            onChannelSelect={(channel) => {
              if (channel && channel.url) {
                setCurrentChannelUrl(channel.url);
              } else {
                setCurrentChannelUrl("");
              }
            }}
            queries={query}
          />
        </div>
        <div className="sendbird-app__conversation-wrap">
          <SBConversation
            channelUrl={currentChannelUrl}
            onChatHeaderActionClick={() => {
              setShowSettings(true);
            }}
          />
        </div>
        {showSettings && (
          <div className="sendbird-app__settingspanel-wrap">
            <SBChannelSettings
              channelUrl={currentChannelUrl}
              onCloseClick={() => {
                setShowSettings(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default withSendBird(CustomizedApp);
