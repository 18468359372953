import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Image from "../../components/Images/Image";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import SvgIcon from "../../components/Images/SvgIcon";
import moment from "moment";
import { startLoader, stopLoader, showToast } from "../../lib/global";
import "../../static/css/orderCss/allOrder.css";

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import InputField from "../../components/formControl/input/InputField";
import { useDispatch } from "react-redux";
import {
  getAllReturnOrder,
  getOrderReturnBystoreId,
} from "../../redux/actions/finance.action";
import { getLocalStorage } from "../../lib/session";

const Return_Refund = (props) => {
  const param = useParams();
  const storeId = param.mid ? param.mid : getLocalStorage("storeId");
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState({ order: "", item: "" });
  const [allOrderReceiptData, setAllOrderReceiptData] = useState([]);
  const viewAction = (id) => {
    history.push(`/orders/return-and-refund/${id}`);
  };
  useEffect(() => {
    const data = {
      status: props.status,
      // ...props.search,
      returnId: "",
      ...searchData,
    };
    dispatch(getOrderReturnBystoreId(storeId))
      .then((data) => {
        // console.log(data?.data?.data);
        setAllOrderReceiptData(data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchData, props.search]);

  //   const returnOrderInfo = () => {
  //     history.push("/")
  // }

  return (
    <React.Fragment>
      <div className="mt-1 py-3  rounded bg-white">
        <div className="allTeamPurchaseMain">
          <div className="tableheadTeamPurchaseProduct teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Product</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "title", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "title", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Total Price</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Request Date</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Reason</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Status</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Agent Name</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="tableheadfix teamPurchase_borderLine">Action</div>
          {!allOrderReceiptData.length ? (
            <div>No data found</div>
          ) : (
            <>
              {allOrderReceiptData.map((item, index) => (
                <div className="info">
                  <div className="teamPurchase-orderId-detail-maindiv">
                    <div className="justify-content-between d-flex teamPurchase-orderId-detail-section">
                      <div className="teamPurchase_orderId_Detail_Name">
                        <p>Buyer: {item?.consumerName || "N/A"}</p>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                      <div className="teamPurchase_orderId_Detail_brand mx-4">
                        <p>Merchant: {item?.order?.merchantName || "N/A"}</p>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                    </div>
                    <div className="teamPurchase-orderId-detail-indexId">
                      <div className="justify-content-between d-flex teamPurchase-orderId-detail-section">
                        <div className="teamPurchase_orderId_Detail_Name">
                          Case ID: #001277{index}
                        </div>
                        <div className="teamPurchase_orderId_Detail_brand mx-4">
                          Order ID: #001277{index}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="teamPurchase_tablehead_details_head">
                    <div
                      className="tableheadTeamPurchaseProduct"
                      onClick={() => viewAction(item?.orderId)}
                    >
                      <div className="d-flex">
                        <Image
                          src={
                            item?.order?.productImages || "/images/shoes.jpeg"
                          }
                          alt={"photo"}
                          className="teamPurchase_page_product_image"
                        />
                        <div className=" mx-2">
                          <div className="teamPurchase_Page_ProductBox_ProductName">
                            {item?.order?.productName || "N/A"}{" "}
                          </div>
                          <div className="teamPurchase_Page_ProductBox_Product_varient">
                            Variation: {item?.order?.variation?.name || " N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">{`${
                          item?.order?.currencyName || "RM"
                        }${item?.order?.price || " 00"}.00`}</div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient">
                          Online Banking
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">
                          {item?.createdAt.split("T")[0]}
                        </div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient ">
                          To avoid late shisss
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">
                          {item?.reason || "N/A"}
                        </div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">
                          {item?.status}
                        </div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient ">
                          To avoid late shisss
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">
                          {item?.agentName || "Yet to assign"}
                        </div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                      </div>
                    </div>
                    <div className="tableheadfix">
                      <div className="d-flex justify-content-center ">
                        <IconButton
                          className="pointer"
                          onClick={() => viewAction(item?.returnId)}
                        >
                          <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                        </IconButton>
                        {/* <IconButton className="pointer" onClick={() => viewAction(item?.orderId)}>
                                                    <SvgIcon
                                                        src="/icons/delete_icon.svg"
                                                        alt="delete icon"
                                                    />
                                                </IconButton> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

          {/* <div className="d-flex align-items-center " >
                        <div className="mr-3 text-muted">Expand ( {allProductData.length-2} Products) </div>

                        <button className="btn bg-white" onClick={() => setQuatity(allProductData.length)}
                        >
                            <Image  
                                src='/images/arrow_down.png'
                                alt='photo'
                                width={16}
                                height={10}
                            />
                        </button>
                    </div> */}
        </div>
      </div>

      <TablePagination
        rowsPerPageOptions={10}
        component="div"
        // count={allProductData.length}
        count={2}
        rowsPerPage={10}
        page={0}
        onPageChange={(e) => {
          console.log("page change", e);
        }}
      />

      {/* <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
                <div className="return_page_head_btns">
                    <div className='return_page_head_all_btn'>
                        <button>
                            All
                        </button>
                    </div>
                    <div className='return_page_head_toProcess_btn'>
                        <button>
                            To Process
                        </button>
                    </div>
                    <div className='return_page_head_processed_btn'>
                        <button>
                            Processed
                        </button>
                    </div>
                </div>
                <div className="return_page_allContent_top">
                    <div className="return_page_allContent_top_totalOrder">1 Return/Refund</div>

                    <div id="return_page_allContent_top_searchBtn">
                        <Image
                            src="/icons/search.png"
                            className="order_page_search_icon"
                            alt="Header logo for admin dashboard"
                        />
                        <input type="text" placeholder='Search' />
                    </div>
                </div>
                <div className="return_page_allContent_body_headbar">
                    <div className="return_page_Product">Product</div>
                    <div className="return_page_other">Total Price</div>
                    <div className="return_page_other ">Request Date</div>
                    <div className="return_page_other return_page_ProductBox_otherDetails_orderStatus">Status</div>
                    <div className="return_page_other">Action</div>
                </div>
                <div className="return_page_allContent_body_itemBox">
                    <div className="return_page_itemBox_buyerBox">
                        <div className="return_page_buyerBox_buyerDetail">
                            <div className="return_page_buyerBox_buyerDetail_Name">
                                <p>Buyer: Jassica</p>
                                <Image
                                    src="/icons/chat1.png"
                                    className="order_page_chat_icon"
                                    alt="Header logo for admin dashboard"
                                />
                            </div>
                            <div className="return_page_buyerBox_buyerDetail_brand">
                                <p>Merchant: Logitech</p>
                                <Image
                                    src="/icons/chat1.png"
                                    className="order_page_chat_icon"
                                    alt="Header logo for admin dashboard" />
                            </div>

                        </div>
                        <div className="return_page_buyerBox_orderId">Order ID: #1234567 </div>
                    </div>
                    <div className="return_page_itemBox_ProductBox">
                        <div className="return_page_ProductBox_Product">
                            <Image
                                src="/images/shoes.jpeg"
                                className="order_page_product_image"
                                alt="Header logo for admin dashboard"
                            />

                            <div>
                                <div className="return_page_ProductBox_ProductName">Nike Shoe V11 </div>
                                <div className="return_page_ProductBox_Product_varient">Variation: White</div>
                            </div>

                        </div>
                        <div className="return_page_ProductBox_otherDetails">
                            <div>
                                <div className="return_page_ProductBox_ProductName">RM43.00</div>
                                <div className="return_page_ProductBox_Product_varient">Online Banking</div>
                            </div>
                        </div>
                        <div className="return_page_ProductBox_otherDetails ">
                            <div>
                                <div className="return_page_ProductBox_ProductName">11/12/2021</div>
                            </div>
                        </div>
                        <div className="return_page_ProductBox_otherDetails return_page_other_orderStatus">
                            <div>
                                <div className="return_page_ProductBox_ProductName  ">Return in Progress</div>
                                <div className="return_page_ProductBox_Product_varient">UU2 is response</div>
                            </div>
                        </div>
                        <div className="return_page_ProductBox_otherDetails return_page_other_active">
                            <button className="return_page_ProductBox_ProductName return_page_ProductBox_Options" onClick={() => returnOrderInfo()}>

                                Respond
                            </button>
                        </div>

                    </div>
                </div>
                <div className="return_page_allContent_body_itemBox">
                    <div className="return_page_itemBox_buyerBox">
                        <div className="return_page_buyerBox_buyerDetail">
                            <div className="return_page_buyerBox_buyerDetail_Name">
                                <p>Buyer: Jassica</p>
                                <Image
                                    src="/icons/chat1.png"
                                    className="order_page_chat_icon"
                                    alt="Header logo for admin dashboard"
                                />
                            </div>
                            <div className="return_page_buyerBox_buyerDetail_brand">
                                <p>Merchant: Logitech</p>
                                <Image
                                    src="/icons/chat1.png"
                                    className="order_page_chat_icon"
                                    alt="Header logo for admin dashboard" />
                            </div>

                        </div>
                        <div className="return_page_buyerBox_orderId">Order ID: #1234567 </div>
                    </div>
                    <div className="return_page_itemBox_ProductBox">
                        <div className="return_page_ProductBox_Product">
                            <Image
                                src="/images/shoes.jpeg"
                                className="order_page_product_image"
                                alt="Header logo for admin dashboard"
                            />

                            <div>
                                <div className="return_page_ProductBox_ProductName">Nike Shoe V11 </div>
                                <div className="return_page_ProductBox_Product_varient">Variation: White</div>
                            </div>

                        </div>
                        <div className="return_page_ProductBox_otherDetails">
                            <div>
                                <div className="return_page_ProductBox_ProductName">RM43.00</div>
                                <div className="return_page_ProductBox_Product_varient">Online Banking</div>
                            </div>
                        </div>
                        <div className="return_page_ProductBox_otherDetails ">
                            <div>
                                <div className="return_page_ProductBox_ProductName">11/12/2021</div>
                            </div>
                        </div>
                        <div className="return_page_ProductBox_otherDetails return_page_other_orderStatus">
                            <div>
                                <div className="return_page_ProductBox_ProductName  ">Return in Progress</div>
                                <div className="return_page_ProductBox_Product_varient">UU2 is response</div>
                            </div>
                        </div>
                        <div className="return_page_ProductBox_otherDetails return_page_other_active">
                            <button className="return_page_ProductBox_ProductName return_page_ProductBox_Options" >

                                View
                            </button>
                        </div>

                    </div>
                </div>

            </div> */}
    </React.Fragment>
  );
};

export default Return_Refund;
