import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllProductList, getProductsBymerchantId, getSortedProducts } from "../../../redux/actions/product.action";
import moment from "moment";
// import { getAllCategoryApi } from "../../../services/category.service";
// import {
//     startLoader,
//     stopLoader,
//     showToast    
// } from "../../../lib/global";

import { startLoader, stopLoader, showToast, open_dialog, AllowAccess } from "../../../lib/global";
import { ACCESS_DENIED, PRODUCT_DELETE_DIALOG } from "../../../lib/constant";
import { getLocalStorage } from "../../../lib/session";
import SvgIcon from "../../../components/Images/SvgIcon";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import ablity from "../../../helpers/abilityGuard";

const initialData = [
  {
    ticketNo: "1",
    ticketStatus: "open",
    createDate: "12/02/2022",
    updated: "24/02/2022",
    merchantId: "13",
    adminName: "uu2",
    lastMessage: "kjafhdfryw8hfjkdncjknsdufyisy",
  },
  {
    ticketNo: "1",
    ticketStatus: "open",
    createDate: "12/02/2022",
    updated: "24/02/2022",
    merchantId: "13",
    adminName: "uu2",
    lastMessage: "kjafhdfryw8hfjkdncjknsdufyisy",
  },
]


const MerchantsHelpCenter = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const [allTicketData, setallTicketData] = useState(initialData)
  const history = useHistory()
  const dispatch = useDispatch()

  const breadcrumb = [
    {label: "Help Center", url: null}
  ];
  dispatch(breadcrumbAction(breadcrumb));

  return (
    <React.Fragment>
      <div className="card p-3 m-3">
        <h4>
          Ticket Listing
        </h4>
        <div className="card p-3 border rounded">
          <h5>All Ticket</h5>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="th_cell"
                    component="th"
                    align="center"
                  >
                    <div className="d-flex  ">
                      <div>No</div>
                      <div className="d-flex flex-column ">
                        <ExpandLessIcon
                          style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                          onClick={() => getApiData({ item: "title", order: "ASC" })}

                        />
                        <ExpandMoreIcon
                          style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                          onClick={() => getApiData({ item: "title", order: "DESC" })}

                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="th_cell"
                    component="th"
                    align="left"
                  >
                    <div className="d-flex  ">
                      <div>Status</div>
                      <div className="d-flex flex-column ">
                        <ExpandLessIcon
                          style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                          onClick={() => getApiData({ item: "title", order: "ASC" })}

                        />
                        <ExpandMoreIcon
                          style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                          onClick={() => getApiData({ item: "title", order: "DESC" })}

                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="th_cell "
                    component="th"
                    align="left"
                  >
                    <div className="d-flex  ">
                      <div>Created</div>
                      <div className="d-flex flex-column ">
                        <ExpandLessIcon
                          style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                          onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                        />
                        <ExpandMoreIcon
                          style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                          onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="th_cell "
                    component="th"
                    align="left"
                  >
                    <div className="d-flex  ">
                      <div>Updated</div>
                      <div className="d-flex flex-column ">
                        <ExpandLessIcon
                          style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                          onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                        />
                        <ExpandMoreIcon
                          style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                          onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="th_cell "
                    component="th"
                    align="left"
                  >
                    <div className="d-flex  ">
                      <div>Merchant ID</div>
                      <div className="d-flex flex-column ">
                        <ExpandLessIcon
                          style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                          onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                        />
                        <ExpandMoreIcon
                          style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                          onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="th_cell "
                    component="th"
                    align="left"
                  >
                    <div className="d-flex  ">
                      <div>UU2 Admin</div>
                      <div className="d-flex flex-column ">
                        <ExpandLessIcon
                          style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                          onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                        />
                        <ExpandMoreIcon
                          style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                          onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="th_cell "
                    component="th"
                    align="left"
                  >
                    <div className="d-flex  ">
                      <div>Last Message</div>
                      <div className="d-flex flex-column ">
                        <ExpandLessIcon
                          style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                          onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                        />
                        <ExpandMoreIcon
                          style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                          onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                        />
                      </div>
                    </div>
                  </TableCell>

                  <TableCell className="table_action_head" component="th">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {!allProductData?.length && (
                          <div className="d-flex justify-content-center">
                              <h3 className="text-muted m-auto">No data found!</h3>
                          </div>
                      )} */}
                {allTicketData &&
                  allTicketData.length > 0 &&
                  allTicketData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}

                    >

                      <TableCell align="center" className="td_cell">
                        {row.ticketNo || "0"}
                      </TableCell>
                      <TableCell align="left" className="td_cell">
                        {row.ticketStatus || "N/A"}
                      </TableCell>
                      <TableCell align="left" className="td_cell">
                        {row.createDate || "N/A"}
                      </TableCell>
                      <TableCell align="left" className="td_cell">
                        {row.updated || "N/A"}
                      </TableCell>
                      <TableCell align="left" className="td_cell">
                        {row.merchantId || "N/A"}
                      </TableCell>
                      <TableCell align="left" className="td_cell">
                        {row.adminName || "N/A"}
                      </TableCell>
                      <TableCell align="left" className="">
                        {row.lastMessage || "N/A"}
                      </TableCell>

                      <TableCell className="d-flex  table_action_item">
                        {ablity({model:'Merchant', action:'read'}) == 1 && (
                              <IconButton
                                onClick={()=> history.push("/merchant-center/merchant-help-center/chat-log")}
                                className="pointer"
                              // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                              >
                                <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                              </IconButton>
                          )}
                       

                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={10}
            component="div"
            // count={allProductData.length}
            count={2}
            rowsPerPage={10}
            page={0}
            onPageChange={(e) => {
              console.log("page change", e);
            }}
          // onRowsPerPageChange={(e)=>{console.log('page change', e)}}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default MerchantsHelpCenter;
